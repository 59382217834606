import { useContext, useEffect, useRef, useState } from "react";
import AnimateHeight from "react-animate-height";
import { useNavigate } from "react-router-dom";
import SessionsApi from "../../api/sessionApi";
import UsersApi from "../../api/usersApi";
import { ReactComponent as PlayBtn } from "../../assets/Profile/specialist/play-circle-video.svg";
import { ReactComponent as Token } from "../../assets/Profile/specialist/token-icon.svg";
import { ReactComponent as Verify } from "../../assets/Profile/specialist/verify.svg";
import { ReactComponent as ChavronDown } from "../../assets/SpecTools/chevron-down.svg";
import { ReactComponent as Clock } from "../../assets/SpecTools/clock.svg";
import { ReactComponent as PlayCircle } from "../../assets/SpecTools/play-circle.svg";
import Loader from "../../components/Loader/Loader";
import ModalWindow from "../../components/ModalWindow/ModalWindow";
import NavigateToProfile from "../../components/NavigateToProfile/NavigateToProfile";
import StandartButton from "../../components/StandartButton/StandartButton";
import { ContextProvider } from "../../contextProvider";
import strings from "../../localization";
import { SessionPackage } from "../../types/TypeSession";
import PackageBooking from "../Profile/SpecialistProfileContent/Packages/PackageBooking/PackageBooking";
import { NewPackageType } from "../SpecialistTools/PackagesTool/PackagesTool";
import s from "./PackageInfo.module.css";

type PackageInfoProps = {
  propsPackageData?: NewPackageType;
  propsPackageFiles?: { video: string | undefined; image: string | undefined };
};

const PackageInfo = ({
  propsPackageData,
  propsPackageFiles,
}: PackageInfoProps) => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const { userData } = useContext(ContextProvider);
  const pathname = window.location.pathname;
  const pathParts = pathname.split("/package-info/");
  const packageId = pathParts[pathParts.length - 1];
  const [packageData, setPackageData] = useState<SessionPackage | null>(null);
  const [specInfo, setSpecInfo] = useState({
    name: "",
    surname: "",
    isVerified: false,
    yearsOfExp: 0,
  });
  const [packageFiles, setPackageFiles] = useState<{
    videoLink: string | undefined;
    imageLink: string | undefined;
  }>({
    videoLink: undefined,
    imageLink: undefined,
  });
  const [openedSessions, setOpenedSessions] = useState<number[]>([]);
  const [bookingModal, setBookingModal] = useState(false);
  const [videoModalIsOpen, setVideoModalIsOpen] = useState(false);
  const [overlapTime, setOverlapTime] = useState<{ start: Date; end: Date }[]>([
    { start: new Date(), end: new Date() },
  ]);
  const [canBook, setCanBook] = useState(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const imgRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const img = imgRef.current;

    if (
      !video ||
      !canvas ||
      !img ||
      (!packageFiles.videoLink && !propsPackageFiles?.video)
    )
      return;

    const handleLoadedMetadata = () => {
      video.currentTime = 0;
    };

    const handleTimeUpdate = () => {
      const context = canvas.getContext("2d");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      if (!context) return;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      const dataURL = canvas.toDataURL();
      img.src = dataURL;
    };

    video.addEventListener("loadedmetadata", handleLoadedMetadata);
    video.addEventListener("timeupdate", handleTimeUpdate);

    return () => {
      video.removeEventListener("loadedmetadata", handleLoadedMetadata);
      video.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, [packageFiles]);

  useEffect(() => {
    const makeAsync = async () => {
      if (
        !token ||
        !packageData ||
        propsPackageData ||
        (!packageData.introductionVideo && !packageData.previewImage)
      )
        return;

      const response = await SessionsApi.getPackageFiles(
        token,
        packageData._id
      );

      if (
        response.status &&
        response.packageVideos &&
        response.packageVideos[0]
      ) {
        setPackageFiles((prev) => ({
          ...prev,
          videoLink: response.packageVideos![0].link,
        }));
      }
      if (
        response.status &&
        response.packageImages &&
        response.packageImages[0]
      ) {
        setPackageFiles((prev) => ({
          ...prev,
          imageLink: response.packageImages![0].link,
        }));
      }
    };
    makeAsync();
  }, [packageData]);

  useEffect(() => {
    const makeAsync = async () => {
      if (!token || !packageId || propsPackageData) return;
      const response = await SessionsApi.getSpecialistSessionPackageById(
        token,
        packageId
      );

      if (response.status && response.package) {
        setPackageData(response.package);
      }
      if (response.status && response.overlapDays) {
        const responseOverlaps = response.overlapDays[0].overlaps.map(
          (item) => ({ day: item.day, overlapTime: item.overlapTime })
        );
        const allOverlaps: { start: Date; end: Date }[] =
          responseOverlaps.flatMap((dayOverlap) => dayOverlap.overlapTime);
        setOverlapTime(allOverlaps);
      }
    };
    makeAsync();
  }, [packageId]);

  useEffect(() => {
    const makeAsync = async () => {
      if (!packageData || !token || !userData) return;
      const userId = propsPackageData
        ? userData._id
        : packageData.createdBy._id;
      const selectUserFields = "name surname specialistId";
      const selectSpecFields = "yearsOfExperience isVerified";
      const response = await UsersApi.getUserSelectedFields(
        token,
        userId,
        selectUserFields,
        selectSpecFields
      );
      if (
        response.status &&
        response.userData &&
        response.userData.specialistIds
      ) {
        const responseSpecInfo = {
          name: response.userData.name!,
          surname: response.userData.surname!,
          isVerified: response.userData.specialistIds[0].isVerified,
          yearsOfExp: response.userData.specialistIds[0].yearsOfExperience!,
        };
        setSpecInfo(responseSpecInfo);
      }
    };
    makeAsync();
  }, [packageData]);
  useEffect(() => {
    if (!userData) return;
    if (propsPackageData) {
      const correctPackagesData = {
        _id: "",
        title: propsPackageData.title,
        isActive: propsPackageData.isActive,
        tags: propsPackageData.tags,
        description: propsPackageData.description,
        createdBy: userData,
        specializations: propsPackageData.specializations,
        sessions: propsPackageData.sessions,
        packageCost: propsPackageData.packageCost,
        isPayedOut: true,
      };
      //@ts-expect-error
      setPackageData(correctPackagesData);
    }
    if (propsPackageFiles) {
      setPackageFiles({
        videoLink: propsPackageFiles.video,
        imageLink: propsPackageFiles.image,
      });
    }
  }, [propsPackageData, propsPackageFiles]);

  useEffect(() => {
    const makeAsync = async () => {
      if (!token || !userData || !packageData || !packageData._id) return;
      const response = await SessionsApi.checkIfCanBookPackage(
        token,
        userData._id,
        packageData._id
      );
      if (response.status) {
        setCanBook(response.canBook);
      }
    };
    makeAsync();
  }, [packageData]);

  if (!packageData) {
    return (
      <div className={s.loaderContaner}>
        <Loader size={100} />
      </div>
    );
  }

  const getAllSessionDuration = () => {
    if (!packageData.sessions.length) return 0;
    const durationArr = packageData.sessions.map(
      (item) => item.datesAndTime[0].duration
    );
    let result = durationArr.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);
    result = Number((result / 60).toFixed(1));
    return result;
  };

  return (
    <div className={s.container}>
      <div className={s.leftBlock}>
        <div className={s.mainInfoBlock}>
          <h2 className={s.packageTitle}>{packageData.title}</h2>
          {packageData.description && (
            <div
              className={s.description}
              dangerouslySetInnerHTML={{ __html: packageData.description }}
            ></div>
          )}
          {specInfo && (
            <div className={s.specInfoBlock}>
              <NavigateToProfile userId={packageData.createdBy._id}>
                <div className={s.specNameBlock}>
                  <span>{specInfo.name + " " + specInfo.surname}</span>
                  {specInfo.isVerified && <Verify />}
                </div>
              </NavigateToProfile>
              <span className={s.specExpBlock}>
                {specInfo.yearsOfExp + "+ " + strings.setupAvatarYearsExp}
              </span>
            </div>
          )}
          {packageData.tags && packageData.tags.length ? (
            <div className={s.tagsBlock}>
              {packageData.tags.map((item, index) => (
                <span className={s.tagElement} key={index}>
                  {"#" + item}
                </span>
              ))}
            </div>
          ) : null}
        </div>
        <div className={s.sessionBlock}>
          <h2 className={s.blockHeadTitle}>{strings.sections}</h2>
          <div className={s.generalSessionsInfoBlock}>
            <div className={s.sessionsInfoElementBlock}>
              <PlayCircle />
              <span>
                {packageData.sessions.length + " " + strings.sections}
              </span>
            </div>
            <div className={s.sessionsInfoElementBlock}>
              <Clock />
              <span>{getAllSessionDuration() + " " + strings.hoursLength}</span>
            </div>
          </div>
          <div className={s.sessionListBlock}>
            {packageData.sessions.map((item, index) => (
              <div key={index} className={s.sessionElement}>
                <div className={s.mainSessionElementContent}>
                  <div className={s.leftSessionElementBlock}>
                    {item.description && item.description.length ? (
                      <ChavronDown
                        className={`${s.chevronIcon} ${
                          openedSessions.includes(index)
                            ? s.rotateChevronIcon
                            : ""
                        }`}
                        onClick={() =>
                          setOpenedSessions((prev) =>
                            prev.includes(index)
                              ? prev.filter((el) => el !== index)
                              : [...prev, index]
                          )
                        }
                      />
                    ) : null}
                    <span>{item.title}</span>
                  </div>
                  <div className={s.rightSessionElementBlock}>
                    {((item.datesAndTime[0].duration / 60) % 1 === 0
                      ? (item.datesAndTime[0].duration / 60).toFixed(0)
                      : (item.datesAndTime[0].duration / 60).toFixed(1)) +
                      " " +
                      strings.packageSessionHours}
                  </div>
                </div>
                {item.description && item.description.length ? (
                  <AnimateHeight
                    height={openedSessions.includes(index) ? "auto" : 0}
                  >
                    <div className={s.sessionDescription}>
                      {item.description}
                    </div>
                  </AnimateHeight>
                ) : null}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={s.rightBlock}>
        <div className={s.actionBlock}>
          {packageFiles.imageLink && !packageFiles.videoLink && (
            <img
              src={packageFiles.imageLink}
              alt="Video preview"
              className={s.previewVideoImg}
            />
          )}
          {packageFiles.videoLink && (
            <div className={s.previewVideoBlock}>
              <canvas ref={canvasRef} style={{ display: "none" }} />

              {packageFiles.imageLink ? (
                <img
                  src={packageFiles.imageLink}
                  alt="Video preview"
                  className={s.previewVideoImg}
                />
              ) : (
                <img
                  ref={imgRef}
                  alt="First frame of video"
                  className={s.previewVideoImg}
                />
              )}
              <PlayBtn
                className={s.playBtn}
                onClick={() => setVideoModalIsOpen(true)}
              />
            </div>
          )}
          <div className={s.priceBlock}>
            <span className={s.priceTitle}>{strings.price}</span>
            <div className={s.priceValueBlock}>
              <span>
                {propsPackageData
                  ? packageData.packageCost
                  : (packageData.packageCost / 100).toFixed(0)}
              </span>
              <Token />
            </div>
          </div>

          <div className={s.bookingBtnBlock}>
            {userData?._id === packageData.createdBy._id ? (
              <StandartButton
                action={() =>
                  navigate("/tools/package", {
                    state: {
                      packageId: packageData._id,
                    },
                  })
                }
                buttonTitle={strings.editPackageBtn}
                width="200px"
                disabled={propsPackageData ? true : false}
              />
            ) : (
              <StandartButton
                action={() => setBookingModal(true)}
                buttonTitle={strings.packageBookingBtn}
                width="200px"
                disabled={canBook ? false : true}
              />
            )}
          </div>
        </div>
      </div>
      {packageFiles.videoLink && (
        <video
          ref={videoRef}
          src={packageFiles.videoLink}
          style={{ display: "none" }}
          crossOrigin="anonymous"
        />
      )}
      <ModalWindow
        isOpen={videoModalIsOpen}
        setIsOpen={setVideoModalIsOpen}
        bgColor="transparent"
        width="fit-content"
      >
        <video
          src={packageFiles.videoLink}
          crossOrigin="anonymous"
          controls
          autoPlay
          className={s.video}
        />
      </ModalWindow>
      <ModalWindow
        isOpen={bookingModal}
        setIsOpen={setBookingModal}
        maxWidth="fit-content"
      >
        <PackageBooking
          overlapTime={overlapTime}
          packageId={packageData._id}
          sessions={packageData.sessions.map((item) => ({
            duration: item.datesAndTime[0].duration,
            order: item.order,
            title: item.title,
            description: item.description,
          }))}
          setBookingModal={setBookingModal}
          packagePrice={
            propsPackageData
              ? packageData.packageCost * 100
              : packageData.packageCost
          }
          specialistUserId={packageData.createdBy._id}
          specialistId={packageData.createdBySpecialist}
        />
      </ModalWindow>
    </div>
  );
};

export default PackageInfo;
