import CalendarIcon from "../../../assets/Header/calendar.svg";
import UserAvatarList from "../../../components/UserAvatarList/UserAvatarList";
import strings from "../../../localization";
import s from "./MyProgramCard.module.css";

type MyProgramCardProps = {
  clientIds: string[];
  programTitle: string;
  programStartDate: string;
  isSelected: boolean;
  discount: number;
};

const MyProgramCard = ({
  clientIds,
  programTitle,
  programStartDate,
  discount,
  isSelected,
}: MyProgramCardProps) => {
  return (
    <div className={`${s.container} ${isSelected ? s.slectedCard : ""}`}>
      <div className={s.userListBlock}>
        <UserAvatarList
          userIds={clientIds}
          showLimit={4}
          avatarHeight="30px"
          avatarWidth="30px"
          fontSize="14px"
        />
      </div>
      <div className={s.programInfoBlock}>
        <span className={s.programTitle}>{programTitle}</span>
        {discount > 0 && (
          <div className={s.priceBlock}>
            <span>{strings.myProgramDiscountTitle}</span>
            <span>-{discount}% </span>
          </div>
        )}
      </div>
      <div className={s.joinBlock}>
        <div className={s.dateBlock}>
          <img src={CalendarIcon} alt="" />
          <span>{programStartDate}</span>
        </div>
      </div>
    </div>
  );
};

export default MyProgramCard;
