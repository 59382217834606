import dayjs, { Dayjs } from "dayjs";
import moment from "moment-timezone";
import React, { useContext, useEffect, useState } from "react";
import { ReactComponent as ChevronIcon } from "../../assets/HomePage/chevron-up.svg";
import { ReactComponent as DeleteIcon } from "../../assets/Notes/delete.svg";
import { ReactComponent as CheckIcon } from "../../assets/SpecialistSetupInfo/checked.svg";
import { ContextProvider } from "../../contextProvider";
import strings from "../../localization";
import { PaymentFor, PaymentForType } from "../../types/Balance";
import { drawDatesInWeek } from "../../utils/sessionBooking";
import { formatDate } from "../../utils/setupProfileInfo";
import CircleAvatar from "../CircleAvatar/CircleAvatar";
import ModalWindow from "../ModalWindow/ModalWindow";
import MuiCalendar from "../MuiCalendar/MuiCalendar";
import PaymentSession from "../PaymentSession/PaymentSession";
import StandartButton from "../StandartButton/StandartButton";
import s from "./SelectSessionDate.module.css";

type SelectSessionDateProps = {
  fullname?: string;
  specialization: string;
  showDateInputs: boolean;
  bookingHandler: (
    sessionBookingData: { date: Date | string; duration: number }[]
  ) => Promise<{ status: boolean; notEnoughBalance: boolean }>;
  overlapTime: { start: Date; end: Date }[];
  price: number;
  userId: string;
  specialistId: string;
  projectId?: string;
};

const SelectSessionDate = ({
  fullname,
  specialization,
  showDateInputs,
  bookingHandler,
  overlapTime,
  price,
  userId,
  projectId,
  specialistId,
}: SelectSessionDateProps) => {
  const weekDays = [
    strings.sun,
    strings.mon,
    strings.tue,
    strings.wed,
    strings.thu,
    strings.fri,
    strings.sat,
  ];
  const { userData, setUserData } = useContext(ContextProvider);
  const [selectedDate, setSelectedDate] = React.useState<Dayjs | null>(null);
  const [weekDates, setWeekDates] = useState<{ date: Date; day: string }[]>([]);
  const [selectedTime, setSelectedTime] = useState<
    { date: Date; duration: number }[]
  >([]);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [startX, setStartX] = useState<number>(0);
  const [scrollLeft, setScrollLeft] = useState<number>(0);
  const [timeSlots, setTimeSlots] = useState<{ date: string; time: string }[]>(
    []
  );
  const [noBalanceModal, setNoBalanceModal] = useState(false);
  const [daySlots, setDaySlots] = useState<string[]>([]);
  const [paymentFor, setPaymentFor] = useState<PaymentFor | null>(null);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setIsDragging(true);
    setStartX(e.clientX - e.currentTarget.offsetLeft);
    setScrollLeft(e.currentTarget.scrollLeft);
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!isDragging) return;
    const x = e.clientX - e.currentTarget.offsetLeft;
    const walk = (x - startX) * 2;
    e.currentTarget.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };
  useEffect(() => {
    if (!selectedDate) return;
    const dates = drawDatesInWeek(selectedDate.toDate());
    const datesAndDays = dates.map((item, index) => ({
      date: item,
      day: weekDays[index],
    }));

    setWeekDates(datesAndDays);
  }, [selectedDate]);

  const sendRequestHandler = async () => {
    if (selectedDate && selectedTime && selectedTime.length) {
      const bookingsData = selectedTime.map((item) => {
        const userMoment = moment(item.date.toISOString())
          .tz(userData?.timezone!, true)
          .utc();

        return {
          date: userMoment.format(),
          duration: item.duration,
        };
      });

      const response = await bookingHandler(bookingsData);
      if (!response.status && response.notEnoughBalance) {
        setNoBalanceModal(true);
      }
      if (response.status) {
        setUserData((prev) => ({
          ...prev!,
          balance: prev!.balance - price,
        }));
      }
    }
  };
  const getTimeAndDateSlots = (
    sessions: { start: Date; end: Date }[]
  ): { timeSlots: { date: string; time: string }[]; dateSlots: string[] } => {
    const timeSlots: { date: string; time: string }[] = [];
    const dateSlotsSet: Set<string> = new Set();

    for (const session of sessions) {
      const startDate = new Date(session.start);
      const endDate = new Date(session.end);

      const startTime = startDate.getUTCHours();
      const endTime = endDate.getUTCHours();
      const startDateISO = startDate.toISOString().slice(0, 10);
      const endDateISO = endDate.toISOString().slice(0, 10);

      dateSlotsSet.add(startDateISO);
      if (startDateISO !== endDateISO && endTime !== 0) {
        dateSlotsSet.add(endDateISO);
      }

      if (startDateISO === endDateISO || startTime < endTime) {
        for (let i = startTime; i < endTime; i++) {
          const timeSlot = `${i < 10 ? "0" + i : i}:00`;
          timeSlots.push({ date: startDateISO, time: timeSlot });
        }
      } else {
        for (let i = startTime; i < 24; i++) {
          const timeSlot = `${i < 10 ? "0" + i : i}:00`;
          timeSlots.push({ date: startDateISO, time: timeSlot });
        }
        for (let i = 0; i < endTime; i++) {
          const timeSlot = `${i < 10 ? "0" + i : i}:00`;
          timeSlots.push({ date: endDateISO, time: timeSlot });
        }
      }
    }
    const dateSlots = Array.from(dateSlotsSet).sort();
    return { timeSlots, dateSlots };
  };

  const getTimeSlotsForDate = (date: Date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    const freeTimeSlots = getTimeAndDateSlots(overlapTime);
    const slots = freeTimeSlots.timeSlots.filter(
      (slot) => slot.date === formattedDate
    );
    return slots;
  };

  useEffect(() => {
    const date = selectedDate?.toDate();

    const freeTimeSlots = getTimeAndDateSlots(overlapTime);
    freeTimeSlots.timeSlots.sort((a, b) => {
      if (a.date === b.date) {
        return a.time.localeCompare(b.time);
      }
      return a.date.localeCompare(b.date);
    });

    setDaySlots(freeTimeSlots.dateSlots);
    if (date) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;

      const filterTimeSlots = () => {
        const slots = freeTimeSlots.timeSlots.filter(
          (slot) => slot.date === formattedDate
        );

        if (!selectedTime.length) return slots;

        const filteredSlots = slots.filter((slot) => {
          const slotDateTime = combineDateTime(slot.date, slot.time);

          return !selectedTime.some((session) => {
            const sessionEnd = new Date(
              session.date.getTime() + session.duration * 60000
            );
            return (
              (slotDateTime > session.date && slotDateTime < sessionEnd) ||
              (slotDateTime < session.date &&
                session.date < new Date(slotDateTime.getTime() + 60 * 60000))
            );
          });
        });

        return filteredSlots;
      };

      const freeSlots = filterTimeSlots();

      if (!freeSlots) return;
      setTimeSlots(freeSlots);
    }
  }, [selectedDate, selectedTime]);

  useEffect(() => {
    const scrollContainer = document.querySelector(".selectTimeBlock");
    if (scrollContainer) {
      scrollContainer.scrollLeft = 0; // установите начальное положение скролла
    }
  }, []);

  const combineDateTime = (dateStr: string, timeStr: string): Date => {
    // Парсим дату и время
    const dateParts = dateStr.split("-").map((part) => parseInt(part, 10));
    const timeParts = timeStr.split(":").map((part) => parseInt(part, 10));

    // Создаем новый объект Date
    const year = dateParts[0];
    const month = dateParts[1] - 1; // Месяцы в JavaScript начинаются с 0
    const day = dateParts[2];
    const hour = timeParts[0];
    const minute = timeParts[1];

    return new Date(year, month, day, hour, minute);
  };

  const areDatesEqual = (date1: Date, date2: Date) => {
    return date1.getTime() === date2.getTime();
  };

  const incrementDuration = (index: number) => {
    setSelectedTime((prev) => {
      // Создаем копию текущего состояния и сортируем ее по времени и дате
      const sortedTimes = [...prev].sort(
        (a, b) => a.date.getTime() - b.date.getTime()
      );

      // Находим индекс текущего элемента в отсортированном массиве
      const sortedIndex = sortedTimes.findIndex(
        (item) => item.date.getTime() === prev[index].date.getTime()
      );

      // Проверяем, есть ли следующий элемент
      const currentItem = sortedTimes[sortedIndex];
      const nextItem = sortedTimes[sortedIndex + 1];

      if (nextItem) {
        const currentItemEndTime = new Date(
          currentItem.date.getTime() + currentItem.duration * 60000
        );
        const nextItemStartTime = new Date(nextItem.date);

        // Проверяем, перекрывает ли увеличение следующий слот, учитывая день
        if (
          currentItemEndTime.getHours() >= nextItemStartTime.getHours() &&
          currentItemEndTime.toDateString() === nextItemStartTime.toDateString()
        ) {
          return prev; // Не изменяем длительность, если перекрывает следующий слот
        }
      }

      const currentTime = currentItem.date.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
      const currentSlots = getTimeSlotsForDate(currentItem.date).sort(
        (a, b) => {
          if (a.date === b.date) {
            return a.time.localeCompare(b.time);
          }
          return a.date.localeCompare(b.date);
        }
      );
      const currentTimeSlotIndex = currentSlots.findIndex(
        (obj) => obj.time === currentTime
      );
      if (
        currentTimeSlotIndex !== -1 &&
        currentTimeSlotIndex < currentSlots.length - 1
      ) {
        let currentTime = currentSlots[currentTimeSlotIndex];
        let nextTime =
          currentSlots[currentTimeSlotIndex + prev[index].duration / 60];
        if (!nextTime) return prev;
        let currentTimeObj = new Date(`2000-01-01T${currentTime.time}`);
        let nextTimeObj = new Date(`2000-01-01T${nextTime.time}`);

        let differenceInMinutes =
          (nextTimeObj.getTime() - currentTimeObj.getTime()) / (1000 * 60);

        if (differenceInMinutes > prev[index].duration) return prev;
      }

      const lastTimeSlot = currentSlots[currentSlots.length - 1].time;
      if (currentTime === lastTimeSlot) return prev;
      // Увеличиваем duration, если не перекрывает или если нет следующего слота
      return prev.map((item, i) => {
        if (i === index && item.duration + 60 <= 180) {
          return { ...item, duration: item.duration + 60 };
        }
        return item;
      });
    });
  };

  useEffect(() => {
    const bookingsData = selectedTime.map((item) => {
      const userMoment = moment(item.date.toISOString())
        .tz(userData?.timezone!, true)
        .utc();

      return {
        date: userMoment.format(),
        duration: item.duration,
      };
    });

    const paymentForCurrent = {
      type: PaymentForType.SESSION,
      bookingFor: userId,
      datesAndTime: bookingsData,
      projectId: projectId,
      bookingForSpecialist: specialistId,
    };

    setPaymentFor(paymentForCurrent);
  }, [selectedTime]);

  return (
    <div className={s.container}>
      <div className={s.specialistInfoBlock}>
        {selectedDate && (
          <ChevronIcon
            className={s.chevronBackIcon}
            onClick={() => setSelectedDate(null)}
          />
        )}
        <div className={s.avtarBlock}>
          <CircleAvatar
            userId={userId}
            width="80px"
            height="80px"
            fontSize="32px"
          />
        </div>
        <div className={s.titleBlock}>
          <div className={s.nameBlcok}>
            <span className={s.nameTitle}>{fullname}</span>
            <span className={s.checkBlock}>
              <CheckIcon className={s.checkIcon} />
            </span>
          </div>
          <span className={s.specializationTitle}>
            {specialization.toUpperCase()}
          </span>
        </div>
      </div>
      {showDateInputs && (
        <div className={s.newTimeBlock}>
          <div className={s.showDateTimeBlock}>
            <span className={s.timeTitle}>Old Time</span>
            <div className={s.showTimeInputsBlock}>
              <input type="text" placeholder="----" readOnly />
              <input type="text" placeholder="----" readOnly />
            </div>
          </div>
          <div className={s.showDateTimeBlock}>
            <span className={s.timeTitle}>New Time</span>
            <div className={s.showTimeInputsBlock}>
              <input
                type="text"
                placeholder="----"
                readOnly
                value={selectedDate?.format("DD.MM")}
              />
              {/*   <input
                type="text"
                placeholder="----"
                readOnly
                value={selectedTime}
              /> */}
            </div>
          </div>
        </div>
      )}
      <div>
        <div className={s.decriptionTitleBlock}>
          <p className={s.freeDaysTitle}>
            {selectedDate ? strings.freeTime : strings.freDays}
          </p>
          {selectedDate && (
            <p className={s.timeszoneTitle}>
              {strings.timeRepresentedDescription} ({userData?.timezone})
            </p>
          )}
        </div>
        {selectedDate ? (
          <div className={s.timeBookingBlock}>
            <div className={s.selectDateBlock}>
              {weekDates.length &&
                weekDates.map((item, index) => {
                  const freeDays = daySlots.map((item) =>
                    new Date(item).getDate()
                  );
                  return (
                    <div className={s.datesList} key={index}>
                      <span>{item.day}</span>
                      <span
                        className={
                          selectedDate.toDate().getDate() ===
                          item.date.getDate()
                            ? s.selectedDate
                            : freeDays.includes(new Date(item.date).getDate())
                            ? ""
                            : s.notFreeDay
                        }
                        onClick={
                          freeDays.includes(new Date(item.date).getDate())
                            ? () => {
                                setSelectedDate(dayjs(item.date));
                                /*  setSelectedTime(""); */
                              }
                            : () => null
                        }
                      >
                        {item.date.getDate()}
                      </span>
                    </div>
                  );
                })}
            </div>
            <div
              className={s.selectTimeBlock}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseUp}
            >
              {timeSlots.map((item, index) => (
                <button
                  key={index}
                  className={
                    selectedTime.some((selected) =>
                      areDatesEqual(
                        selected.date,
                        combineDateTime(item.date, item.time)
                      )
                    )
                      ? s.selectedFreeTimeButton
                      : s.freeTimeButton
                  }
                  onClick={() => {
                    const newDateTime = combineDateTime(item.date, item.time);
                    setSelectedTime((prev) => {
                      const existingIndex = prev.findIndex((selected) =>
                        areDatesEqual(selected.date, newDateTime)
                      );
                      if (existingIndex > -1) {
                        return [
                          ...prev.slice(0, existingIndex),
                          ...prev.slice(existingIndex + 1),
                        ];
                      } else {
                        return [...prev, { date: newDateTime, duration: 60 }];
                      }
                    });
                  }}
                >
                  {item.time}
                </button>
              ))}
            </div>
          </div>
        ) : (
          <div className={s.calendarBlock}>
            <MuiCalendar
              setSelectedDate={setSelectedDate}
              dateSlots={daySlots}
              isSelectedMode={false}
              fontSize="14px"
              disableAutoSelectDay
            />
          </div>
        )}
      </div>
      {selectedTime.length ? (
        <div className={s.selectedSessionList}>
          <h4>{strings.selectBookSlot}</h4>
          <div className={s.selectBookSlot}>
            <span className={s.sessionDateTitle}>{strings.dateBooking}</span>
            <span className={s.sessionTimeTitle}>{strings.timeBooking}</span>
            <span>{strings.durationBooking}</span>
          </div>
          <div className={s.selectedSessionElementContainer}>
            {selectedTime.map((item, index) => (
              <div className={s.selectedSessionElement} key={index}>
                <span className={s.sessionDateTitle}>
                  {formatDate(item.date)}
                  {/* {item.date.getDate()} */}
                </span>
                <span className={s.sessionTimeTitle}>
                  {item.date.getHours().toString().padStart(2, "0") +
                    ":" +
                    item.date.getMinutes().toString().padStart(2, "0")}
                </span>
                <div className={s.durationValueBlock}>
                  <div className={s.durationValue}>
                    <button
                      className={s.durationButton}
                      onClick={() =>
                        setSelectedTime((prev) =>
                          prev.map((itemPrev, i) => {
                            if (i === index && item.duration > 60) {
                              return {
                                ...itemPrev,
                                duration: itemPrev.duration - 60,
                              };
                            }
                            return itemPrev;
                          })
                        )
                      }
                    >
                      -
                    </button>
                    <span className={s.durationValueTitle}>
                      {item.duration}
                    </span>
                    <button
                      className={s.durationButton}
                      onClick={() => incrementDuration(index)}
                    >
                      +
                    </button>
                  </div>
                  <DeleteIcon
                    className={s.deletIcon}
                    onClick={() =>
                      setSelectedTime((prev) =>
                        prev.filter((_, i) => i !== index)
                      )
                    }
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
      <div className={s.bookBtnBlock}>
        {selectedTime.length ? (
          <StandartButton
            action={() => {
              sendRequestHandler();
            }}
            buttonTitle={strings.sendRequestBtn}
          />
        ) : null}
      </div>
      {paymentFor && (
        <ModalWindow
          isOpen={noBalanceModal}
          setIsOpen={setNoBalanceModal}
          width="fit-content"
        >
          <PaymentSession hidePromocode paymentFor={paymentFor} />
        </ModalWindow>
      )}
    </div>
  );
};

export default SelectSessionDate;
