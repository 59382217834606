import { MouseEvent, useRef, useState } from "react";
import { ReactComponent as RepeatIcon } from "../../../../assets/Social/repeat.svg";
import { ReactComponent as UploadIcon } from "../../../../assets/SpecialistSetupInfo/upload.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/SpecTools/delete.svg";
import { ReactComponent as PlusIcon } from "../../../../assets/SpecTools/plus.svg";
import Input from "../../../../components/Input/Input";
import MultipleSelect from "../../../../components/MultipleSelect/MultipleSelect";
import strings from "../../../../localization";
import { compressFiles } from "../../../../utils/compressionFile";
import s from "../CreatingProfile.module.css";

type Qualifications = {
  certificate: File | undefined;
  federation: string | undefined;
  endDate: Date | undefined;
  badgeUtl: string | undefined;
  federetionProfileUrl: string | undefined;
};
const federations = [
  { value: "1", lable: "International Coaching Federation (ICF)" },
  { value: "2", lable: "European Mentor and Coaching Council (EMCC)" },
  { value: "3", lable: "Association for Coaching (AC)" },
];
/* type SubSteps3Props = {
  files: File[];
  setFiles: Dispatch<SetStateAction<File[]>>;
}; */

const SubSteps3 = () => {
  const [federationCertificates, setFederationCertificates] = useState<
    Qualifications[]
  >([
    {
      certificate: undefined,
      federation: undefined,
      endDate: undefined,
      badgeUtl: undefined,
      federetionProfileUrl: undefined,
    },
  ]);

  const scrollRef = useRef<HTMLDivElement | null>(null); // Добавили типизацию для ref
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (e: MouseEvent<HTMLDivElement>) => {
    if (!scrollRef.current) return; // Проверка на null
    setIsDragging(true);
    setStartX(e.pageX - scrollRef.current.offsetLeft);
    setScrollLeft(scrollRef.current.scrollLeft);
  };

  const handleMouseLeave = () => {
    if (!isDragging) return;
    setIsDragging(false);
  };

  const handleMouseUp = () => {
    if (!isDragging) return;
    setIsDragging(false);
  };

  const handleMouseMove = (e: MouseEvent<HTMLDivElement>) => {
    if (!isDragging || !scrollRef.current) return; // Проверка на null
    e.preventDefault();
    const x = e.pageX - scrollRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Регулируем скорость прокрутки
    scrollRef.current.scrollLeft = scrollLeft - walk;
  };

  /*   const maxFiles = 20; */

  /*  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = event.target.files;
    if (newFiles) {
      const totalFiles = files.length + newFiles.length;
      if (totalFiles <= maxFiles) {
        setFiles((prevFiles) => [...prevFiles, ...Array.from(newFiles)]);
      } else {
        Notify(strings.toManyFiles);
      }
    }
  };
  const handleRemoveFile = (index: number) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  }; */

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    if (event.target && event.target.files) {
      const file = event.target.files[0];
      if (file) {
        const compressedFile = await compressFiles(file);
        if (compressedFile.file) {
          setFederationCertificates((prevCertificates) =>
            prevCertificates.map((cert, i) =>
              i === index ? { ...cert, certificate: compressedFile.file } : cert
            )
          );
        }
      }
    }
  };

  return (
    <div className={s.subStepBlock}>
      <div className={s.federationsBlock}>
        <h2>Membership in federations</h2>
        <div
          className={s.federationListBlock}
          ref={scrollRef}
          onMouseDown={handleMouseDown}
          onMouseLeave={handleMouseLeave}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
          role="presentation"
        >
          {federationCertificates.map((item, index) => (
            <div className={s.federationCard} key={index}>
              <div
                className={s.deleteBtnBlock}
                role="button"
                onClick={() =>
                  setFederationCertificates((prev) =>
                    prev.filter((_, i) => i !== index)
                  )
                }
              >
                <DeleteIcon />
              </div>
              <div>
                <span className={s.lableTitle}>Certificate</span>
                {item.certificate ? (
                  <div className={s.uploadedCertificateBlock}>
                    <span className={s.fileNameTitle}>
                      {item.certificate.name}
                    </span>
                    <label>
                      <input
                        style={{ display: "none" }}
                        type="file"
                        accept=".pdf, image/*"
                        multiple
                        onChange={(e) => handleFileChange(e, index)}
                      />
                      <div>
                        <RepeatIcon
                          className={s.repeatIcon}
                          width={25}
                          height={25}
                        />
                      </div>
                    </label>
                    <div></div>
                  </div>
                ) : (
                  <label className={s.fileInputBlock}>
                    <input
                      style={{ display: "none" }}
                      type="file"
                      accept=".pdf, image/*"
                      multiple
                      onChange={(e) => handleFileChange(e, index)}
                    />
                    <div className={s.uploadCertificateBtn}>
                      <span>{strings.setupUploadFiles}</span>
                      <UploadIcon width={25} height={25} />
                    </div>
                  </label>
                )}
              </div>
              <div>
                <span className={s.lableTitle}>Federation</span>
                <MultipleSelect
                  data={federations}
                  multiplie={false}
                  value={item.federation ? [item.federation] : ""}
                  setValue={(value) =>
                    setFederationCertificates((prevCertificates) =>
                      prevCertificates.map((cert, i) =>
                        i === index
                          ? { ...cert, federation: value as string }
                          : cert
                      )
                    )
                  }
                  padding="10px 20px"
                />
              </div>
              <div>
                <span className={s.lableTitle}>End date</span>
                <Input
                  type="date"
                  inputValue={
                    item.endDate ? item.endDate.toISOString().split("T")[0] : ""
                  }
                  onChangeInput={(value) =>
                    setFederationCertificates((prevCertificates) =>
                      prevCertificates.map((cert, i) =>
                        i === index
                          ? { ...cert, endDate: new Date(value) }
                          : cert
                      )
                    )
                  }
                  isVisible
                  required
                />
              </div>
              <div>
                <span className={s.lableTitle}>Badge URL</span>
                <Input
                  inputValue={item.badgeUtl ? item.badgeUtl : ""}
                  onChangeInput={(value) =>
                    setFederationCertificates((prevCertificates) =>
                      prevCertificates.map((cert, i) =>
                        i === index ? { ...cert, badgeUtl: value } : cert
                      )
                    )
                  }
                  isVisible
                  required
                  placeholder="https://apps.coachingfederation.org/eweb/CCFDynamicPage.aspx?webcode=ccfcoachprofileview&coachcstkey=A26D427B-86A3-4EE3-873E-D1468F4C48FB"
                />
              </div>
              <div>
                <span className={s.lableTitle}>Federation profile URL</span>
                <Input
                  inputValue={
                    item.federetionProfileUrl ? item.federetionProfileUrl : ""
                  }
                  onChangeInput={(value) =>
                    setFederationCertificates((prevCertificates) =>
                      prevCertificates.map((cert, i) =>
                        i === index
                          ? { ...cert, federetionProfileUrl: value }
                          : cert
                      )
                    )
                  }
                  isVisible
                  required
                  placeholder="https://apps.coachingfederation.org/eweb/CCFDynamicPage.aspx?webcode=ccfcoachprofileview&coachcstkey=A26D427B-86A3-4EE3-873E-D1468F4C48FB"
                />
              </div>
            </div>
          ))}
          {federationCertificates.length < 10 && (
            <div
              className={s.federationCard}
              style={{ cursor: "pointer" }}
              onClick={() =>
                setFederationCertificates((prev) => [
                  ...prev,
                  {
                    certificate: undefined,
                    federation: undefined,
                    endDate: undefined,
                    badgeUtl: undefined,
                    federetionProfileUrl: undefined,
                  },
                ])
              }
            >
              <div className={s.plusIconBlock}>
                <PlusIcon className={s.plusIcon} />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={s.qualificationsBlock}></div>
      {/*   <p className={s.stepTitle}>{strings.setupDiplomasTitle}</p>
      <p>{strings.from1To20Diplomas}</p>
      <div className={s.uploadDocumentsBlock}>
        {files.length <= 20 && (
          <label className={s.fileInputBlock}>
            <input
              style={{ display: "none" }}
              type="file"
              accept=".pdf, image/*"
              multiple
              onChange={handleFileChange}
            />
            <div className={s.uploadButton}>{strings.setupUploadFiles}</div>
          </label>
        )}
        {files.map((file, index) => (
          <div key={index} className={s.uploadedFileBlock}>
            <span className={s.fileName}>{file.name}</span>
            <button
              className={s.removeButton}
              onClick={() => handleRemoveFile(index)}
            >
              {strings.setupRemoveBtn}
            </button>
          </div>
        ))}
      </div>
      <p>{strings.setupDiplomasDescription}</p> */}
    </div>
  );
};

export default SubSteps3;
