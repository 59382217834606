import Modal from "@mui/material/Modal";
import { Dispatch, ReactNode, SetStateAction } from "react";
import { ReactComponent as CloseIcon } from "../../assets/HomePage/close.svg";
import s from "./ModalWindow.module.css";

type ModalWindowProps = {
  children: ReactNode;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  width?: string;
  height?: string;
  bgColor?: string;
  padding?: string;
  position?: "right" | "left" | "bottom";
  maxWidth?: string;
  alternativeCloseFunction?: () => void;
  showCloseBtn?: boolean;
};
const ModalWindow = ({
  children,
  isOpen,
  setIsOpen,
  width,
  height,
  bgColor,
  padding,
  position,
  maxWidth,
  alternativeCloseFunction,
  showCloseBtn = true,
}: ModalWindowProps) => {
  return (
    <div style={{ outline: "none" }}>
      <Modal
        open={isOpen}
        onClose={
          alternativeCloseFunction
            ? alternativeCloseFunction
            : () => setIsOpen(false)
        }
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={s.modal}
      >
        <div
          className={
            position === "right"
              ? s.modalContentRight
              : position === "left"
              ? s.modalContentLeft
              : position === "bottom"
              ? s.modalContentBottom
              : s.modalContent
          }
          style={{
            maxWidth: maxWidth ?? "auto",
            width: width ? width : "50%",
            height: height ? height : "fit-content",
            backgroundColor: bgColor ? bgColor : "white",
            padding: padding
              ? padding
              : window.innerWidth > 768
              ? "20px 20px"
              : "20px",
            outline: "none",
          }}
        >
          {showCloseBtn && (
            <div className={s.closeBtnBlock}>
              <div
                className={s.closeBtn}
                role="button"
                onClick={() => setIsOpen(false)}
              >
                <CloseIcon className={s.closeIcon} />
              </div>
            </div>
          )}
          {children}
        </div>
      </Modal>
    </div>
  );
};
export default ModalWindow;
