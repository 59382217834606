import moment from "moment";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { ReactComponent as DeleteIcon } from "../../assets/Notes/delete.svg";
import { ContextProvider } from "../../contextProvider";
import strings from "../../localization";
import WorkTimeInput from "../../pages/SpecialistSetupInfo/Price/WorkTimeInput/WorkTimeInput";
import { DaySchedule } from "../../utils/shedule";
import s from "./NewShedule.module.css";

type NewSheduleProps = {
  dayState: DaySchedule;
  allDaysState: DaySchedule[];
  setDaysState: Dispatch<SetStateAction<DaySchedule[]>>;
};

const NewShedule = ({
  dayState,
  setDaysState,
  allDaysState,
}: NewSheduleProps) => {
  const { userData } = useContext(ContextProvider);
  const [currentDayState, setCurrentDayState] = useState(dayState);

  useEffect(() => {
    const dayIndex = allDaysState.findIndex(
      (el) => el.dayValue === currentDayState.dayValue
    );
    setCurrentDayState(allDaysState[dayIndex]);
  }, [allDaysState]);

  function getTimezoneOffset(timezone: string): string {
    const offsetMinutes = moment.tz(timezone).utcOffset();
    const result =
      offsetMinutes > 0 ? `+${offsetMinutes / 60}` : `${offsetMinutes / 60}`;
    return result; // конвертируем минуты в часы
  }

  const handleCancelIconClick = (timeIndex: number) => {
    const dayIndex = allDaysState.findIndex(
      (el) => el.dayValue === currentDayState.dayValue
    );
    setCurrentDayState((prev) => ({
      ...prev,
      workTime: prev.workTime.filter((_, i) => i !== timeIndex),
    }));
    setDaysState((prev) => {
      const updatedState = [...prev];
      const day = { ...updatedState[dayIndex] };
      day.workTime = day.workTime.filter((_, i) => i !== timeIndex);
      updatedState[dayIndex] = day;

      return updatedState;
    });
  };

  const addGapHandler = () => {
    const dayIndex = allDaysState.findIndex(
      (el) => el.dayValue === currentDayState.dayValue
    );
    setCurrentDayState((prev) => ({
      ...prev,
      workTime: [...prev.workTime, { from: "", to: "" }],
    }));
    setDaysState((prev) => {
      const updatedState = [...prev];
      updatedState[dayIndex].workTime = [
        ...updatedState[dayIndex].workTime,
        { from: "", to: "" }, // Изменения здесь
      ];
      return updatedState;
    });
  };

  return (
    <div className={s.container}>
      <div className={s.dayTitleBlock}>
        <h2>{currentDayState.daytitle}</h2>
      </div>
      <div className={s.daySlotsListBlock}>
        {currentDayState.workTime.map((_, index) => (
          <div className={s.timeElement} key={index}>
            <div className={s.startFinishLabelsBlock}>
              <span>{strings.sheduleStart}</span>
              <span>{strings.sheduleFinish}</span>
            </div>
            <div style={{ display: "flex", height: "80px" }}>
              <WorkTimeInput
                inputsValue={allDaysState}
                mainIndex={allDaysState.findIndex(
                  (el) => el.dayValue === currentDayState.dayValue
                )}
                setInputsValue={setDaysState}
                timeIndex={index}
                height="40px"
                isForProfile
              />
              <div
                className={s.deleteBtn}
                role="button"
                onClick={() => handleCancelIconClick(index)}
              >
                <DeleteIcon className={s.deleteIcon} />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className={s.addSlotBtn} role="button" onClick={addGapHandler}>
        {strings.addGapBtn}
      </div>
      <div className={s.timeZoneBlock}>
        <span>{strings.timeZoneShedule}</span>
        <span>
          {`(UTC ${getTimezoneOffset(userData?.timezone!)}:00) ` +
            userData?.timezone!}
        </span>
      </div>
    </div>
  );
};

export default NewShedule;
