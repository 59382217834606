import ky, { Options } from "ky";
import { logout } from "../utils/general";

export const callApi = async (url: string, options?: Options): Promise<any> => {
  try {
    const response = await ky(url, options);
    if (response.ok) {
      return await response.json();
    } else if (response.status === 401) {
      logout();
      throw new Error("Unauthorized");
    } else {
      const error = new Error("An error occurred");
      //@ts-expect-error
      error.response = response;
      throw error;
    }
  } catch (error) {
    //@ts-expect-error
    if (!error.response) {
      console.error("No response from server");
    }
    throw error;
  }
};
