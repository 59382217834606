import { useEffect, useState } from "react";
import SessionsApi from "../../api/sessionApi";
import SocialApi from "../../api/socialApi";
import UsersApi from "../../api/usersApi";
import strings from "../../localization";
import Notify from "../../utils/toaster";
import NavigateToProfile from "../NavigateToProfile/NavigateToProfile";
import s from "./SpecialistCard.module.css";

type SpecialistCardProps = {
  userId: string;
  fullName: string;
  rating: number | undefined;
  mainSpeciality: string[];
  languages: string[];
  location: string;
  isRecommendationCard?: boolean;
  avatarHeight?: string;
  avatarBlockBorderRadius?: string;
  mainInfoBorderRadius?: string;
  projectId?: string;
  briefDescription?: string;
  username: string;
};

const SpecialistCard = ({
  userId,
  fullName,
  rating,
  mainSpeciality,
  languages,
  location,
  isRecommendationCard,
  avatarHeight,
  avatarBlockBorderRadius,
  mainInfoBorderRadius,
  projectId,
  briefDescription,
  username,
}: SpecialistCardProps) => {
  const token = localStorage.getItem("token");
  const [avatar, setAvatar] = useState("");
  const [totalInfo, setTotalInfo] = useState<{
    reviewTotal?: number;
    sessionsTotal?: number;
  }>({});
  const [isSubscribe, setIsSubscribe] = useState(false);

  useEffect(() => {
    const maleAsync = async () => {
      if (!token) return;
      const response = await SocialApi.checkIfFollowed(token, userId);
      if (response.status) {
        setIsSubscribe(response.followed);
      }
    };
    maleAsync();
  }, [userId, token]);

  const getSpecialistsSessionsAmount = async () => {
    if (!token || !userId) return;
    const response = await SessionsApi.getSpecialistsSessionsAmount(userId);
    if (response.status) {
      setTotalInfo((prev) => ({ ...prev, sessionsTotal: response.amount }));
    }
  };
  const getSpecialistsComments = async () => {
    if (!token || !userId) return;
    const commentsResponse = await SessionsApi.getSpecialistsComments(
      userId,
      1,
      1
    );

    setTotalInfo((prev) => ({
      ...prev,
      reviewTotal: commentsResponse.totalAmount,
    }));
  };

  useEffect(() => {
    console.log(projectId); //For navigation state to Profile
    const makeAsync = async () => {
      if (token && userId) {
        const responseAvatar = await UsersApi.getUserAvatarAndUsername(
          token,
          userId
        );
        getSpecialistsComments();
        getSpecialistsSessionsAmount();
        if (responseAvatar.avatar) setAvatar(responseAvatar.avatar);
      }
    };
    makeAsync();
  }, [userId]);

  const followUser = async () => {
    if (!token) return;
    setIsSubscribe(true);
    const response = await SocialApi.followUser(token, {
      userIdToFollow: userId,
    });

    if (!response.status && response.message) {
      Notify(response.message);
      setIsSubscribe(false);
    }
  };

  const unfollowUser = async () => {
    if (!token) return;
    setIsSubscribe(false);
    const response = await SocialApi.unfollowUser(token, {
      userIdToUnfollow: userId,
    });

    if (!response.status && response.message) {
      Notify(response.message);

      setIsSubscribe(true);
    }
  };
  const navigateToUserPage = (userName: string) => {
    window.open(`/${userName}`, "_blank");
  };
  return (
    <div
      className={window.innerWidth > 600 ? s.container : s.mobileContainer}
      style={{ flexDirection: isRecommendationCard ? "row" : "column" }}
    >
      <NavigateToProfile userId={userId}>
        <div
          className={s.avatarBlock}
          style={{ borderRadius: avatarBlockBorderRadius ?? "20px 20px 0 0" }}
        >
          {!avatar || avatar === "" ? (
            <div
              className={s.noAvatarBlock}
              style={{
                width: isRecommendationCard ? "200px" : "100%",
                height: isRecommendationCard ? "250px" : "auto",
              }}
            >
              {fullName.split(" ")[0][0]}
              {fullName.split(" ")[1][0]}
            </div>
          ) : (
            <>
              {/*  <div className={s.blurAvatar}> */}
              <img
                src={avatar}
                alt=""
                className={s.avatarBlur}
                height={avatarHeight ?? "auto"}
                width={isRecommendationCard ? "200px" : "100%"}
              />
              {/*  </div> */}
              {/*   <img src={avatar} alt="" className={s.avatar} /> */}
            </>
          )}
        </div>
      </NavigateToProfile>
      <div
        className={
          window.innerWidth > 600 ? s.mainInfoBlock : s.mobileMainInfoBlock
        }
        style={{ borderRadius: mainInfoBorderRadius ?? "0 0 20px 20px" }}
      >
        <div className={s.fullNameValue}>{fullName}</div>
        <div className={s.specializationValue}>{mainSpeciality.join(", ")}</div>
        {totalInfo.reviewTotal !== undefined &&
          totalInfo.sessionsTotal !== undefined && (
            <div className={s.totalBlock}>
              <div className={s.totalElement}>
                <span>{totalInfo.reviewTotal}</span>
                <span>{strings.reviewsSpecCArd}</span>
              </div>
              <div className={s.totalElement}>
                <span>{totalInfo.sessionsTotal}</span>
                <span>{strings.sessionSpecCard}</span>
              </div>
            </div>
          )}
        <div className={s.briefDescription}>{briefDescription}</div>
        <div style={{ marginBlockStart: "auto" }}>
          <div className={s.locationRatingBlock}>
            <span className={s.locationValue}>{location}</span>
            <span className={s.ratingValue}>
              {strings.ratingSpecProfile + " " + rating?.toFixed(1)}
            </span>
          </div>
          <div className={s.professionalInfoBlock}>
            {languages.map((item, index) => (
              <span className={s.langValue} key={index}>
                {item + " "}
              </span>
            ))}
          </div>
        </div>
      </div>
      <div className={s.blurActionBlock}>
        <button
          className={!isSubscribe ? s.subscripBtn : s.unsubscripBtn}
          onClick={isSubscribe ? unfollowUser : followUser}
        >
          {isSubscribe ? strings.unfollowBtn : strings.followBtn}
        </button>
        <button onClick={() => navigateToUserPage(username)}>
          {strings.viewProfileBtn}
        </button>
      </div>
    </div>
  );
};
export default SpecialistCard;
