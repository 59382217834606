import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  BtnBold,
  BtnBulletList,
  BtnClearFormatting,
  BtnItalic,
  BtnNumberedList,
  BtnRedo,
  BtnStrikeThrough,
  BtnUnderline,
  BtnUndo,
  Editor,
  EditorProvider,
  Separator,
  Toolbar,
} from "react-simple-wysiwyg";
import VirtualList from "react-virtual-drag-list";
import SessionsApi from "../../../api/sessionApi";
import SpecialistApi from "../../../api/specialistApi";
import { ReactComponent as Close } from "../../../assets/HomePage/close.svg";
import { ReactComponent as Clock } from "../../../assets/SpecTools/clock.svg";
import { ReactComponent as Delete } from "../../../assets/SpecTools/delete.svg";
import { ReactComponent as MenuIcon } from "../../../assets/SpecTools/menu.svg";
import { ReactComponent as Plus } from "../../../assets/SpecTools/plus.svg";
import { ReactComponent as Refresh } from "../../../assets/SpecTools/refresh.svg";
import { ReactComponent as Stickynote } from "../../../assets/SpecTools/stickynote.svg";
import { ReactComponent as Upload } from "../../../assets/SpecTools/upload.svg";
import Input from "../../../components/Input/Input";
import Loader from "../../../components/Loader/Loader";
import ModalWindow from "../../../components/ModalWindow/ModalWindow";
import MultipleSelect from "../../../components/MultipleSelect/MultipleSelect";
import StandartButton from "../../../components/StandartButton/StandartButton";
import { ContextProvider } from "../../../contextProvider";
import strings from "../../../localization";
import Notify, { SuccesNotify } from "../../../utils/toaster";
import PackageInfo from "../../PackageInfo/PackageInfo";
import PackageSessionCard from "./PackageSessionCard/PackageSessionCard";
import s from "./PackagesTool.module.css";

export type NewPackageType = {
  title: string;
  description: string;
  packageCost: number;
  tags: string[];
  isActive: boolean;
  specializations: string[];
  sessions: {
    id: number;
    title: string;
    description?: string;
    datesAndTime: {
      duration: number;
    }[];
    order: number;
  }[];
};

const PackagesTool = () => {
  const token = localStorage.getItem("token");
  const location = useLocation();
  const navigate = useNavigate();
  const windowWidth = window.innerWidth;
  const packageId =
    location.state && location.state.packageId
      ? location.state.packageId
      : undefined;
  const { userData } = useContext(ContextProvider);
  const [specializationsData, setSpecializationsData] = useState<
    { value: string; lable: string }[] | null
  >(null);
  const [tagsInput, setTagsInput] = useState("");
  const [newPackageData, setNewPackageData] = useState<NewPackageType>({
    title: "",
    description: "",
    packageCost: 0,
    isActive: false,
    tags: [],
    specializations: [],
    sessions: [],
  });
  const [newPackageFiles, setNewPackageFiles] = useState<{
    video: File | null;
    image: File | null;
  }>({ video: null, image: null });
  const [actionLoaders, setActionLoaders] = useState({ generalLoader: false });
  const [currentPackageFile, setCurrentPackageFile] = useState<{
    video: { _id: string; name: string; link: string } | null;
    image: { _id: string; link: string } | null;
  }>({ video: null, image: null });
  const [previewPackageModal, setPreviewPackageModal] = useState(false);

  const packageStatusData = [
    { value: "true", lable: strings.publishedStatus },
    { value: "false", lable: strings.draftStatus },
  ];

  useEffect(() => {
    const makeAsync = async () => {
      if (!packageId || !token) return;
      const response = await SessionsApi.getSpecialistSessionPackageById(
        token,
        packageId
      );
      if (
        response.status &&
        response.package &&
        (response.package.introductionVideo || response.package.previewImage)
      ) {
        const filesResponse = await SessionsApi.getPackageFiles(
          token,
          response.package._id
        );
        if (
          filesResponse.status &&
          filesResponse.packageVideos &&
          filesResponse.packageVideos[0]
        ) {
          setCurrentPackageFile((prev) => ({
            ...prev,
            video: {
              _id: filesResponse.packageVideos![0]._id,
              name: filesResponse.packageVideos![0].filename,
              link: filesResponse.packageVideos![0].link,
            },
          }));
        }
        if (
          filesResponse.status &&
          filesResponse.packageImages &&
          filesResponse.packageImages[0]
        ) {
          setCurrentPackageFile((prev) => ({
            ...prev,
            image: {
              _id: filesResponse.packageImages![0]._id,
              link: filesResponse.packageImages![0].link,
            },
          }));
        }
      }

      if (response.status && response.package) {
        const responsePackageData = {
          title: response.package.title,
          description: response.package.description ?? "",
          packageCost: (response.package.packageCost / 100).toFixed(0),
          isActive: response.package.isActive,
          tags: response.package.tags ?? [],
          specializations: response.package.specializations
            ? response.package.specializations.map((item) => item._id)
            : [],
          sessions: response.package.sessions,
        };
        //@ts-expect-error
        setNewPackageData(responsePackageData);
      }
    };
    makeAsync();
  }, [packageId]);

  useEffect(() => {
    const makeAsync = async () => {
      if (!token || !userData) return;
      const response = await SpecialistApi.getAllSpecialzations(token);
      if (response.status && response.specializations) {
        setSpecializationsData(
          response.specializations.map((item) => ({
            value: item._id,
            lable: item.labels.find(
              (el) => el.language === userData.selectedLanguage
            )?.text!,
          }))
        );
      }
    };
    makeAsync();
  }, []);

  const handleFileChange = (
    event: ChangeEvent<HTMLInputElement>,
    fileType: "video" | "image"
  ) => {
    const file = event.target.files && event.target.files[0];
    if (fileType === "image") {
      setNewPackageFiles((prev) => ({ ...prev, image: file }));
    } else if (fileType === "video") {
      setNewPackageFiles((prev) => ({ ...prev, video: file }));
    } else return;
  };

  const getAllSessionDuration = () => {
    if (!newPackageData.sessions.length) return 0;
    const durationArr = newPackageData.sessions.map(
      (item) => item.datesAndTime[0].duration
    );

    let result = durationArr.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);
    result = Number((result / 60).toFixed(1));
    return result;
  };

  const onDrop = (event: {
    event: { oldIndex: number; newIndex: number };
    list: any[];
  }) => {
    const items = event.list;

    setNewPackageData((prev) => ({ ...prev, sessions: items }));
  };
  const createNewPackage = async () => {
    if (
      !newPackageData.title.length ||
      !newPackageData.description.length ||
      !newPackageData.packageCost ||
      !newPackageData.sessions.length ||
      !newPackageData.specializations.length
    ) {
      return Notify(strings.packageRequiredFieldsNotify);
    }
    if (!token) return;
    const newPackage = {
      ...newPackageData,
    };
    const payload = {
      newPackage: newPackage,
      specialistId: userData!.specialistIds![0]._id,
      userId: userData!._id,
    };

    setActionLoaders((prev) => ({ ...prev, generalLoader: true }));
    const response = await SessionsApi.createSessionPackage(token, payload);

    if (response.status && response.package) {
      if (!newPackageFiles.video) {
        SuccesNotify(strings.createPackageSucces);
        setNewPackageData({
          title: "",
          description: "",
          packageCost: 0,
          isActive: true,
          tags: [],
          specializations: [],
          sessions: [],
        });
        setNewPackageFiles({ video: null, image: null });
        setActionLoaders((prev) => ({ ...prev, generalLoader: false }));
        return;
      }
      const videoUploadStatus = await setPackageFiles(
        response.package._id,
        "video"
      );
      if (videoUploadStatus) {
        if (!newPackageFiles.image) {
          SuccesNotify(strings.createPackageSucces);
          setNewPackageData({
            title: "",
            description: "",
            packageCost: 0,
            isActive: true,
            tags: [],
            specializations: [],
            sessions: [],
          });
          setNewPackageFiles({ video: null, image: null });
          setActionLoaders((prev) => ({ ...prev, generalLoader: false }));
          return;
        }
        const imageUploadStatus = await setPackageFiles(
          response.package._id,
          "image"
        );
        if (imageUploadStatus) {
          SuccesNotify(strings.createPackageSucces);
          setNewPackageData({
            title: "",
            description: "",
            packageCost: 0,
            isActive: true,
            tags: [],
            specializations: [],
            sessions: [],
          });
          setNewPackageFiles({ video: null, image: null });
          setActionLoaders((prev) => ({ ...prev, generalLoader: false }));
          return;
        }
      }
    }
    setActionLoaders((prev) => ({ ...prev, generalLoader: false }));
  };

  const handleTagsInputChange = (value: string) => {
    if (value.length >= 32) return;
    const filteredValue = value.replace(/[^a-zA-Z0-9]/g, "");
    setTagsInput(filteredValue);
  };

  const addTagHandle = (value: string) => {
    setNewPackageData((prev) => ({ ...prev, tags: [...prev.tags, value] }));
    setTagsInput("");
  };

  const updatePackage = async () => {
    if (!token || !userData) return;
    const updateData = {
      ...newPackageData,
      _id: packageId,
    };

    setActionLoaders((prev) => ({ ...prev, generalLoader: true }));
    const response = await SessionsApi.updatePackage(token, updateData);

    if (response.status && response.package) {
      if (!newPackageFiles.video && !newPackageFiles.image) {
        SuccesNotify(strings.updatedPackageNotify);
        setNewPackageData({
          title: "",
          description: "",
          packageCost: 0,
          isActive: true,
          tags: [],
          specializations: [],
          sessions: [],
        });
        setNewPackageFiles({ video: null, image: null });
        setActionLoaders((prev) => ({ ...prev, generalLoader: false }));
        navigate(userData.username, {
          state: {
            activeProfileTab: "packages",
          },
        });
        return;
      }
      if (newPackageFiles.video && newPackageFiles.image) {
        const videoUploadStatus = await setPackageFiles(
          response.package._id,
          "video"
        );
        if (videoUploadStatus) {
          const imageUploadStatus = await setPackageFiles(
            response.package._id,
            "image"
          );
          if (imageUploadStatus) {
            SuccesNotify(strings.updatedPackageNotify);
            setNewPackageData({
              title: "",
              description: "",
              packageCost: 0,
              isActive: true,
              tags: [],
              specializations: [],
              sessions: [],
            });
            setNewPackageFiles({ video: null, image: null });
            setActionLoaders((prev) => ({ ...prev, generalLoader: false }));
            navigate(userData.username, {
              state: {
                activeProfileTab: "packages",
              },
            });
            return;
          }
        }
      }
      if (newPackageFiles.video && newPackageFiles.image) {
        const videoUploadStatus = await setPackageFiles(
          response.package._id,
          "video"
        );
        if (videoUploadStatus) {
          SuccesNotify(strings.updatedPackageNotify);
          setNewPackageData({
            title: "",
            description: "",
            packageCost: 0,
            isActive: true,
            tags: [],
            specializations: [],
            sessions: [],
          });
          setNewPackageFiles({ video: null, image: null });
          setActionLoaders((prev) => ({ ...prev, generalLoader: false }));
          navigate(userData.username, {
            state: {
              activeProfileTab: "packages",
            },
          });
          return;
        }
      }
      if (!newPackageFiles.video && newPackageFiles.image) {
        const imageUploadStatus = await setPackageFiles(
          response.package._id,
          "image"
        );
        if (imageUploadStatus) {
          SuccesNotify(strings.updatedPackageNotify);
          setNewPackageData({
            title: "",
            description: "",
            packageCost: 0,
            isActive: true,
            tags: [],
            specializations: [],
            sessions: [],
          });
          setNewPackageFiles({ video: null, image: null });
          setActionLoaders((prev) => ({ ...prev, generalLoader: false }));
          navigate(userData.username, {
            state: {
              activeProfileTab: "packages",
            },
          });
          return;
        }
      }
    }
  };

  const setPackageFiles = async (
    packageId: string,
    fileType: "image" | "video",
    returnPackage?: true
  ) => {
    if (!token) return;
    const formData = new FormData();
    if (fileType === "image" && newPackageFiles.image) {
      formData.append("file", newPackageFiles.image);
    }
    if (fileType === "video" && newPackageFiles.video) {
      formData.append("file", newPackageFiles.video);
    }
    if (returnPackage) {
      formData.append("returnPackage", returnPackage.toString());
    }
    formData.append("fileType", fileType);

    const response = await SessionsApi.setSessionPackagePreviewFile(
      token,
      packageId,
      formData
    );

    return response.status;
  };

  const deleteFile = async (
    fileId: string,
    fileType: "packageImage" | "packageVideo" | "packageSessionImage"
  ) => {
    if (!token || !packageId) return;
    const payload = {
      packageId: packageId,
      fileId: fileId,
      fileType: fileType,
    };

    const response = await SessionsApi.deletePackageSessionFile(token, payload);

    if (response.status && fileType === "packageImage") {
      setCurrentPackageFile((prev) => ({ ...prev, image: null }));
      setNewPackageFiles((prev) => ({ ...prev, image: null }));
    }
    if (response.status && fileType === "packageVideo") {
      setCurrentPackageFile((prev) => ({ ...prev, video: null }));
      setNewPackageFiles((prev) => ({ ...prev, video: null }));
    }
  };

  if (actionLoaders.generalLoader) {
    return (
      <div className={s.loaderContainer}>
        <Loader size={100} />
      </div>
    );
  }

  return (
    <div className={s.container}>
      <div className={s.leftCotextBlock}>
        <div className={s.whiteBlock}>
          <h2 className={s.blockHead}>{strings.basicInfo}</h2>
          <Input
            inputValue={newPackageData.title}
            onChangeInput={(value) =>
              setNewPackageData((prev) => ({ ...prev, title: value }))
            }
            bgColor="white"
            border="1px solid #DADADA"
            placeholder={strings.titleOfPackage + "..."}
            lable={strings.titleOfPackage}
            isVisible
            required
          />
          <div className={s.descriptionBlock}>
            <span className={s.dataLabel}>{strings.descriptionOfPackage}</span>
            <EditorProvider>
              <Editor
                value={newPackageData.description}
                onChange={(e) => {
                  if (
                    e.target.value.length >= 600 ||
                    e.target.value.includes("<img src=")
                  )
                    return;
                  setNewPackageData((prev) => ({
                    ...prev,
                    description: e.target.value,
                  }));
                }}
                containerProps={{
                  style: { resize: "vertical", borderRadius: "20px" },
                }}
              >
                <Toolbar
                  style={{
                    backgroundColor: "white",
                    borderBottom: "1px solid #DADADA",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "5px 10px",
                  }}
                >
                  <div className={s.toolsBlock}>
                    {window.innerWidth > 768 && (
                      <>
                        <BtnUndo />
                        <BtnRedo />
                        <Separator />
                      </>
                    )}
                    <BtnBold />
                    <BtnItalic />
                    <BtnUnderline />
                    <BtnStrikeThrough />
                    <Separator />
                    <BtnNumberedList />
                    <BtnBulletList />
                    <BtnClearFormatting />
                    <Separator />
                    {/*  <BtnStyles /> */}
                  </div>
                  <span className={s.charactersLimit}>
                    600 {strings.characters}
                  </span>
                </Toolbar>
              </Editor>
            </EditorProvider>
          </div>
        </div>
        <div className={s.whiteBlock}>
          <h2 className={s.blockHead}>{strings.packageIntroVideo}</h2>
          <div className={s.videoUploadBlock}>
            <span>{strings.packageVideoLable}</span>
            <div className={s.fileInputBlock}>
              {(newPackageFiles.video || currentPackageFile.video) && (
                <div className={s.uploadedVideoNameBlock}>
                  {newPackageFiles.video
                    ? newPackageFiles.video.name
                    : currentPackageFile.video!.name}
                  <Delete
                    className={s.deleteIcon}
                    onClick={
                      currentPackageFile.video
                        ? () =>
                            deleteFile(
                              currentPackageFile.video!._id,
                              "packageVideo"
                            )
                        : () =>
                            setNewPackageFiles((prev) => ({
                              ...prev,
                              video: null,
                            }))
                    }
                  />
                </div>
              )}
              <label>
                <input
                  style={{ display: "none" }}
                  type="file"
                  accept="video/*"
                  onChange={(e) => handleFileChange(e, "video")}
                  multiple={false}
                />
                {newPackageFiles.video || currentPackageFile.video ? (
                  <div className={s.refreshFileBtn} role="button">
                    <Refresh className={s.refreshIcon} />
                    {windowWidth > 768 ? strings.clickToChange : ""}
                  </div>
                ) : (
                  <div className={s.uplaodFileBtn}>
                    <Upload />
                    {strings.uplaodVideo}
                  </div>
                )}
              </label>
            </div>
          </div>
          <div className={s.imageUploadBlock}>
            <span>{strings.imgPreviewVideo}</span>
            <div className={s.imageInputBlock}>
              {(newPackageFiles.image || currentPackageFile.image) && (
                <div className={s.imageBlock}>
                  <img
                    src={
                      newPackageFiles.image
                        ? URL.createObjectURL(newPackageFiles.image)
                        : currentPackageFile.image?.link
                    }
                    className={s.videoPreviewImg}
                  />
                  <div
                    className={s.deleteImageBlock}
                    onClick={
                      currentPackageFile.image
                        ? () =>
                            deleteFile(
                              currentPackageFile.image!._id,
                              "packageImage"
                            )
                        : () =>
                            setNewPackageFiles((prev) => ({
                              ...prev,
                              image: null,
                            }))
                    }
                  >
                    <Delete className={s.deleteIcon} />
                  </div>
                </div>
              )}

              <label className={s.fileInputBlock}>
                <input
                  style={{ display: "none" }}
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileChange(e, "image")}
                  multiple={false}
                />
                {newPackageFiles.image || currentPackageFile.image ? (
                  <div className={s.refreshFileBtn} role="button">
                    <Refresh className={s.refreshIcon} />
                    {windowWidth > 768 ? strings.clickToChange : ""}
                  </div>
                ) : (
                  <div className={s.uplaodFileBtn}>
                    <Upload />
                    {strings.uplaodImage}
                  </div>
                )}
              </label>
            </div>
          </div>
        </div>
        <div className={s.whiteBlock}>
          <div className={s.packageSessionsHeadBlock}>
            <h2 className={s.blockHead}>{strings.packageSessions}</h2>
            <div className={s.generalSessionsInfoBlock}>
              <div className={s.sessionInfoTitleBlock}>
                <div className={s.generalSessionInfoElement}>
                  <Stickynote className={s.sessionsInfoIcon} />
                  {newPackageData.sessions.length + " " + strings.sections}
                </div>
                <div className={s.generalSessionInfoElement}>
                  <Clock className={s.sessionsInfoIcon} />
                  {getAllSessionDuration() + " " + strings.hoursLength}
                </div>
              </div>
              {newPackageData.sessions.length < 100 && (
                <div
                  role="button"
                  className={s.addSectionBtn}
                  onClick={() =>
                    setNewPackageData((prev) => ({
                      ...prev,
                      sessions: [
                        ...prev.sessions,
                        {
                          id: newPackageData.sessions.length + 1,
                          title: "",
                          description: "",
                          datesAndTime: [
                            {
                              duration: 60,
                            },
                          ],
                          order: newPackageData.sessions.length + 1,
                        },
                      ],
                    }))
                  }
                >
                  {strings.addNewSection}
                </div>
              )}
            </div>
          </div>
          <VirtualList
            className="virtual-list"
            dataKey="id"
            dataSource={newPackageData.sessions}
            handle="#handle-drag"
            onDrop={onDrop}
            keeps={100}
          >
            {(
              record: {
                id: number;
                title: string;
                description?: string;
                datesAndTime: {
                  duration: number;
                }[];
                order: number;
              },
              index: number
            ) => {
              return (
                <div className={s.cardBlock} key={index}>
                  <MenuIcon id="handle-drag" className={s.menuIcon} />
                  <PackageSessionCard
                    session={record}
                    setSessionState={setNewPackageData}
                  />
                </div>
              );
            }}
          </VirtualList>
        </div>
      </div>
      <div className={s.rightContentBlock}>
        <div className={`${s.whiteBlock} ${s.publishBlock}`}>
          <div className={s.mainActionsBlock}>
            <StandartButton
              action={!packageId ? createNewPackage : updatePackage}
              buttonTitle={
                packageId ? strings.specSaveProfileBtn : strings.publishBtn
              }
              width="100%"
            />
            <div
              role="btn"
              className={s.showPreviewBtn}
              onClick={() => setPreviewPackageModal(true)}
            >
              {strings.ViewPreview}
            </div>
          </div>
        </div>
        <div className={s.whiteBlock}>
          <h2 className={s.blockHead}>{strings.packStatus}</h2>
          <div>
            <span>{strings.productStatus}</span>
            <MultipleSelect
              data={packageStatusData}
              multiplie={false}
              setValue={(value) =>
                setNewPackageData((prev) => ({
                  ...prev,
                  isActive: value === "false" ? false : true,
                }))
              }
              value={newPackageData.isActive.toString()}
              bgColor="white"
              border="1px solid #DADADA"
              padding="10px 20px"
            />
          </div>
        </div>
        <div className={s.whiteBlock}>
          <h2 className={s.blockHead}>{strings.packCategotias}</h2>
          {specializationsData && (
            <div className={s.specializationsBlock}>
              <span>{strings.specializationsPackage}</span>
              <MultipleSelect
                data={specializationsData}
                multiplie={true}
                setValue={(value) =>
                  setNewPackageData((prev) => ({
                    ...prev,
                    specializations: value as string[],
                  }))
                }
                value={newPackageData.specializations}
                bgColor="white"
                border="1px solid #DADADA"
                padding="10px 20px"
              />
            </div>
          )}
          <div className={s.tagsBlock}>
            <span>{strings.tags + " (" + strings.upTo + " " + "8)"}</span>
            <div className={s.inputTagsBlock}>
              <input
                type="text"
                value={tagsInput}
                onChange={(e) => handleTagsInputChange(e.target.value)}
                className={s.tagsInput}
                placeholder={strings.tagsForSearch}
              />
              {newPackageData.tags.length < 8 && (
                <div
                  className={s.plusTagBtn}
                  onClick={
                    tagsInput.length
                      ? () => addTagHandle(tagsInput)
                      : () => null
                  }
                >
                  <Plus
                    className={s.plusIcon}
                    style={{ opacity: tagsInput.length ? 1 : 0.5 }}
                  />
                </div>
              )}
              {newPackageData.tags.length ? (
                <div className={s.addedTagsList}>
                  {newPackageData.tags.map((item, index) => (
                    <div className={s.tagElement} key={index}>
                      <span>#{item}</span>
                      <Close
                        className={s.closeIcon}
                        onClick={() =>
                          setNewPackageData((prev) => ({
                            ...prev,
                            tags: prev.tags.filter((_, i) => i !== index),
                          }))
                        }
                      />
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className={s.whiteBlock}>
          <h2 className={s.blockHead}>{strings.packPricing}</h2>
          <div className={s.priceInputBlock}>
            <Input
              inputValue={newPackageData.packageCost.toString()}
              type="number"
              onChangeInput={(value) =>
                setNewPackageData((prev) => ({
                  ...prev,
                  packageCost: Number(value),
                }))
              }
              isVisible
              required
              bgColor="white"
              border="1px solid #DADADA"
              placeholder="45"
              lable={strings.packPrice}
            />
          </div>
        </div>
      </div>

      <ModalWindow
        isOpen={previewPackageModal}
        setIsOpen={setPreviewPackageModal}
        bgColor="var(--gray-bg-color)"
        width="90vw"
      >
        <PackageInfo
          propsPackageData={newPackageData}
          propsPackageFiles={{
            video: newPackageFiles.video
              ? URL.createObjectURL(newPackageFiles.video)
              : currentPackageFile.video
              ? currentPackageFile.video.link
              : undefined,
            image: newPackageFiles.image
              ? URL.createObjectURL(newPackageFiles.image)
              : currentPackageFile.image
              ? currentPackageFile.image.link
              : undefined,
          }}
        />
      </ModalWindow>
    </div>
  );
};

export default PackagesTool;
