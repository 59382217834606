import { Dispatch, SetStateAction, useState } from "react";
import EyeSlashIcon from "../../../assets/Auth/eye-slash.svg";
import EyeIcon from "../../../assets/Auth/eye.svg";
import FacebookIcon from "../../../assets/Auth/facebook.svg";
import GoogleIcon from "../../../assets/Auth/google.svg";
import IcloudIcon from "../../../assets/Auth/icloud.svg";
import TwitterIcon from "../../../assets/Auth/twitter.svg";
import Input from "../../../components/Input/Input";
import StandartButton from "../../../components/StandartButton/StandartButton";
import strings from "../../../localization";
import s from "./MobileAuthorization.module.css";

type MobileAuthorizationProps = {
  authValues: { email: string; password: string };
  setAuthValues: Dispatch<SetStateAction<{ email: string; password: string }>>;
  registarValues: { name: string; email: string; password: string };
  setRegisterValues: Dispatch<
    SetStateAction<{ name: string; email: string; password: string }>
  >;
  authHandler: () => void;
  passwordVisible: { auth: boolean; register: boolean };
  setPasswordVisible: Dispatch<
    SetStateAction<{ auth: boolean; register: boolean }>
  >;
  handleRegister: () => void;
  regError: string;
  authError: string;
};

const MobileAuthorization = ({
  authValues,
  setAuthValues,
  registarValues,
  setRegisterValues,
  authHandler,
  passwordVisible,
  setPasswordVisible,
  handleRegister,
  regError,
  authError,
}: MobileAuthorizationProps) => {
  const [isSignUp, setIsSignUp] = useState(false);

  const onChangeAuth = (
    value: string,
    objKey: keyof { email: string; password: string }
  ) => {
    setAuthValues((prev) => ({ ...prev, [objKey]: value }));
  };
  const onChangeRegister = (
    value: string,
    objKey: keyof { name: string; email: string; password: string }
  ) => {
    setRegisterValues((prev) => ({ ...prev, [objKey]: value }));
  };
  return (
    <div className={s.conatiner}>
      {!isSignUp ? (
        <div className={s.signInBlock}>
          <h1>{strings.signInTitle}</h1>
          <div className={s.changeSignBlock}>
            <span>{strings.newUserMob}</span>
            <button className={s.changeSignButton}>
              <span
                className={s.changeSignTitle}
                onClick={() => setIsSignUp(true)}
              >
                {strings.createAccountMob}
              </span>
              <span className={s.underLine}></span>
            </button>
          </div>
          <div className={s.inputsBlock}>
            <Input
              lable={strings.emailUsernamePlaceHolder}
              inputValue={authValues.email}
              onChangeInput={(value) => onChangeAuth(value, "email")}
              isVisible={true}
              placeholder={"username"}
              margin="0 0 20px 0"
              required
            />

            <div className={s.passwordInputContainer}>
              <Input
                lable={strings.passwordAuthLable}
                inputValue={authValues.password}
                onChangeInput={(value) => onChangeAuth(value, "password")}
                isVisible={passwordVisible.auth}
                placeholder="********"
                required
              />
              {authError !== "" && (
                <p className={s.errorMessege}>{authError}</p>
              )}
              <img
                data-testid="register-visible-switcher"
                src={passwordVisible.auth ? EyeSlashIcon : EyeIcon}
                alt=""
                className={s.eyeIcon}
                onClick={() =>
                  setPasswordVisible((prev) => ({
                    ...prev,
                    auth: !prev.auth,
                  }))
                }
              />
            </div>
          </div>
          <div className={s.forgotPasswordBlock}>
            <button className={s.changeSignButton}>
              <span
                className={s.changeSignTitle}
                onClick={() => setIsSignUp(true)}
              >
                {strings.forgotPassword}
              </span>
              <span className={s.underLine}></span>
            </button>
          </div>
          <div className={s.buttonBlock}>
            <StandartButton
              buttonTitle="Continue"
              action={authHandler}
              width="200px"
            />
          </div>
          <div className={s.altSign}>
            <span className={s.altLine}></span>
            <span className={s.altTitle}>{strings.orSignWithMob}</span>
            <span className={s.altLine}></span>
          </div>
          <div className={s.socialContainer}>
            <a href="#" className={s.social}>
              <img src={FacebookIcon} alt="" />
            </a>
            <a href="#" className={s.social}>
              <img src={GoogleIcon} alt="" />
            </a>
            <a href="#" className={s.social}>
              <img src={TwitterIcon} alt="" />
            </a>
            <a href="#" className={s.social}>
              <img src={IcloudIcon} alt="" />
            </a>
          </div>
        </div>
      ) : (
        <div className={s.signUpBlock}>
          <h1>{strings.signUpBtn}</h1>
          <div className={s.changeSignBlock}>
            <span>{strings.alreadyHaveAccMob}</span>
            <button className={s.changeSignButton}>
              <span
                className={s.changeSignTitle}
                onClick={() => setIsSignUp(false)}
              >
                {strings.signInBtn}
              </span>
              <span className={s.underLine}></span>
            </button>
          </div>
          <div className={s.inputsBlock}>
            <Input
              lable={strings.usernamePlacehodler}
              inputValue={registarValues.name}
              onChangeInput={(value) => onChangeRegister(value, "name")}
              isVisible={true}
              placeholder="name"
              required
            />
            <Input
              lable={strings.emailPlaceHolder}
              inputValue={registarValues.email}
              onChangeInput={(value) => onChangeRegister(value, "email")}
              isVisible={true}
              placeholder="name@example.com"
              required
            />
            <div className={s.passwordInputContainer}>
              <Input
                lable={strings.passwordPlaceHolder}
                inputValue={registarValues.password}
                onChangeInput={(value) => onChangeRegister(value, "password")}
                isVisible={passwordVisible.register}
                required
              />
              {regError !== "" && <p className={s.errorMessege}>{regError}</p>}
              <img
                data-testid="register-visible-switcher"
                src={passwordVisible.register ? EyeSlashIcon : EyeIcon}
                alt=""
                className={s.eyeIcon}
                onClick={() =>
                  setPasswordVisible((prev) => ({
                    ...prev,
                    register: !prev.register,
                  }))
                }
              />
            </div>
          </div>
          <div className={s.buttonBlock}>
            <StandartButton
              buttonTitle="Continue"
              action={handleRegister}
              width="200px"
            />
          </div>
          <div className={s.altSign}>
            <span className={s.altLine}></span>
            <span className={s.altTitle}>{strings.orSignWithMob}</span>
            <span className={s.altLine}></span>
          </div>
          <div className={s.socialContainer}>
            <a href="#" className={s.social}>
              <img src={FacebookIcon} alt="" />
            </a>
            <a href="#" className={s.social}>
              <img src={GoogleIcon} alt="" />
            </a>
            <a href="#" className={s.social}>
              <img src={TwitterIcon} alt="" />
            </a>
            <a href="#" className={s.social}>
              <img src={IcloudIcon} alt="" />
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileAuthorization;
