import { Box, Checkbox, Chip, ListItemText } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useState } from "react";
import "./MultipleSelect.css";
import s from "./MultipleSelect.module.css";

type DropdownSelectProps = {
  data: { value: string; lable: string }[];
  setValue: (value: string | string[]) => void;
  value: string | string[];
  multiplie: boolean;
  width?: string;
  lable?: string;
  checkBox?: boolean;
  height?: string;
  padding?: string;
  disabled?: boolean;
  placeholder?: string;
  bgColor?: string;
  border?: string;
  chip?: boolean;
  defaultValue?: string;
};

const MultipleSelect = ({
  data,
  setValue,
  value,
  multiplie,
  width,
  lable,
  checkBox,
  height,
  padding,
  disabled,
  placeholder,
  bgColor,
  border,
  chip,
  defaultValue,
}: DropdownSelectProps) => {
  const [selectIsOpen, setSelectIsOpen] = useState(false);
  const inputStyle = {
    border: "none",
    "&:focus": {
      borderColor: "#3FB083",
    },
  };

  function truncateString(str: string, maxLength: number) {
    if (str.length > maxLength) {
      return str.slice(0, maxLength) + "...";
    }
    return str;
  }

  return (
    <div style={{ width: width ? width : "100%" }} className={s.container}>
      <FormControl
        variant="outlined"
        sx={{ minWidth: 120, width: "100%", margin: 0 }}
      >
        <InputLabel
          shrink={selectIsOpen || value.length ? true : false}
          style={{
            color:
              selectIsOpen || value.length ? "black" : "rgba(0, 0, 0, 0.4)",
            top: selectIsOpen || value.length ? "-7px" : "-5px",
          }}
        >
          {lable}
        </InputLabel>

        <Select
          multiple={multiplie}
          disabled={disabled ? disabled : false}
          color="success"
          onOpen={() => setSelectIsOpen(true)}
          onClose={() => setSelectIsOpen(false)}
          placeholder={placeholder ?? ""}
          style={{
            borderRadius: "20px",
            backgroundColor: "#f0f0f0 ",
            borderColor: "red",
            ...inputStyle,
          }}
          labelId={
            chip
              ? "demo-multiple-chip-label"
              : checkBox
              ? "demo-multiple-checkbox-label"
              : "demo-multiple-name-label"
          }
          id={
            chip
              ? "demo-multiple-chip"
              : checkBox
              ? "demo-multiple-checkbox"
              : "demo-multiple-name"
          }
          //@ts-expect-error
          value={value}
          defaultValue={defaultValue ? [defaultValue] : undefined}
          renderValue={
            chip && data && data.length
              ? (selected: string[]) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={truncateString(
                          data.find((el) => el.value === value)!.lable,
                          selected.length > 1 ? 10 : 100
                        )}
                      />
                    ))}
                  </Box>
                )
              : checkBox
              ? (selected: string[]) =>
                  selected.map(
                    (item) => data.find((el) => item === el.value)?.lable
                  )
              : undefined
          }
          onChange={(e) => {
            setValue(e.target.value);
          }}
          /*          onFocus={() => setLabelVisible(false)}
          onBlur={() => setLabelVisible(true)} */
          inputProps={{
            style: {
              ...inputStyle,
            },
          }}
          SelectDisplayProps={{
            style: {
              borderRadius: "20px",
              backgroundColor: bgColor ?? "#f0f0f0",
              border: border ?? "none",
              height: height ? height : "auto",
              padding: padding ? padding : "auto",
              display: "flex",
              alignItems: "center",
              overflow: "hidden",
              textOverflow: "ellipsis",
              paddingInline: "20px",
            },
          }}
          MenuProps={{
            style: {
              borderRadius: "20px",
              marginTop: "0px",
              maxHeight: "300px", // Устанавливаем максимальную высоту
            },
          }}
        >
          {data.map((item, index) =>
            checkBox ? (
              <MenuItem
                key={item.lable}
                value={item.value}
                className="multi-select-menu"
              >
                <Checkbox checked={value.indexOf(item.value) > -1} />
                <ListItemText primary={item.lable} />
              </MenuItem>
            ) : (
              <MenuItem
                value={item.value}
                key={index}
                className="multi-select-menu"
              >
                {item.lable}
              </MenuItem>
            )
          )}
        </Select>
      </FormControl>
    </div>
  );
};

export default MultipleSelect;
