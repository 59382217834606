import { Dispatch, SetStateAction, useEffect, useState } from "react";
import TodosAPI from "../../../../api/todoApi";
import UsersApi from "../../../../api/usersApi";
import CalendarIcon from "../../../../assets/Header/calendar.svg";
import CheckIcon from "../../../../assets/Notes/check.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/Notes/delete.svg";
import { ReactComponent as EditIcon } from "../../../../assets/Notes/edit.svg";
import strings from "../../../../localization";
import { TodoList } from "../../../../types/TypeToDo";
import s from "./TodosCardVideoChat.module.css";

type ToDoCardProps = {
  title: string;
  fullName: string;
  date: string;
  todoListId: string;
  setTodoData: Dispatch<SetStateAction<TodoList[] | null>>;
  setSelectedTodoList: Dispatch<SetStateAction<TodoList | null>>;
  forId: string;
};

type ClientInfo = {
  name: string;
  surname: string;
};

const TodosCardVideoChat = ({
  title,
  fullName,
  date,
  todoListId,
  setTodoData,
  setSelectedTodoList,
  forId,
}: ToDoCardProps) => {
  const token = localStorage.getItem("token");
  const [isEdit, setIsEdit] = useState(false);
  const [updateTodoList, setUpdateTodoList] = useState({ title: title });
  const [clientInfo, setClientInfo] = useState<ClientInfo | null>(null);

  const deleteToDoList = async () => {
    if (token) {
      const response = await TodosAPI.deleteTodoList(token, todoListId);
      if (response.status) setSelectedTodoList(null);
      setTodoData((prevTodoData) => {
        if (prevTodoData) {
          const updatedTodoListArray: TodoList[] = prevTodoData.filter(
            (todo) => todo._id !== todoListId
          );
          return updatedTodoListArray;
        }
        return null;
      });
    }
  };

  const updateTodoListHandler = async () => {
    if (token && updateTodoList.title !== title) {
      const response = await TodosAPI.updateTodoList(token, {
        _id: todoListId,
        title: updateTodoList.title,
      });

      if (response.status) {
        setTodoData((prevTodoData) => {
          if (prevTodoData) {
            const updatedTodoListArray: TodoList[] = prevTodoData.map(
              (todo) => {
                if (todo._id === todoListId) {
                  return { ...todo, title: updateTodoList.title };
                }
                return todo;
              }
            );
            return updatedTodoListArray;
          }
          return null;
        });
      }
    }
    setIsEdit(false);
  };

  useEffect(() => {
    const makeAsync = async () => {
      if (!token || !forId) return;
      const response = await UsersApi.getUserAvatarAndUsername(token, forId);
      if (response.name && response.surname) {
        setClientInfo({
          name: response.name,
          surname: response.surname,
        });
      }
    };
    makeAsync();
  }, [forId]);

  return (
    <div className={s.container}>
      <div className={s.headBlock}>
        {isEdit ? (
          <input
            className={s.updateTitleInput}
            type="text"
            value={updateTodoList.title}
            onClick={(e) => e.stopPropagation()}
            onChange={(event) =>
              setUpdateTodoList((prev) => ({
                ...prev,
                title:
                  event.target.value.length < 100
                    ? event.target.value
                    : prev.title,
              }))
            }
          />
        ) : (
          <span>{title.length > 30 ? title.slice(0, 30) + "..." : title}</span>
        )}
        <div className={s.iconBlock}>
          {isEdit ? (
            <span
              onClick={(e) => {
                e.stopPropagation();
                updateTodoListHandler();
              }}
              className={s.editButtonBlock}
            >
              <img src={CheckIcon} alt="" className={s.checkIcon} />
            </span>
          ) : (
            <span
              className={s.editButtonBlock}
              onClick={(e) => {
                e.stopPropagation();
                setIsEdit(true);
              }}
            >
              <EditIcon className={s.editIcon} />
            </span>
          )}
          <span
            className={s.deleteButtonBlock}
            onClick={(e) => {
              e.stopPropagation();
              deleteToDoList();
            }}
          >
            <DeleteIcon className={s.deletIcon} />
          </span>
        </div>
      </div>
      <div className={s.bottomBlock}>
        <div className={s.authorInfo}>
          <div className={s.authorForTitleBlock}>
            <span>{strings.createdByTodo} </span>
            <span>{fullName}</span>
          </div>
          {clientInfo?.name && (
            <div className={s.authorForTitleBlock}>
              <span>{strings.forTodo} </span>
              <span>{clientInfo?.name + " " + clientInfo?.surname}</span>
            </div>
          )}
        </div>
        <div className={s.dateBlock}>
          <img src={CalendarIcon} alt="" />
          <span>{date}</span>
        </div>
      </div>
    </div>
  );
};

export default TodosCardVideoChat;
