import { useContext, useEffect, useRef, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import SessionsApi from "../../api/sessionApi";
import { ContextProvider } from "../../contextProvider";
import { SessionComment } from "../../types/TypeSession";
import { SpecialistData } from "../../types/TypeSpecialist";
import s from "./PublicProfile.module.css";

import { CircularProgress, ThemeProvider } from "@mui/material";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import countries from "i18n-iso-countries";
import { ReactSpoiler } from "react-simple-spoiler";
import { ReactComponent as ChevronDown } from "../../assets/Profile/specialist/chevron-down.svg";
import { ReactComponent as EearthIcon } from "../../assets/Profile/specialist/earh.svg";
import { ReactComponent as SpecializationIcon } from "../../assets/Profile/specialist/global.svg";
import { ReactComponent as GreenStar } from "../../assets/Profile/specialist/green-star.svg";
import { ReactComponent as MessageIcon } from "../../assets/Profile/specialist/message.svg";
import { ReactComponent as PlayBtnIcon } from "../../assets/Profile/specialist/play-circle-video.svg";
import { ReactComponent as StarIcon } from "../../assets/Profile/specialist/star.svg";
import { ReactComponent as VerifiedIcon } from "../../assets/Profile/specialist/verify.svg";
import ModalWindow from "../../components/ModalWindow/ModalWindow";
import StandartButton from "../../components/StandartButton/StandartButton";
import strings from "../../localization";
import localizationJson from "../../localization.json";
import { TypeUserData } from "../../types/TypeUsers";
import { theme } from "../../utils/theme";

import "@react-pdf-viewer/core/lib/styles/index.css";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import UsersApi from "../../api/usersApi";
import CircleAvatar from "../../components/CircleAvatar/CircleAvatar";
import { SpecialistLevels } from "../../types/Subscription";
import { calculateAge } from "../../utils/setupProfileInfo";
import Notify from "../../utils/toaster";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export type CertificatesData = {
  _id?: string | undefined;
  filename: string | undefined;
  type: string | undefined;
  size: number | undefined;
  link: string;
};

const PublicProfile = () => {
  const connectionString = process.env.REACT_APP_HTTP_CONNECTION_STRING;
  const storageSelectLang = localStorage.getItem("lang");
  const { selectLang, setSelectLang } = useContext(ContextProvider);
  const navigate = useNavigate();
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [loaders, setLoaders] = useState({
    mainLoader: false,
    certificates: false,
  });
  const [navigateToRegModal, setNavigateToRegModal] = useState(false);
  const [profileUserData, setProfileUserData] = useState<
    (TypeUserData & { specialistId?: SpecialistData }) | undefined | null
  >();
  const [specialistData, setSpecialistData] = useState<
    | {
        specializationValue: string;
        specializationLable: string;
        specialistData: SpecialistData;
      }[]
    | undefined
  >();
  const [selectedSpecialistData, setSelectedSpecialistData] = useState<
    | {
        specializationValue: string;
        specializationLable: string;
        specialistData: SpecialistData;
      }
    | undefined
  >();

  const [commentsData, setCommentsData] = useState<SessionComment[]>([]);
  const [previewFileModal, setPreviewFileModal] = useState(false);
  //@ts-expect-error
  const [previewFile, setPreviewFile] = useState<{
    pdfFile: string | null;
    imageFile: string | null;
  }>({ pdfFile: null, imageFile: null });

  /*   const [certificatesPaggination, setCertificatesPaggination] = useState({
    page: 1,
    total: 0,
  });
  const [certificatesData, setCertificatesData] = useState<CertificatesData[]>(
    []
  ); */

  const [videoPreviewImg, setVideoPreviewImg] = useState<string | undefined>();
  const [textHeight, setTextHeight] = useState({
    aboutMyself: 0,
    aboutTeraphy: 0,
  });

  const [totalInfo, setTotalInfo] = useState<{
    reviewTotal?: number;
    sessionsTotal?: number;
  }>({});

  const introVideoId =
    selectedSpecialistData &&
    selectedSpecialistData.specialistData.introductionVideo?._id
      ? selectedSpecialistData.specialistData.introductionVideo._id
      : null;

  const aboutMyselfRef = useRef<HTMLParagraphElement | null>(null);
  const aboutTeraphyRef = useRef<HTMLParagraphElement | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const imgRef = useRef<HTMLImageElement | null>(null);

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const pathname = window.location.pathname;
  const pathParts = pathname.split("/");
  const userName = pathParts[pathParts.length - 1];

  const coachLevels = {
    [SpecialistLevels.STUDENT]: strings.specLvlStudent,
    [SpecialistLevels.GRADUATED_STUDENT]: strings.specLvlGradtudent,
    [SpecialistLevels.LEVEL1]: strings.specLvl1,
    [SpecialistLevels.LEVEL2]: strings.specLvl2,
  };
  useEffect(() => {
    if (!aboutMyselfRef.current || !aboutTeraphyRef.current) return;
    setTextHeight({
      aboutMyself: aboutMyselfRef.current.offsetHeight + 10,
      aboutTeraphy: aboutTeraphyRef.current.offsetHeight + 10,
    });
  }, [aboutMyselfRef.current, aboutTeraphyRef.current]);

  useEffect(() => {
    const browserLanguage = navigator.language.split("-")[0];
    const languageArray = Object.keys(localizationJson);

    if (languageArray.includes(browserLanguage)) {
      setSelectLang(browserLanguage);
    }

    setSelectLang((prev) => (prev ? prev : "en"));
  }, []);

  useEffect(() => {
    const makeAsync = async () => {
      if (userName === "") {
        return navigate("/choice-mode");
      }

      setLoaders((prev) => ({ ...prev, mainLoader: true }));
      const response = await UsersApi.getUserPublicInfo(userName, "en");
      setLoaders((prev) => ({ ...prev, mainLoader: false }));

      if (!response.status) {
        Notify(response.message!);
        await new Promise((resolve) => setTimeout(resolve, 3000));
        navigate("/choice-mode");
      }
      if (response.status && response.user && response.user.specialistIds) {
        setProfileUserData(response.user);

        setSpecialistData(
          response.user.specialistIds &&
            response.user.specialistIds.map((item) => ({
              specializationValue: item.mainSpecializations[0].specialization,
              specializationLable: item.mainSpecializations[0].labels.find(
                (el) => el.language === storageSelectLang
              )!.text,
              specialistData: item,
            }))
        );
      }
    };
    makeAsync();
  }, []);
  useEffect(() => {
    if (!specialistData) return;
    setSelectedSpecialistData(specialistData[0]);
  }, [specialistData]);
  useEffect(() => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const img = imgRef.current;

    if (!video || !canvas || !img) return;

    const handleLoadedMetadata = () => {
      video.currentTime = 0;
    };

    const handleTimeUpdate = () => {
      const context = canvas.getContext("2d");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      if (!context) return;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      const dataURL = canvas.toDataURL();

      setVideoPreviewImg(dataURL);
    };

    video.addEventListener("loadedmetadata", handleLoadedMetadata);
    video.addEventListener("timeupdate", handleTimeUpdate);

    return () => {
      video.removeEventListener("loadedmetadata", handleLoadedMetadata);
      video.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, [profileUserData, selectedSpecialistData]);

  const getSpecialistsSessionsAmount = async () => {
    if (!profileUserData) return;
    const response = await SessionsApi.getSpecialistsSessionsAmount(
      profileUserData._id
    );
    if (response.status) {
      setTotalInfo((prev) => ({ ...prev, sessionsTotal: response.amount }));
    }
  };
  const getSpecialistsComments = async (specialistId?: string) => {
    if (!profileUserData || !profileUserData._id || !selectedSpecialistData)
      return;
    const commentsResponse = await SessionsApi.getSpecialistsComments(
      profileUserData._id,
      100,
      1,
      selectedSpecialistData.specialistData._id
    );
    if (!specialistId && commentsResponse.status) {
      setTotalInfo((prev) => ({
        ...prev,
        reviewTotal: commentsResponse.totalAmount,
      }));
    }
    if (commentsResponse.status && specialistId)
      setCommentsData(commentsResponse.comments);
  };
  useEffect(() => {
    if (!profileUserData) return;
    getSpecialistsComments();
    getSpecialistsSessionsAmount();
    if (!selectedSpecialistData) return;
    getSpecialistsComments(selectedSpecialistData.specialistData._id);
  }, [selectedSpecialistData]);

  /*   const previewFileHandler = (link: string, type: string) => {
    if (type === "pdf") {
      setPreviewFile({ imageFile: null, pdfFile: link });
    } else {
      setPreviewFile({ imageFile: link, pdfFile: null });
    }
    setPreviewFileModal(true);
  };
 */
  const changeLangHandler = async (value: string) => {
    strings.setLanguage(value);
    setSelectLang(value);
  };

  const navigateToAuth = (authMode: "login" | "signup") => {
    localStorage.setItem("specUsername", userName);
    navigate("/login", {
      state: {
        authMode: authMode,
      },
    });
  };

  if (loaders.mainLoader || !profileUserData || !selectedSpecialistData) {
    return (
      <div className="loader-container">
        <ThemeProvider theme={theme}>
          <CircularProgress size={150} color="primary" />
        </ThemeProvider>
        <ToastContainer />
      </div>
    );
  }

  return (
    <div className={s.container} id="profile-content">
      <div className={s.langSelectBlock}>
        <button
          onClick={() => changeLangHandler("en")}
          className={selectLang === "en" ? s.selectLang : s.unselectLang}
        >
          EN
        </button>
        <button
          onClick={() => changeLangHandler("uk")}
          className={selectLang === "uk" ? s.selectLang : s.unselectLang}
        >
          УКР
        </button>
      </div>

      <div className={s.profileInfoBlock}>
        <div className={s.headBlock}>
          <div className={s.headBlockMainContent}>
            <div className={s.headNameBlock}>
              <div className={s.mobileAvatarBlock}>
                <CircleAvatar
                  userId={profileUserData?._id || ""}
                  name={profileUserData?.name || ""}
                  surname={profileUserData?.surname || ""}
                  height="auto"
                  width="100%"
                  fontSize="78px"
                  marginRight="0"
                  isModalAvatar
                  borderRadius="20px"
                  hideNoAvtaarBlock
                />
              </div>
              <div className={s.nameBlock}>
                <span className={s.nameTitle}>
                  {profileUserData?.name} {profileUserData?.surname}
                  {specialistData &&
                    selectedSpecialistData?.specialistData.isVerified && (
                      <VerifiedIcon className={s.verifyIcon} />
                    )}
                </span>
                <span className={s.ageTitle}>
                  {calculateAge(new Date(profileUserData!.dateOfBirth!)) +
                    " " +
                    strings.ageAbbr}
                </span>
                <div className={s.rightMenuInfoBlock}>
                  <SpecializationIcon />
                  <span>
                    {specialistData
                      ? selectedSpecialistData.specialistData.mainSpecializations[0].labels.find(
                          (item) => item.language === selectLang
                        )?.text + " "
                      : ""}
                    {specialistData &&
                    selectedSpecialistData.specialistData.level
                      ? coachLevels[
                          selectedSpecialistData.specialistData.level
                        ].toLowerCase()
                      : null}
                  </span>
                </div>
                <div className={s.rightMenuInfoBlock}>
                  <MessageIcon className={s.infoIcon} width={15} height={15} />
                  <span>
                    {profileUserData &&
                      profileUserData.preferredLanguages!.join(", ")}
                  </span>
                </div>
                <div className={s.rightMenuInfoBlock}>
                  <EearthIcon />
                  <span>{profileUserData && profileUserData.location}</span>
                </div>
              </div>
            </div>
            <div className={s.actionBlock}>
              <div className={s.guestProfileActionBlock}>
                {selectedSpecialistData.specialistData.isVerified && (
                  <StandartButton
                    buttonTitle={strings.bookProfileBtn}
                    action={() => setNavigateToRegModal(true)}
                    width="100%"
                  />
                )}
              </div>
            </div>
            {totalInfo.reviewTotal && (
              <div className={s.totalInfoBlock}>
                <span>{strings.reviewSpecProfile}</span>
                <span>{totalInfo.reviewTotal}</span>
              </div>
            )}
            {totalInfo.sessionsTotal && (
              <div className={s.totalInfoBlock}>
                <span>{strings.totalSessions}</span>
                <span>{totalInfo.sessionsTotal}</span>
              </div>
            )}
            {profileUserData &&
            profileUserData.briefDescription &&
            profileUserData.briefDescription.length ? (
              <div>
                {profileUserData.briefDescription.find(
                  (el) => el.language === selectLang
                )?.text ?? profileUserData.briefDescription[0].text}
              </div>
            ) : null}
          </div>
        </div>
        <div className={s.dynamiContentBlock}>
          {specialistData && specialistData.length > 1 && (
            <div className={s.tabsBlock}>
              {specialistData!.map((item, index) => (
                <div
                  className={
                    selectedSpecialistData!.specialistData
                      .mainSpecializations[0].specialization ===
                    item.specializationValue
                      ? s.activeTab
                      : s.notActiveTab
                  }
                  onClick={() => {
                    setSelectedSpecialistData(
                      specialistData!.find(
                        (el) =>
                          el.specializationValue === item.specializationValue
                      )
                    );
                  }}
                  key={index}
                >
                  <span>{item.specializationLable}</span>
                  <span
                    className={
                      selectedSpecialistData!.specialistData
                        .mainSpecializations[0].specialization ===
                      item.specializationValue
                        ? s.tabActiveIndicator
                        : s.indicatorHidden
                    }
                  ></span>
                </div>
              ))}
            </div>
          )}
          <div className={s.mainInfoBlock}>
            {commentsData.length &&
            selectedSpecialistData.specialistData.rating ? (
              <div className={s.headRatingBlock}>
                <div className={s.headRatingValueBlock}>
                  <GreenStar />
                  <div>
                    <span>
                      {selectedSpecialistData.specialistData.rating.toFixed(1)}
                    </span>
                    <span>{strings.specCardRating}</span>
                  </div>
                </div>
                <div className={s.expInfoBlock}>
                  <span>
                    {selectedSpecialistData.specialistData.yearsOfExperience}+
                  </span>
                  <span>{strings.setupExperienceTitle}</span>
                </div>
                <div className={s.expInfoBlock}>
                  <span>
                    {selectedSpecialistData.specialistData.practiceHours}+
                  </span>
                  <span>{strings.setupPracticalHours}</span>
                </div>
              </div>
            ) : null}
            <div className={s.aboutVideoBlock}>
              {introVideoId ? (
                <div className={s.previewVideoBlock}>
                  <canvas ref={canvasRef} style={{ display: "none" }} />
                  <img
                    ref={imgRef}
                    src={videoPreviewImg}
                    /*     alt="First frame of video" */
                    className={s.previewVideoImg}
                  />
                  <PlayBtnIcon
                    className={s.playBtn}
                    onClick={() => setVideoModalOpen(true)}
                  />
                </div>
              ) : null}
              {(selectedSpecialistData.specialistData.aboutMyself &&
                selectedSpecialistData.specialistData.aboutMyself.length) ||
              (selectedSpecialistData.specialistData.aboutTherapy &&
                selectedSpecialistData.specialistData.aboutTherapy.length) ? (
                <div className={s.aboutTitleBlock}>
                  <h4 className={s.blockLable}>{strings.aboutMeSpecProfile}</h4>
                  <ReactSpoiler
                    collapsedSize={
                      textHeight.aboutMyself > 250
                        ? 250
                        : textHeight.aboutMyself
                    }
                    toggleContainerStyle={{ height: "fit-content" }}
                    showMoreComponent={
                      <div
                        className={s.showMoreBtn}
                        style={{
                          display:
                            textHeight.aboutMyself > 250 ? "flex" : "none",
                        }}
                      >
                        <p>{strings.showMore}</p> <ChevronDown />
                      </div>
                    }
                    showLessComponent={
                      <div
                        className={s.showMoreBtn}
                        style={{
                          display:
                            textHeight.aboutMyself > 250 ? "flex" : "none",
                        }}
                      >
                        <p>{strings.showLess}</p>
                        <ChevronDown className={s.chevronRotate} />
                      </div>
                    }
                  >
                    <p className={s.aboutTitle} ref={aboutMyselfRef}>
                      {selectedSpecialistData.specialistData.aboutMyself &&
                      selectedSpecialistData.specialistData.aboutMyself.length
                        ? selectedSpecialistData.specialistData.aboutMyself.find(
                            (el) => el.language === selectLang
                          )?.text ??
                          selectedSpecialistData.specialistData.aboutMyself[0]
                            .text
                        : selectedSpecialistData.specialistData.aboutTherapy &&
                          selectedSpecialistData.specialistData.aboutTherapy
                            .length &&
                          (selectedSpecialistData.specialistData.aboutTherapy.find(
                            (el) => el.language === selectLang
                          )?.text ??
                            selectedSpecialistData.specialistData
                              .aboutTherapy[0].text)}
                    </p>
                  </ReactSpoiler>
                </div>
              ) : null}
            </div>
            {selectedSpecialistData.specialistData.aboutTherapy &&
            selectedSpecialistData.specialistData.aboutTherapy.length &&
            selectedSpecialistData.specialistData.aboutMyself &&
            selectedSpecialistData.specialistData.aboutMyself.length ? (
              <div className={s.aboutTitleBlock}>
                <h4 className={s.blockLable}>
                  {strings.howSessionGoesSpecProfile}
                </h4>
                <ReactSpoiler
                  collapsedSize={
                    textHeight.aboutTeraphy > 290
                      ? 300
                      : textHeight.aboutTeraphy
                  }
                  showMoreComponent={
                    <div
                      className={s.showMoreBtn}
                      style={{
                        display:
                          textHeight.aboutTeraphy > 250 ? "flex" : "none",
                      }}
                    >
                      <p>{strings.showMore}</p> <ChevronDown />
                    </div>
                  }
                  showLessComponent={
                    <div
                      className={s.showMoreBtn}
                      style={{
                        display:
                          textHeight.aboutTeraphy > 250 ? "flex" : "none",
                      }}
                    >
                      <p>{strings.showLess}</p>
                      <ChevronDown className={s.chevronRotate} />
                    </div>
                  }
                >
                  <p className={s.aboutTitle} ref={aboutTeraphyRef}>
                    {selectedSpecialistData.specialistData.aboutTherapy.find(
                      (el) => el.language === selectLang
                    )?.text ??
                      selectedSpecialistData.specialistData.aboutTherapy[0]
                        .text}
                  </p>
                </ReactSpoiler>
              </div>
            ) : null}
            {/*     {certificatesData && certificatesData.length ? (
            <div className={s.educationBlock}>
              <h4 className={s.blockLable}>{strings.educationSpecProfile}</h4>
              {actionLoaders.certificates ? (
                <div className={s.educationLoaderBlock}>
                  <ThemeProvider theme={theme}>
                    <CircularProgress size={50} color="primary" />
                  </ThemeProvider>
                </div>
              ) : (
                certificatesData.map((item, index) => (
                  <div
                    key={index}
                    className={s.educationElement}
                    style={{
                      marginBottom:
                        index < certificatesData.length - 1 ? "10px" : 0,
                    }}
                  >
                    <div className={s.leftEducationElContent}>
                      <div className={s.educationIconBlock}>
                        <FileIcon className={s.fileIcon} />
                      </div>
                      <div className={s.certificateTitleBlock}>
                        <Tooltip
                          title={
                            item.filename && item.filename.length > 30
                              ? item.filename
                              : ""
                          }
                        >
                          <span className={s.certificateTitle}>
                            {item.filename && item.filename.length > 30
                              ? item.filename?.slice(0, 30) + "..."
                              : item.filename}
                          </span>
                        </Tooltip>
                        <span>{formatFileSize(item.size!)}</span>
                      </div>
                    </div>
                    <EyeIcon
                      className={s.eyeIcon}
                      onClick={() => previewFileHandler(item.link, item.type!)}
                    />
                  </div>
                ))
              )}

              <div className={s.pagginationBlock}>
                <ThemeProvider theme={theme}>
                  <Pagination
                    page={certificatesPaggination.page}
                    count={Math.ceil(certificatesPaggination.total / 2)}
                    color="primary"
                    onChange={(_, page) =>
                      setCertificatesPaggination((prev) => ({
                        ...prev,
                        page: page,
                      }))
                    }
                    sx={{
                      "& .Mui-selected": {
                        color: "white",
                      },
                    }}
                  />
                </ThemeProvider>
              </div>
            </div>
          ) : null} */}

            {commentsData && commentsData.length ? (
              <div className={s.generalWhiteBlock}>
                <div className={s.reviewsBlock}>
                  <h4 className={s.blockLable}>
                    {strings.reviewSpecProfile} ({commentsData.length})
                  </h4>
                  <div className={s.commentsListBlock}>
                    {commentsData.map((item, index) => (
                      <div className={s.commentCard} key={index}>
                        <div className={s.ratingBlock}>
                          {Array.from({ length: item.rating }).map(
                            (_, index) => (
                              <StarIcon className={s.starIcon} key={index} />
                            )
                          )}
                          <span>{item.rating + ".0"}</span>
                        </div>
                        <div className={s.commentTextBlock}>
                          <p className={s.commentText}>{item.customComment}</p>

                          {item.defaultComments.map((item) => (
                            <p key={item.comment} className={s.commentText}>
                              {
                                item.labels.find(
                                  (lable) => lable.language === selectLang
                                )?.text
                              }
                            </p>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <video
        ref={videoRef}
        src={`${connectionString}specialists/getSpecialistPublicIntroVideo/${profileUserData?.username}/${selectedSpecialistData.specialistData._id}`}
        style={{ display: "none" }}
        crossOrigin="anonymous"
      />
      <ModalWindow
        isOpen={previewFileModal}
        setIsOpen={setPreviewFileModal}
        bgColor="transparent"
        height="fit-content"
        width={"fit-content"}
        padding="0"
        maxWidth="fit-content"
      >
        <div
          className={
            previewFile.pdfFile ? s.pdfPreviewBlock : s.imagePreviewBlock
          }
        >
          {previewFile.pdfFile && (
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
              <div className={s.pdfWorker}>
                <Viewer
                  fileUrl={previewFile.pdfFile}
                  plugins={[defaultLayoutPluginInstance]}
                />
              </div>
            </Worker>
          )}

          {previewFile.imageFile && (
            <img src={previewFile.imageFile} alt="" className={s.previewImg} />
          )}
        </div>
      </ModalWindow>
      <ModalWindow
        isOpen={videoModalOpen}
        setIsOpen={setVideoModalOpen}
        bgColor="transparent"
        width="fit-content"
      >
        <video
          src={`${connectionString}specialists/getSpecialistPublicIntroVideo/${profileUserData?.username}/${selectedSpecialistData.specialistData._id}`}
          /*   crossOrigin="anonymous" */
          controls
          autoPlay
          className={s.video}
        />
      </ModalWindow>
      <ModalWindow
        isOpen={navigateToRegModal}
        setIsOpen={setNavigateToRegModal}
        width="fit-content"
      >
        <div className={s.navigateModalContainer}>
          <h2>{strings.pleaseRegisterOrLoginTitle}</h2>
          <span>{strings.pleaseRegisterOrLoginDescription}</span>
          <div className={s.navigateModalBtnBlock}>
            <StandartButton
              buttonTitle={strings.logInModalBtn}
              action={() => navigateToAuth("login")}
              width="fit-content"
            />
            <StandartButton
              buttonTitle={strings.registerModalBtn}
              action={() => navigateToAuth("signup")}
              width="fit-content"
            />
          </div>
        </div>
      </ModalWindow>
      <ToastContainer />
    </div>
  );
};

export default PublicProfile;
