import { Booking } from "./TypeSession";
import { SpecialistData } from "./TypeSpecialist";

export enum Roles {
  ADMIN = "ADMIN",
  DEEFAULT_USER = "DEEFAULT_USER",
  SPECIALIST = "SPECIALIST",
  PSYCHOLOGIST = "PSYCHOLOGIST",
  COACH = "COACH",
  MENTOR = "MENTOR",
  MANAGER = "MANAGER",
}

export type TypeNewUser = {
  username: string;
  email: string;
  password: string;
  isSpecialist: boolean;
};
export type TypeUpdatedUserInfo = {
  _id: string;
  [key: string]: any;
};

export type DaySchedule = {
  time: string[];
  switch: boolean;
};

export type UserSchedule = {
  monday: DaySchedule;
  tuesday: DaySchedule;
  wednesday: DaySchedule;
  thursday: DaySchedule;
  friday: DaySchedule;
  saturday: DaySchedule;
  sunday: DaySchedule;
};

export type TypeUserData = {
  _id: string;
  specialistIds?: SpecialistData[];
  email: string;
  isAdmin: boolean;
  password: string;
  name?: string;
  surname?: string;
  gender?: string;
  avatar?: string;
  registerConfCode?: number;
  resendConfCodeDate?: string;
  registerFinished: boolean;
  registerConfirmed: boolean;
  roles?: string[];
  username: string;
  dateOfBirth?: Date;
  location?: string;
  preferredLanguages?: string[];
  bookings: Booking | [];
  balance: number;
  schedule?: UserSchedule;
  timezone: string;
  selectedLanguage: string;
  briefDescription?: { text: string; language: string }[];
};

export type TypeConfirmRegister = {
  id: string;
  code: number;
};
export type GetUserAvatar = {
  _id: string;
  avatar: string;
  name: string;
  surname: string;
  username: string;
};

export type Genders = {
  _id: string;
  gender: string;
  labels: { text: string; language: string }[];
};
