import ky from "ky";
import { network } from "../config";
import {
  Company,
  Project,
  Roles,
  TypeCompanyEmployee,
  TypeCompanyStatistic,
  TypeNewCompany,
} from "../types/Company";
import TypeDefaultResponse from "../types/TypeDefaultResponse";
import { Sessions } from "../types/TypeSession";
import { TypeUpdatedUserInfo } from "../types/TypeUsers";

const { companies } = network;

const baseURL = process.env.REACT_APP_HTTP_CONNECTION_STRING + "company/";

const getAuthHeader = (token: string) => {
  return { Authorization: `Bearer ${token}` };
};

export default class CompanyApi {
  static async createCompany(
    token: string,
    payload: { userId: string; ownerId: string; newCompany: TypeNewCompany }
  ): Promise<TypeDefaultResponse & { company: Company }> {
    return await ky
      .post(`${baseURL}${companies.createCompany}`, {
        headers: getAuthHeader(token),
        json: payload,
      })
      .then((res) => res.json());
  }

  static async updateCompany(
    token: string,
    payload: TypeUpdatedUserInfo
  ): Promise<TypeDefaultResponse & { company: Company }> {
    return await ky
      .post(`${baseURL}${companies.update}/${payload._id}`, {
        headers: getAuthHeader(token),
        json: { payload },
      })
      .then((res) => res.json());
  }

  static async deleteCompany(
    token: string,
    payload: { comapnyId: string }
  ): Promise<TypeDefaultResponse> {
    return await ky
      .post(`${baseURL}${companies.deleteCompany}`, {
        headers: getAuthHeader(token),
        json: { payload },
      })
      .then((res) => res.json());
  }

  static async getCompanyById(
    token: string,
    companyId: string
  ): Promise<TypeDefaultResponse & { company: Company }> {
    return await ky
      .get(`${baseURL}${companies.getCompanyById}/${companyId}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async getCompanyParticipants(
    token: string,
    companyId: string
  ): Promise<
    TypeDefaultResponse & { participants: TypeCompanyEmployee[] | null }
  > {
    return await ky
      .get(`${baseURL}${companies.getCompanyParticipants}/${companyId}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async joinCompany(
    token: string,
    payload: {
      userId: string;
      code: string;
    }
  ): Promise<TypeDefaultResponse> {
    return await ky
      .post(`${baseURL}${companies.joinCompany}`, {
        headers: getAuthHeader(token),
        json: payload,
      })
      .then((res) => res.json());
  }

  static async removeUserFromCompany(
    token: string,
    payload: {
      userIdToRemove: string;
      companyId: string;
    }
  ): Promise<
    TypeDefaultResponse & {
      newNotify?: {
        title: string;
        date: Date;
        for: string;
      } | null;
      emailMesage?: {
        email: string;
        companyName: string;
      };
    }
  > {
    return await ky
      .post(
        `${baseURL}${companies.removeUserFromCompany}/${payload.companyId}`,
        {
          headers: getAuthHeader(token),
          json: payload,
        }
      )
      .then((res) => res.json());
  }

  static async inviteUserToCompany(
    token: string,
    payload: {
      emails: string[];
      companyId: string;
      role: Roles.DEEFAULT_USER | Roles.MANAGER;
    }
  ): Promise<
    TypeDefaultResponse & {
      newNotify?: {
        title: string;
        date: Date;
        for: string;
      } | null;
      companyInvite?: {
        email: string;
        link: string;
        companyName: string;
      };
    }
  > {
    return await ky
      .post(`${baseURL}${companies.inviteUserToCompany}/${payload.companyId}`, {
        headers: getAuthHeader(token),
        json: payload,
      })
      .then((res) => res.json());
  }

  static async getCompanySessions(
    token: string,
    comapnyId: string
  ): Promise<TypeDefaultResponse & { sessions: Sessions[] | null }> {
    return await ky
      .get(`${baseURL}${companies.getCompanySessions}/${comapnyId}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async getCompanyStatistic(
    token: string,
    comapnyId: string
  ): Promise<
    TypeDefaultResponse & { statistics: TypeCompanyStatistic | null }
  > {
    return await ky
      .get(`${baseURL}${companies.getCompanyStatistic}/${comapnyId}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async getCompanyProjects(
    token: string,
    comapnyId: string
  ): Promise<TypeDefaultResponse & { projects: Project[] | null }> {
    return await ky
      .get(`${baseURL}${companies.getCompanyProjects}/${comapnyId}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async getCompaniesOfUser(
    token: string,
    userId: string
  ): Promise<TypeDefaultResponse & { companies?: Company[] | null }> {
    return await ky
      .get(`${baseURL}${companies.getCompaniesOfUser}/${userId}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async inviteBulkUsersToCompany(
    token: string,
    payload: {
      emailsList: string;
      companyId: string;
    }
  ): Promise<
    TypeDefaultResponse & {
      incorrectRoleLines: number[];
      incorrectEmailLines: number[];
      duplicateLines: number[][];
    }
  > {
    return await ky
      .post(
        `${baseURL}${companies.inviteBulkUsersToCompany}/${payload.companyId}`,
        {
          headers: getAuthHeader(token),
          json: payload,
          timeout: 300000,
        }
      )
      .then((res) => res.json());
  }
}
