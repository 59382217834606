import { Dispatch, SetStateAction } from "react";
import { ReactComponent as GrayToken } from "../../../assets/Subscriptions/heart-tick.svg";
import { ReactComponent as SelectedSubsciption } from "../../../assets/Subscriptions/selected-subscr.svg";
import strings from "../../../localization";
import { Subscription } from "../../../types/Subscription";
import s from "./SubscriptionCard.module.css";

type SubscriptionCardProps = {
  subscriptionData: Subscription;
  selectedSubscrip: Subscription | null;
  setSelectedSubscrip: Dispatch<SetStateAction<Subscription | null>>;
};

const SubscriptionCard = ({
  subscriptionData,
  selectedSubscrip,
  setSelectedSubscrip,
}: SubscriptionCardProps) => {
  return (
    <div
      className={s.subElementBlock}
      onClick={() => setSelectedSubscrip(subscriptionData)}
    >
      <div className={s.leftSubElementBlock}>
        {selectedSubscrip && selectedSubscrip._id === subscriptionData._id ? (
          <SelectedSubsciption />
        ) : (
          <span className={s.notSelectedSub}></span>
        )}
        <div className={s.titleSubBlock}>
          <span>{subscriptionData.title}</span>
        </div>
      </div>
      <div className={s.subCostBlock}>
        <span>
          {subscriptionData.costPerMonth}
          <GrayToken className={s.tokenIcon} />
        </span>
        <span>/{strings.periodOneMonth}</span>
      </div>
    </div>
  );
};

export default SubscriptionCard;
