import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import PostApi from "../../../api/postsApi";
import { ReactComponent as DeleteIcon } from "../../../assets/Notes/delete.svg";
import { ReactComponent as EditIcon } from "../../../assets/Notes/edit.svg";
import { ReactComponent as ChevronIcon } from "../../../assets/Social/chevron.svg";
import { ReactComponent as LikeIcon } from "../../../assets/Social/heart.svg";
import { ReactComponent as TripleDotIcon } from "../../../assets/Social/triple-dots.svg";
import { ContextProvider } from "../../../contextProvider";
import useAutosizeTextArea from "../../../hooks/useAutosizeTextarea";
import strings from "../../../localization";
import { Post, PostComments } from "../../../types/Posts";
import { formatNumber } from "../../../utils/Numbers";
import { formatDateTimeAgo } from "../../../utils/dateActions";
import CircleAvatar from "../../CircleAvatar/CircleAvatar";
import ConfirmationModal from "../../ConfirmationModal/ConfirmationModal";
import NavigateToProfile from "../../NavigateToProfile/NavigateToProfile";
import NewsfeedCard from "../NewsfeedCard/NewsfeedCard";
import s from "./CommentsModalContent.module.css";

type CommentsModalContentProps = {
  post: Post;
  allPosts: Post[];
  setAllPosts: Dispatch<SetStateAction<Post[]>>;
  images: string[];
  videos: string[];
  setSeletedImgIndex: Dispatch<SetStateAction<number>>;
  seletedImgIndex: number;
};

const CommentsModalContent = ({
  post,
  allPosts,
  setAllPosts,
  images,
  videos,
  setSeletedImgIndex,
  seletedImgIndex,
}: CommentsModalContentProps) => {
  const token = localStorage.getItem("token");
  const { userData } = useContext(ContextProvider);
  const [commentText, setCommentText] = useState("");
  const [commentsData, setCommentsData] = useState(post.comments);
  const [confirmDeleteCommentModal, setConfirmDeleteCommentModal] =
    useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const postBlockRef = useRef<HTMLDivElement>(null);
  const [selectedComment, setSelectedComment] = useState("");
  const [updateCommentData, setUpdateCommentData] = useState<{
    commentId: string;
    prevComment: string;
    newComment: string;
  } | null>(null);

  const [openCommentActionMenu, setOpenCommentActionMenu] = useState<
    number | null
  >(null);
  const commentActionMenuRef = useRef<HTMLDivElement | null>(null);

  useAutosizeTextArea(
    textAreaRef.current,
    updateCommentData ? updateCommentData.newComment : commentText
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        commentActionMenuRef.current &&
        !commentActionMenuRef.current.contains(event.target as Node)
      ) {
        setOpenCommentActionMenu(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [commentActionMenuRef]);

  const addCommentToPost = (postId: string, newComment: PostComments) => {
    setAllPosts((prevPosts) =>
      prevPosts.map((post) =>
        post._id === postId
          ? { ...post, comments: [...post.comments, newComment] }
          : post
      )
    );
  };

  const sortCommentsByDate = (comments: PostComments[]): PostComments[] => {
    return comments.sort(
      (a, b) =>
        new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
    );
  };

  const leaveComment = async () => {
    if (!token || !userData || !commentText.replace(/\s+/g, "").length) return;
    const payload = {
      postId: post._id,
      comment: commentText,
    };
    const response = await PostApi.commentPost(token, payload);
    if (response.status && response.comment) {
      setCommentsData((prev) => [response.comment, ...prev]);
      addCommentToPost(post._id, response.comment);
      setCommentText("");
    }
  };

  const deleteCommentFromPost = (postId: string, commentId: string) => {
    setAllPosts((prevPosts) =>
      prevPosts.map((post) =>
        post._id === postId
          ? {
              ...post,
              comments: post.comments.filter(
                (comment) => comment._id !== commentId
              ),
            }
          : post
      )
    );
  };

  const deleteComment = async (commentId: string) => {
    if (!token) return;
    const response = await PostApi.removeComment(token, commentId);

    if (response.status) {
      const updatedComments = commentsData.filter((el) => el._id !== commentId);
      setCommentsData(updatedComments);
      deleteCommentFromPost(post._id, commentId);
      setSelectedComment("");
      setConfirmDeleteCommentModal(false);
    }
  };

  const updateComment = async () => {
    if (!token || !updateCommentData) return;
    const paylaod = {
      commentId: updateCommentData.commentId,
      newComment: updateCommentData.newComment,
    };
    const response = await PostApi.updateComment(token, paylaod);

    if (response.status) {
      setCommentsData((prev) =>
        prev.map((item) =>
          item._id === updateCommentData.commentId
            ? { ...item, comment: updateCommentData.newComment }
            : item
        )
      );
      setUpdateCommentData(null);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (updateCommentData) {
        updateComment();
      } else {
        leaveComment();
      }
    }
  };

  const likeComment = async (commentId: string) => {
    if (!token || !userData) return;
    const payload = {
      userId: userData._id,
      commentId: commentId,
    };
    const response = await PostApi.likeComment(token, payload);

    if (response.status) {
      setCommentsData((prev) =>
        prev.map((item, index) =>
          item._id === commentId
            ? {
                ...item,
                likes: [
                  ...item.likes,
                  { _id: index.toString(), user: userData._id },
                ],
              }
            : item
        )
      );
    }
  };

  const unlikeComment = async (commentId: string) => {
    if (!token || !userData) return;
    const payload = {
      userId: userData._id,
      commentId: commentId,
    };
    const response = await PostApi.unlikeComment(token, payload);

    if (response.status) {
      setCommentsData((prev) =>
        prev.map((item) =>
          item._id === commentId
            ? {
                ...item,
                likes: item.likes.filter((el) => el.user !== userData._id),
              }
            : item
        )
      );
    }
  };

  const checkMyselfLike = (likesArr: { _id: string; user: string }[]) => {
    if (!userData) return;
    if (likesArr.length === 0) return false;
    const likesUserIdArr = likesArr.map((item) => item.user);
    return likesUserIdArr.includes(userData._id);
  };

  return (
    <div
      className={
        videos.length || images.length ? s.conteiner : s.containerWithoutMedia
      }
    >
      {images.length || videos.length ? (
        <div className={s.mediaBlock}>
          {videos.length ? (
            <div className={s.postVideoBlock}>
              <video src={videos[0]} controls />{" "}
            </div>
          ) : null}
          {images.length ? (
            <div className={s.postImgBlock}>
              <div className={s.bgImg}>
                <img src={images[seletedImgIndex]} alt="" className={s.bgImg} />
              </div>
              {images.length > 1 && (
                <ChevronIcon
                  className={s.chevronLeft}
                  style={{
                    opacity: seletedImgIndex === 0 ? 0.5 : 1,
                  }}
                  onClick={() =>
                    setSeletedImgIndex((prev) => (prev > 0 ? prev - 1 : prev))
                  }
                />
              )}
              {images.length
                ? images.map((item, index) => (
                    <img
                      src={item}
                      alt=""
                      key={index}
                      className={
                        seletedImgIndex === index ? s.postImg : s.postImgHidden
                      }
                    />
                  ))
                : null}
              {images.length > 1 && (
                <ChevronIcon
                  className={s.chevronRight}
                  style={{
                    opacity: images.length - 1 === seletedImgIndex ? 0.5 : 1,
                  }}
                  onClick={() =>
                    setSeletedImgIndex((prev) =>
                      prev < images.length - 1 ? prev + 1 : prev
                    )
                  }
                />
              )}
            </div>
          ) : null}
        </div>
      ) : null}
      <div className={s.postCommentsBlock}>
        <div className={s.postBlock} ref={postBlockRef}>
          <NewsfeedCard
            allPosts={allPosts}
            post={post}
            setAllPosts={setAllPosts}
            mainPadding="0px"
            hiddenActionsPost
            showMedia={false}
          />
        </div>
        <div className={s.commentsContainer}>
          <div className={s.textAreaBlock}>
            {updateCommentData && (
              <div className={s.editingBlock}>
                <span>{strings.editingTitle}</span>
                <span>{updateCommentData?.prevComment}</span>
              </div>
            )}
            <textarea
              rows={1}
              value={
                updateCommentData ? updateCommentData.newComment : commentText
              }
              onKeyDown={handleKeyDown}
              onChange={(e) => {
                if (e.target.value.length > 400) return;
                if (updateCommentData) {
                  //@ts-expect-error
                  setUpdateCommentData((prev) => ({
                    ...prev,
                    newComment: e.target.value,
                  }));
                  return;
                }
                setCommentText(e.target.value);
              }}
              className={s.postInput}
              placeholder="Start..."
              ref={textAreaRef}
            />
            <div
              onClick={() =>
                updateCommentData ? updateComment() : leaveComment()
              }
              role="btn"
              className={s.sendBtn}
            >
              {strings.postComment}
            </div>
          </div>
          <div className={s.commentsListBlock}>
            {commentsData && commentsData.length
              ? sortCommentsByDate(commentsData).map((item, index) => (
                  <div
                    key={index}
                    className={
                      item.user._id === userData?._id
                        ? `${s.commentContainer} ${s.myCommentConteiner}`
                        : `${s.commentContainer} ${s.otherCommentConteiner}`
                    }
                  >
                    <NavigateToProfile userId={item.user._id}>
                      <CircleAvatar userId={item.user._id} />
                    </NavigateToProfile>
                    <div className={s.commentContentBlock}>
                      <div className={s.nameCommentAuthorBlock}>
                        <span
                          style={{
                            marginRight:
                              item.user._id === userData?._id ? "30px" : 0,
                          }}
                        >
                          {item.user.name + " " + item.user.surname}
                        </span>

                        {item.user._id === userData?._id && (
                          <div className={s.commentActionBlock}>
                            <span className={s.commentDate}>
                              {formatDateTimeAgo(
                                new Date(item.createdDate),
                                userData!.selectedLanguage
                              )}
                            </span>
                            <span
                              className={s.actionCommentMenuBtn}
                              onClick={() =>
                                setOpenCommentActionMenu((prev) =>
                                  prev === index ? null : index
                                )
                              }
                            >
                              <TripleDotIcon />
                            </span>
                            {openCommentActionMenu === index && (
                              <div
                                className={s.actionMenuComment}
                                style={{
                                  display:
                                    openCommentActionMenu !== null
                                      ? "flex"
                                      : "none",
                                }}
                                ref={commentActionMenuRef}
                              >
                                <span
                                  className={s.editButtonBlock}
                                  role="button"
                                  onClick={() => {
                                    setOpenCommentActionMenu(null);
                                    setUpdateCommentData({
                                      commentId: item._id,
                                      newComment: item.comment,
                                      prevComment: item.comment,
                                    });
                                  }}
                                >
                                  <EditIcon className={s.editIcon} />
                                  <span>{strings.editSocial}</span>
                                </span>

                                <span
                                  className={s.deleteButtonBlock}
                                  role="button"
                                  onClick={() => {
                                    setOpenCommentActionMenu(null);
                                    setSelectedComment(item._id);
                                    setConfirmDeleteCommentModal(true);
                                  }}
                                >
                                  <DeleteIcon className={s.deletIcon} />
                                  <span>{strings.deleteSocial}</span>
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                      <span>{item.comment}</span>
                      <div className={s.footerComment}>
                        <div className={s.likeBlock}>
                          <div
                            role="button"
                            className={s.actionIconBtn}
                            onClick={() =>
                              checkMyselfLike(item.likes)
                                ? unlikeComment(item._id)
                                : likeComment(item._id)
                            }
                          >
                            <LikeIcon
                              className={`${
                                checkMyselfLike(item.likes)
                                  ? s.myselfLikeIcon
                                  : s.actionIconLike
                              }`}
                            />
                          </div>
                          <span>{formatNumber(item.likes.length)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>
      <ConfirmationModal
        confirmFunc={() => deleteComment(selectedComment)}
        modalVisible={confirmDeleteCommentModal}
        setModalVisible={setConfirmDeleteCommentModal}
        confirmTitle={strings.confirmDeleteCommentTitle}
      />
    </div>
  );
};

export default CommentsModalContent;
