import CircularProgress from "@mui/material/CircularProgress";
import { ThemeProvider } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import CompanyApi from "./api/companyApi";
import NotificationsApi from "./api/notifications";
import ProjectApi from "./api/projectApi";
import UsersApi from "./api/usersApi";
import "./App.css";
import { network } from "./config";
import { ContextProvider, TypeContextProvider } from "./contextProvider";
import Auth from "./layouts/Auth/Auth";
import Main from "./layouts/Main/Main";
import strings from "./localization";
import store from "./redux-toolkit/store";
import { Company } from "./types/Company";
import { NotificationsData } from "./types/Notifications";
import { TypeUserData } from "./types/TypeUsers";
import { areUserDataEqual, logout } from "./utils/general";
import socket from "./utils/socket";
import { theme } from "./utils/theme";
import "./variables.scss";

const { notifies, specialists } = network;

const App = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const code = localStorage.getItem("code");
  const isSpecialistStored = localStorage.getItem("isSpecialist");
  const isSpecialistUser =
    isSpecialistStored !== null ? JSON.parse(isSpecialistStored) : false;
  const [isAuth, setIsAuth] = useState(false);
  const [userData, setUserData] = useState<TypeUserData | null | undefined>(
    null
  );
  const [isLoader, setIsLoader] = useState(true);
  const [isSetupProfileInfo, setIsSetupProfileInfo] = useState<
    boolean | undefined
  >();
  const [isSpecialist, setIsSpecialist] = useState(false);
  const [notifiesData, setNotifiesData] = useState<NotificationsData[]>([]);
  const [userCompany, setUserCompany] = useState<Company[] | null>(null);
  const [selectLang, setSelectLang] = useState<string | null>(null);

  useEffect(() => {
    if (!selectLang) return;

    strings.setLanguage(selectLang);
    localStorage.setItem("lang", selectLang);
  }, [selectLang]);

  useEffect(() => {
    const makeAsync = async () => {
      if (!token || !userData) return;
      const checkJoinCompanyResponse =
        await UsersApi.checkIfUserJoinedAnyCompany(token, userData._id);

      if (!checkJoinCompanyResponse) {
        localStorage.setItem("userHavePrograms", "false");
      }
      if (checkJoinCompanyResponse) {
        localStorage.setItem("userHavePrograms", "true");
      }
    };
    makeAsync();
    /*  if (isAuth && userData && userData.roles) {
      if (localStorage.getItem("isSpecialist"))
        localStorage.removeItem("isSpecialist");
      localStorage.setItem(
        "isSpecialist",
        userData.roles.includes("SPECIALIST")
          ? true.toString()
          : false.toString()
      );
    } */
  }, [isAuth, userData]);

  useEffect(() => {
    if (!token || !userData || !userData._id) return;
    socket.emit(notifies.joinNotifyChannel, userData?._id);
    socket.on(notifies.sendNotify, (data) => {
      setNotifiesData(data);
    });

    setSelectLang(userData.selectedLanguage);
    localStorage.setItem("lang", userData.selectedLanguage);
    if (!isSpecialistUser) return;

    socket.emit(specialists.connectSpecialist, userData?._id);
  }, [token, userData]);

  useEffect(() => {
    const makeAsync = async () => {
      if (token) {
        setIsAuth(true);
        try {
          const responseUserData = await UsersApi.getUserByToken(token);
          setIsLoader(false);
          if (areUserDataEqual(responseUserData, userData))
            return setIsLoader(false);
          if (responseUserData && responseUserData.roles) {
            setIsAuth(true);
            setUserData(responseUserData);
            setIsLoader(false);
          } else {
            logout();
            setIsAuth(false);
            setIsLoader(false);
          }
        } catch (error: any) {
          if (error.response && error.response.status === 401) {
            logout();
            setIsAuth(false);
            setIsLoader(false);
          } else {
            console.error("Error during API request:", error);
            logout();
            setIsAuth(false);
            setIsLoader(false);
          }
        }
      }
      if (!token) {
        setIsSetupProfileInfo(false);
      }
      setIsLoader(false);
    };

    makeAsync();
  }, [isSetupProfileInfo, token]);

  useEffect(() => {
    const makeAsync = async () => {
      if (token && code && userData && userData._id && isSetupProfileInfo) {
        const payload = {
          userId: userData._id,
          code: code,
        };

        const joinCompanyResponse = await CompanyApi.joinCompany(
          token,
          payload
        );
        if (joinCompanyResponse.status) {
          localStorage.removeItem("code");
        }

        const joinProjectResponse = await ProjectApi.joinProject(
          token,
          payload
        );

        if (joinProjectResponse.status) {
          localStorage.removeItem("code");
        }
      }
      if (token && userData && userData._id) {
        const companyResponse = await CompanyApi.getCompaniesOfUser(
          token,
          userData._id
        );
        if (companyResponse.companies && companyResponse.companies.length) {
          setUserCompany(companyResponse.companies);
          localStorage.setItem("haveCompany", "true");
        }

        const notifiesResposne = await NotificationsApi.getUnreadNotifies(
          token,
          userData._id
        );

        if (notifiesResposne.status) {
          setNotifiesData(notifiesResposne.notifies);
        }
      }
      if (userData && userData.roles && userData.roles.includes("SPECIALIST")) {
        setIsSetupProfileInfo(userData.schedule ? true : false);
        {
          !userData.schedule &&
            userData.registerConfirmed &&
            navigate("setup-professional-info");
        }
      } else if (userData) {
        if (userData?.registerFinished) {
          setIsSetupProfileInfo(true);
        } else {
          setIsSetupProfileInfo(false);
          navigate(
            userData.registerConfirmed ? "/user-registration" : "/confirm"
          );
        }
      }
    };
    makeAsync();
  }, [userData]);

  const contextValue: TypeContextProvider = {
    isAuth,
    setIsAuth,
    userData,
    setUserData,
    isSetupProfileInfo,
    setIsSetupProfileInfo,
    isSpecialist,
    setIsSpecialist,
    notifiesData,
    setNotifiesData,
    userCompany,
    setSelectLang,
    selectLang,
  };

  if (isLoader || isSetupProfileInfo === undefined) {
    return (
      <div className="loader-container">
        <ThemeProvider theme={theme}>
          <CircularProgress size={150} color="primary" />
        </ThemeProvider>
      </div>
    );
  }

  return (
    <ContextProvider.Provider value={contextValue}>
      <Provider store={store}>
        {isAuth && isSetupProfileInfo ? <Main /> : <Auth />}
        <ToastContainer />
      </Provider>
    </ContextProvider.Provider>
  );
};

export default App;
