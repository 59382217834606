import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import SessionsApi from "../../../../../api/sessionApi";
import { ReactComponent as Token } from "../../../../../assets/Header/token-icon.svg";
import { ReactComponent as Clock } from "../../../../../assets/SpecTools/clock.svg";
import { ReactComponent as GrayVerify } from "../../../../../assets/SpecTools/gray-verify.svg";
import { ReactComponent as PlayCircle } from "../../../../../assets/SpecTools/play-circle.svg";
import ConfirmationModal from "../../../../../components/ConfirmationModal/ConfirmationModal";
import Loader from "../../../../../components/Loader/Loader";
import ModalWindow from "../../../../../components/ModalWindow/ModalWindow";
import { ContextProvider } from "../../../../../contextProvider";
import strings from "../../../../../localization";
import { SessionPackage } from "../../../../../types/TypeSession";
import { SpecialistData } from "../../../../../types/TypeSpecialist";
import { TypeUserData } from "../../../../../types/TypeUsers";
import { SuccesNotify } from "../../../../../utils/toaster";
import PackageBooking from "../PackageBooking/PackageBooking";
import s from "./PackageCard.module.css";

type PackageCardProps = {
  sessionPackage: SessionPackage;
  specialistData: SpecialistData | undefined;
  setPackagesData: Dispatch<
    SetStateAction<{
      packagesData: SessionPackage[];
      overlaps: {
        day: string;
        overlapTime: {
          start: Date;
          end: Date;
        }[];
      }[];
    } | null>
  >;
  profileUserData: TypeUserData | null | undefined;
  overlaps: {
    day: string;
    overlapTime: {
      start: Date;
      end: Date;
    }[];
  }[];
};

const PackageCard = ({
  sessionPackage,
  profileUserData,
  overlaps,
  setPackagesData,
  specialistData,
}: PackageCardProps) => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const { userData } = useContext(ContextProvider);
  const [overlapTime, setOverlapTime] = useState<{ start: Date; end: Date }[]>([
    { start: new Date(), end: new Date() },
  ]);
  const [bookingModal, setBookingModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [canBook, setCanBook] = useState(false);
  const [actionLoaders, setActionLoaders] = useState({
    checkCanBooksLoader: false,
  });

  useEffect(() => {
    const allOverlaps: { start: Date; end: Date }[] = overlaps.flatMap(
      (dayOverlap) => dayOverlap.overlapTime
    );
    setOverlapTime(allOverlaps);
  }, [overlaps]);

  const getAllSessionDuration = () => {
    if (!sessionPackage.sessions.length) return 0;
    const durationArr = sessionPackage.sessions.map(
      (item) => item.datesAndTime[0].duration
    );
    let result = durationArr.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);
    result = Number((result / 60).toFixed(1));
    return result;
  };

  const deletePackage = async () => {
    if (!token) return;
    const response = await SessionsApi.deletePackage(token, sessionPackage._id);
    if (response.status) {
      SuccesNotify(strings.deletePackageNotify);
      setDeleteModal(false);
      setPackagesData((prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          packagesData: prev.packagesData.filter(
            (el) => el._id !== sessionPackage._id
          ),
        };
      });
    }
  };

  useEffect(() => {
    const makeAsync = async () => {
      if (!token || !userData || !sessionPackage) return;
      setActionLoaders((prev) => ({ ...prev, checkCanBooksLoader: true }));
      const response = await SessionsApi.checkIfCanBookPackage(
        token,
        userData._id,
        sessionPackage._id
      );
      setActionLoaders((prev) => ({ ...prev, checkCanBooksLoader: false }));
      if (response.status) {
        setCanBook(response.canBook);
      }
    };
    makeAsync();
  }, [sessionPackage]);

  return (
    <div className={s.container}>
      <div className={s.headBlock}>
        <h2>{sessionPackage.title}</h2>
        <div className={s.priceBlock}>
          {(sessionPackage.packageCost / 100).toFixed(0)}
          <Token />
        </div>
      </div>
      <div className={s.generalInfoBlock}>
        <span>
          <PlayCircle />{" "}
          {sessionPackage.sessions.length + " " + strings.sections}
        </span>
        <span>
          <Clock /> {getAllSessionDuration() + " " + strings.hoursLength}
        </span>
      </div>
      {sessionPackage.description && (
        <div
          className={s.description}
          dangerouslySetInnerHTML={{ __html: sessionPackage.description }}
        ></div>
      )}
      <div
        role="button"
        className={s.detailsBtn}
        onClick={() => navigate(`package-info/${sessionPackage._id}`)}
      >
        {strings.detailsBtn}
      </div>
      <div className={s.footerBlock}>
        <div className={s.authorNameBlock}>
          {profileUserData &&
            profileUserData.name + " " + profileUserData.surname}
          {specialistData?.isVerified && <GrayVerify />}
        </div>
        <div className={s.btnsBlock}>
          {actionLoaders.checkCanBooksLoader ? (
            <div>
              <Loader size={40} />
            </div>
          ) : (
            <button
              className={s.buyNowBtn}
              disabled={
                profileUserData?._id === userData?._id
                  ? false
                  : canBook
                  ? false
                  : true
              }
              style={{
                opacity:
                  profileUserData?._id === userData?._id
                    ? 1
                    : canBook
                    ? 1
                    : 0.5,
              }}
              onClick={() => {
                profileUserData?._id !== userData?._id
                  ? setBookingModal(true)
                  : navigate("tools/package", {
                      state: {
                        packageId: sessionPackage._id,
                      },
                    });
              }}
            >
              {profileUserData?._id !== userData?._id
                ? strings.buyNowBtn
                : strings.editPackageBtn}
            </button>
          )}
          {profileUserData?._id === userData?._id && (
            <button
              className={s.deleteBtn}
              onClick={() => setDeleteModal(true)}
            >
              {strings.deletetaskBtn}
            </button>
          )}
        </div>
      </div>
      <ModalWindow
        isOpen={bookingModal}
        setIsOpen={setBookingModal}
        width="fit-content"
      >
        <PackageBooking
          overlapTime={overlapTime}
          packageId={sessionPackage._id}
          sessions={sessionPackage.sessions.map((item) => ({
            duration: item.datesAndTime[0].duration,
            order: item.order,
            title: item.title,
            description: item.description,
          }))}
          setBookingModal={setBookingModal}
          packagePrice={sessionPackage.packageCost}
          specialistUserId={profileUserData?._id!}
          specialistId={specialistData?._id!}
        />
      </ModalWindow>
      <ConfirmationModal
        confirmFunc={deletePackage}
        confirmTitle={strings.deletePackageConfirm}
        modalVisible={deleteModal}
        setModalVisible={setDeleteModal}
      />
    </div>
  );
};

export default PackageCard;
