import ky from "ky";
import { network } from "../config";
import TypeDefaultResponse from "../types/TypeDefaultResponse";
import {
  Todo,
  TodoList,
  TypeNewTodo,
  TypeNewTodoList,
} from "../types/TypeToDo";
import { TypeUpdatedUserInfo } from "../types/TypeUsers";

const { todos } = network;

const baseURL = process.env.REACT_APP_HTTP_CONNECTION_STRING + "todo-api/";

const getAuthHeader = (token: string) => {
  return { Authorization: `Bearer ${token}` };
};

export default class TodosAPI {
  static async createTodoList(
    token: string,
    payload: { newTodoList: TypeNewTodoList }
  ): Promise<
    TypeDefaultResponse & {
      todoList?: TodoList | null;
    }
  > {
    return await ky
      .post(`${baseURL}${todos.createTodoList}`, {
        headers: getAuthHeader(token),
        json: payload,
      })
      .then((res) => res.json());
  }

  static async createTodo(
    token: string,
    payload: { newTodo: TypeNewTodo; todoListId: string }
  ): Promise<TypeDefaultResponse & { todo?: Todo }> {
    return await ky
      .post(`${baseURL}${todos.createTodo}/${payload.todoListId}`, {
        headers: getAuthHeader(token),
        json: payload,
      })
      .then((res) => res.json());
  }

  static async updateTodo(
    token: string,
    updateData: TypeUpdatedUserInfo,
    todoListId: string
  ): Promise<TypeDefaultResponse & { todo?: Todo }> {
    return await ky
      .post(`${baseURL}${todos.update}/${todoListId}`, {
        headers: getAuthHeader(token),
        json: { updateData },
      })
      .then((res) => res.json());
  }

  static async updateTodoList(
    token: string,
    updateData: TypeUpdatedUserInfo
  ): Promise<
    TypeDefaultResponse & {
      todoList?: TodoList | null;
    }
  > {
    return await ky
      .post(`${baseURL}${todos.updateTodoList}/${updateData._id}`, {
        headers: getAuthHeader(token),
        json: { updateData },
      })
      .then((res) => res.json());
  }

  static async deleteTodo(
    token: string,
    todoId: string,
    todoListId: string
  ): Promise<TypeDefaultResponse> {
    return await ky
      .post(`${baseURL}${todos.deleteTodo}/${todoListId}`, {
        headers: getAuthHeader(token),
        json: { todoId },
      })
      .then((res) => res.json());
  }

  static async getUserTodos(
    token: string,
    userId: string,
    limit: number,
    page: number,
    sortType?: 1 | -1,
    sortField?: string
  ): Promise<TypeDefaultResponse & { todoLists: TodoList[]; total: number }> {
    return await ky
      .get(
        `${baseURL}${todos.getUserTodos}/${userId}/${limit}/${page}/${sortType}/${sortField}`,
        {
          headers: getAuthHeader(token),
        }
      )
      .then((res) => res.json());
  }

  static async deleteTodoList(
    token: string,
    todoListId: string
  ): Promise<TypeDefaultResponse> {
    return await ky
      .post(`${baseURL}${todos.deleteTodoList}/${todoListId}`, {
        headers: getAuthHeader(token),
        json: { todoListId },
      })
      .then((res) => res.json());
  }
}
