import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs, { Dayjs } from "dayjs";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import strings from "../../../../localization";
import s from "./WorkTimeInput.module.css";

type TypeWorkTimeInput = {
  inputsValue: Schedule;
  setInputsValue: Dispatch<SetStateAction<Schedule>>;
  mainIndex: number;
  timeIndex: number;
  height?: string;
  isForProfile?: boolean;
};
type Schedule = {
  dayValue: string;
  daytitle: string;
  isOpen: boolean;
  workTime: { from: string; to: string }[];
  switch: boolean;
}[];
const WorkTimeInput = ({
  inputsValue,
  setInputsValue,
  timeIndex,
  mainIndex,
  height,
  isForProfile,
}: TypeWorkTimeInput) => {
  const timeWork = {
    from: "",
    to: "",
    isDayOff: false,
  };
  const [defualtValue, setDefualtValue] = useState<{
    from: Dayjs;
    to: Dayjs;
  } | null>(null);

  useEffect(() => {
    if (
      !inputsValue ||
      !inputsValue[mainIndex] ||
      !inputsValue[mainIndex].workTime[timeIndex] ||
      inputsValue[mainIndex].workTime[timeIndex].from === "" ||
      inputsValue[mainIndex].workTime[timeIndex].to === ""
    )
      return;

    setDefualtValue({
      from: dayjs(inputsValue[mainIndex].workTime[timeIndex].from, "hh:mm"),
      to: dayjs(inputsValue[mainIndex].workTime[timeIndex].to, "hh:mm"),
    });
  }, [inputsValue, mainIndex, timeIndex]);

  const handleTimeChange = (newValue: string, field: "from" | "to") => {
    setInputsValue((prevInputs) => {
      const updatedInputs = [...prevInputs];
      updatedInputs[mainIndex] = {
        ...updatedInputs[mainIndex],
        workTime: [...updatedInputs[mainIndex].workTime],
      };
      updatedInputs[mainIndex].workTime[timeIndex] = {
        ...updatedInputs[mainIndex].workTime[timeIndex],
        [field]: newValue,
      };
      return updatedInputs;
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className={s.timeInputElement}>
        {timeWork.isDayOff ? (
          <div style={{ display: "flex", width: "100%" }}>
            <h3 style={{ width: "100%", textAlign: "center" }}>
              {strings.dayOff}
            </h3>
          </div>
        ) : (
          <div
            className={
              isForProfile ? s.forProfilePickersContainer : s.pickersContainer
            }
          >
            <div
              style={{
                /* marginBottom: "10px", */ height: height ?? "auto",
                display: "flex",
                alignItems: "center",
              }}
            >
              <DemoItem>
                <TimePicker
                  ampm={false}
                  sx={{ width: "120px" }}
                  value={defualtValue ? defualtValue.from : null}
                  onChange={(value) => {
                    const stateTime =
                      (dayjs(value).hour().toString().length > 1
                        ? dayjs(value).hour()
                        : "0" + dayjs(value).hour()) +
                      ":" +
                      (dayjs(value).minute().toString().length > 1
                        ? dayjs(value).minute()
                        : "0" + dayjs(value).minute());

                    handleTimeChange(
                      stateTime === "NaN:NaN" ? "" : stateTime,
                      "from"
                    );
                  }}
                />
              </DemoItem>
            </div>
            <div
              style={{
                /* marginBottom: "15px", */ height: height ?? "auto",
                display: "flex",
                alignItems: "center",
              }}
            >
              <DemoItem>
                <TimePicker
                  ampm={false}
                  sx={{ width: "120px" }}
                  value={defualtValue ? defualtValue.to : null}
                  onChange={(value) => {
                    const stateTime =
                      (dayjs(value).hour().toString().length > 1
                        ? dayjs(value).hour()
                        : "0" + dayjs(value).hour()) +
                      ":" +
                      (dayjs(value).minute().toString().length > 1
                        ? dayjs(value).minute()
                        : "0" + dayjs(value).minute());

                    handleTimeChange(
                      stateTime === "NaN:NaN" ? "" : stateTime,
                      "to"
                    );
                  }}
                />
              </DemoItem>
            </div>
          </div>
        )}
      </div>
    </LocalizationProvider>
  );
};

export default WorkTimeInput;
