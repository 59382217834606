import { useContext, useEffect, useRef, useState } from "react";
import CompanyApi from "../../api/companyApi";
import ProjectApi from "../../api/projectApi";
import SearchIcon from "../../assets/Company/magnifier.svg";
import { ReactComponent as ChevronIcon } from "../../assets/HomePage/chevron-up.svg";
import PlusIcon from "../../assets/Notes/plus.svg";
import ModalWindow from "../../components/ModalWindow/ModalWindow";
import StandartButton from "../../components/StandartButton/StandartButton";
import { ContextProvider } from "../../contextProvider";
import { Project, Roles, TypeCompanyEmployee } from "../../types/Company";
import { TypeNewProject } from "../../types/Projects";
import { formateDate, getUserAvatars } from "../../utils/general";
import Notify, { SuccesNotify } from "../../utils/toaster";
import ProgramCard from "./ProgramCard/ProgramCard";
import s from "./Programs.module.css";

import { CircularProgress, ThemeProvider } from "@mui/material";
import CircleAvatar from "../../components/CircleAvatar/CircleAvatar";
import Input from "../../components/Input/Input";
import Loader from "../../components/Loader/Loader";
import MuiSwitch from "../../components/MuiSwitch/MuiSwitch";
import strings from "../../localization";
import { theme } from "../../utils/theme";

const Programs = () => {
  const token = localStorage.getItem("token");
  const { userCompany } = useContext(ContextProvider);
  const [createProgramModalVisible, setCreateProgramModalVisible] =
    useState(false);
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const companyEmployers = useRef<TypeCompanyEmployee[] | null>([]);
  const [inviteEmailData, setInviteEmailData] = useState<string[]>([]);

  const [filteredEmployers, setFilteredEmployers] = useState<
    TypeCompanyEmployee[] | null
  >([]);
  const [inviteToProjectModalVisible, setInviteToProjectModalVisible] =
    useState(false);
  const [companyProgramsData, setCompanyProgramsData] = useState<
    Project[] | null
  >(null);
  const [selectedProgram, setSelectedProgram] = useState<Project | null>(null);
  const [newProgramData, setNewProgramData] = useState<TypeNewProject>({
    title: "",
    description: "",
    creationDate: new Date(),
    endDate: new Date(),
    clientLimit: 1,
    specialistLimit: 1,
    isPrivate: true,
  });
  const [actionLoader, setActionLoaders] = useState({
    inviteUser: false,
    loadProgrmas: false,
  });
  const [availableInviteUsers, setAvailableInviteUsers] = useState<
    TypeCompanyEmployee[] | null
  >([]);

  useEffect(() => {
    if (token && userCompany) {
      const makeAsync = async () => {
        setActionLoaders((prev) => ({ ...prev, loadProgrmas: true }));
        const companyProgramsResponse = await CompanyApi.getCompanyProjects(
          token,
          userCompany[0]._id
        );

        if (
          companyProgramsResponse.status &&
          companyProgramsResponse.projects
        ) {
          if (window.innerWidth > 768) {
            setSelectedProgram(companyProgramsResponse.projects[0]);
          }

          setCompanyProgramsData(companyProgramsResponse.projects);
        }
        const companyEmployersResponse =
          await CompanyApi.getCompanyParticipants(token, userCompany[0]._id);

        if (companyEmployersResponse.status) {
          companyEmployers.current = companyEmployersResponse.participants;
          setFilteredEmployers(companyEmployersResponse.participants);
        }
        setActionLoaders((prev) => ({ ...prev, loadProgrmas: false }));
      };

      makeAsync();
    }
  }, [userCompany]);

  useEffect(() => {
    if (!filteredEmployers || !filteredEmployers.length || !selectedProgram)
      return;
    const notAvailableUsersId = selectedProgram.clients.map((item) => item._id);
    const availableUsers = filteredEmployers.filter(
      (employee) => !notAvailableUsersId.includes(employee.user._id)
    );

    setAvailableInviteUsers(
      availableUsers.filter((el) => el.role.value !== "specialist")
    );
  }, [selectedProgram, filteredEmployers]);

  const filterEmployers = (value: string) => {
    setSearchValue(value);
    if (companyEmployers.current) {
      const filteredContacts = companyEmployers.current.filter((employer) => {
        const searchTerm = value.toLowerCase();
        const fullname =
          `${employer.user.name} ${employer.user.surname}`.toLowerCase();
        return fullname.includes(searchTerm);
      });
      setFilteredEmployers(filteredContacts);
    }
  };

  useEffect(() => {
    setFilteredEmployers(companyEmployers.current);
    if (companyEmployers.current) {
      const employersId = companyEmployers.current.map((item) => item.user._id);
      getUserAvatars(employersId as string[]);
    }
  }, [companyEmployers.current]);

  const inviteEmployerHandler = (email: string) => {
    if (inviteEmailData.includes(email)) {
      setInviteEmailData((prevData) =>
        prevData.filter((item) => item !== email)
      );
    } else {
      setInviteEmailData((prev) => [...prev, email]);
    }
  };
  const inviteUsersToProgram = async () => {
    if (token && selectedProgram) {
      setActionLoaders((prev) => ({ ...prev, inviteUser: true }));
      const response = await ProjectApi.inviteUserToProject(token, {
        emails: inviteEmailData,
        projectId: selectedProgram._id,
        role: Roles.DEEFAULT_USER,
      });

      setActionLoaders((prev) => ({ ...prev, inviteUser: false }));
      setInviteToProjectModalVisible(false);
      if (response[0].status) {
        SuccesNotify(strings.invitesHasSent);
      }
    }
  };

  const createProject = async () => {
    if (
      !newProgramData.clientLimit ||
      !newProgramData.clientLimit ||
      !newProgramData.title.length ||
      !newProgramData.description.length
    ) {
      return Notify(strings.fillAllFields);
    }
    if (token && userCompany) {
      const payload = {
        companyId: userCompany[0]._id,
        newProject: newProgramData,
      };

      const response = await ProjectApi.createProject(token, payload);

      if (response.status) {
        setNewProgramData({
          title: "",
          description: "",
          creationDate: new Date(),
          endDate: new Date(),
          clientLimit: 1,
          specialistLimit: 1,
          isPrivate: true,
        });
        SuccesNotify(strings.projectCreate);
        setCompanyProgramsData((prev) =>
          prev ? [...prev, response.project] : [response.project]
        );
        setCreateProgramModalVisible(false);
      }
    }
  };
  const formateDateForProgram = (value: Date) => {
    const year = value.getFullYear();
    const month = (value.getMonth() + 1).toString().padStart(2, "0");
    const day = value.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  if (actionLoader.loadProgrmas) {
    return (
      <div className={"loader-container"}>
        <Loader size={100} />
      </div>
    );
  }

  return (
    <div className={s.container}>
      <div className={s.locationTitleBlock}>
        <span>{strings.locationTitleCompany}</span>
        <ChevronIcon className={s.chevronIcon} />
        <span>{strings.locationTitlePrograms}</span>
      </div>
      <div className={s.mobileHeaderContent}>
        <div>
          {selectedProgram ? (
            <ChevronIcon
              className={s.chevronBackIcon}
              onClick={() => setSelectedProgram(null)}
            />
          ) : (
            <span
              className={s.mobileNewTodoButton}
              onClick={() => setCreateProgramModalVisible(true)}
            >
              +
            </span>
          )}
        </div>
        <span className={s.mobileLocationTitle}>
          {strings.locationTitlePrograms}
        </span>
      </div>
      <div className={s.mainContentBlock}>
        <div
          className={
            selectedProgram
              ? `${s.programListBlock} ${s.mobileProgramListBlockHidden}`
              : `${s.programListBlock} ${s.mobileProgramListBlockVisible}`
          }
        >
          <div
            className={s.newProgramButton}
            role="button"
            onClick={() => setCreateProgramModalVisible(true)}
          >
            <span>{strings.newProgramBtn}</span>
            <span>
              <img src={PlusIcon} alt="" />
            </span>
          </div>
          <div className={s.programList}>
            {companyProgramsData &&
              companyProgramsData.map((item, index) => (
                <div
                  onClick={() => setSelectedProgram(item)}
                  key={index}
                  style={{
                    cursor: "pointer",
                    width: "99%",
                  }}
                >
                  <ProgramCard
                    date={item.creationDate ? item.creationDate.toString() : ""}
                    companyProgramsData={companyProgramsData}
                    participantsList={item.clients.map((item) => item._id)}
                    programTitle={item.title}
                    setModalVisible={setInviteToProjectModalVisible}
                    isSelected={item._id === selectedProgram?._id}
                    selectProgram={selectedProgram}
                    setSelectedProgram={setSelectedProgram}
                  />
                </div>
              ))}
          </div>
        </div>
        {!companyProgramsData ||
        (companyProgramsData && !companyProgramsData.length) ? (
          <div className={s.noProgramsBlock}>{strings.noPrograms}</div>
        ) : !selectedProgram ? (
          <div className={s.noProgramsBlock}>{strings.chooseProgram}</div>
        ) : (
          <div
            className={
              !selectedProgram
                ? `${s.programInfoBlock} ${s.mobileProgramInfoBlockHidden}`
                : `${s.programInfoBlock} ${s.mobileProgramInfoBlockVisible}`
            }
          >
            <div className={s.programSpecialistsBlock}>
              <span className={s.specialistTitle}>{strings.specialists}</span>
              <div className={s.specialistsList}>
                {selectedProgram?.specialists.length ? (
                  selectedProgram &&
                  selectedProgram.specialists &&
                  selectedProgram.specialists.map((item, index) => (
                    <div className={s.specialistElementBlock} key={index}>
                      <CircleAvatar userId={item._id} />
                      <span>
                        {item.name} {item.surname}
                      </span>
                    </div>
                  ))
                ) : (
                  <h2>{strings.noSpecialists}</h2>
                )}
              </div>
            </div>
            <div className={s.headTitleBlock}>
              <span>{selectedProgram && selectedProgram.title}</span>
              <span>
                {selectedProgram &&
                  selectedProgram.creationDate &&
                  formateDate(new Date(selectedProgram.creationDate) as Date)}
              </span>
            </div>
            <div className={s.descriptionBlock}>
              <span>{selectedProgram && selectedProgram.description}</span>
            </div>
          </div>
        )}
      </div>
      <ModalWindow
        setIsOpen={setInviteToProjectModalVisible}
        isOpen={inviteToProjectModalVisible}
        width="fit-content"
        bgColor={actionLoader.inviteUser ? "transparent" : "white"}
      >
        {actionLoader.inviteUser ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ThemeProvider theme={theme}>
              <CircularProgress size={150} color="primary" />
            </ThemeProvider>
          </div>
        ) : (
          <div className={s.inviteModalContainer}>
            <h3>{strings.inviteToProgramModalTitle}</h3>
            <div className={s.searchBlock}>
              <Input
                onChangeInput={(value) => filterEmployers(value)}
                inputValue={searchValue ?? ""}
                placeholder={strings.filterSearchPlaceHolder}
                isVisible
                required
              />
              <img src={SearchIcon} alt="" className={s.searchIcon} />
            </div>
            <div className={s.employersList}>
              {availableInviteUsers && availableInviteUsers.length ? (
                availableInviteUsers.map((item, index) => (
                  <div
                    key={index}
                    className={
                      inviteEmailData.includes(item.user.email)
                        ? s.includesEmployer
                        : s.employer
                    }
                    role="button"
                    onClick={() => inviteEmployerHandler(item.user.email)}
                  >
                    <span>
                      <CircleAvatar userId={item.user._id} marginRight="5px" />
                      <span className={s.employerFullname}>
                        {item.user.name} {item.user.surname}
                      </span>
                    </span>
                    <span className={s.employerEmail}>{item.user.email}</span>
                  </div>
                ))
              ) : (
                <h2 className={s.noDataText}>{strings.nousersAvailable}</h2>
              )}
              {inviteEmailData.length ? (
                <div className={s.inviteButtonBlock}>
                  <StandartButton
                    action={inviteUsersToProgram}
                    buttonTitle={strings.inviteBtn}
                    width="fit-content"
                  />
                </div>
              ) : null}
            </div>
          </div>
        )}
      </ModalWindow>
      <ModalWindow
        setIsOpen={setCreateProgramModalVisible}
        isOpen={createProgramModalVisible}
        width="fit-content"
      >
        <div className={s.newProgramContainer}>
          <h2>{strings.newProgramModalTitle}</h2>
          <div className={s.inputProgramTitleBlock}>
            <span className={s.inputTitle}>{strings.enterTitleProgram}</span>
            <Input
              onChangeInput={(value) =>
                setNewProgramData((prev) => ({ ...prev, title: value }))
              }
              inputValue={newProgramData.title}
              isVisible
              required
            />
          </div>
          <div className={s.noteTextData}>
            <span className={s.inputTitle}>
              {strings.enterDescriptionProgram}
            </span>
            <textarea
              name=""
              id=""
              cols={30}
              rows={7}
              value={newProgramData.description}
              className={s.textAreaNewNote}
              onChange={(event) =>
                setNewProgramData((prev) => ({
                  ...prev,
                  description: event.target.value,
                }))
              }
            ></textarea>
            <div className={s.privateProgramSwitch}>
              <MuiSwitch
                lable={strings.isPrivateProgram}
                lablePlacement="start"
                checked={newProgramData.isPrivate}
                setChecked={(value: boolean) =>
                  setNewProgramData((prev) => ({ ...prev, isPrivate: value }))
                }
              />
            </div>
            <div className={s.inputsBlock}>
              <div className={s.inputElement}>
                <span className={s.inputTitle}>{strings.programEndDate}</span>
                <input
                  type="date"
                  value={formateDateForProgram(newProgramData.endDate!)}
                  onChange={(e) =>
                    setNewProgramData((prev) => ({
                      ...prev,
                      endDate: new Date(e.target.value),
                    }))
                  }
                />
              </div>
            </div>
          </div>
          <div className={s.newProgramButtonBlock}>
            <StandartButton
              buttonTitle={strings.createProgramBtn}
              action={createProject}
              width="150px"
            />
          </div>
        </div>
      </ModalWindow>
    </div>
  );
};

export default Programs;
