import { useContext, useEffect, useRef, useState } from "react";
import CompanyApi from "../../api/companyApi";
import MagnifierIcon from "../../assets/Company/magnifier.svg";
import { ReactComponent as ChevronIcon } from "../../assets/HomePage/chevron-up.svg";
import { ContextProvider } from "../../contextProvider";
import { Project, TypeCompanyEmployee } from "../../types/Company";
import EmployerCard from "./EmployerCard/EmployerCard";
import s from "./Employers.module.css";

import Input from "../../components/Input/Input";
import MultipleSelect from "../../components/MultipleSelect/MultipleSelect";
import strings from "../../localization";

const Employers = () => {
  const token = localStorage.getItem("token");
  const { userCompany } = useContext(ContextProvider);
  const companyEmployers = useRef<TypeCompanyEmployee[] | null>([]);
  const [search, setSearch] = useState("");
  const [companyProjects, setCompanyProjects] = useState<Project[] | null>(
    null
  );
  const [filteredEmployers, setFilteredEmployers] = useState<
    TypeCompanyEmployee[] | null
  >([]);
  const [projectFilterValue, setProjectFilterValue] = useState<
    string | string[]
  >([]);
  const [roleFilterValue, setRoleFilterValue] = useState<string | string[]>([]);

  const roleData = [
    { lable: strings.employeeRoleEmployee, value: "employee" },
    { lable: strings.employeeRoleManager, value: "manager" },
    { lable: strings.employeeRoleOwner, value: "owner" },
    { lable: strings.employeeRoleSpec, value: "specialist" },
  ];
  useEffect(() => {
    if (token && userCompany) {
      const makeAsync = async () => {
        const companyEmployersResponse =
          await CompanyApi.getCompanyParticipants(token, userCompany[0]._id);

        if (companyEmployersResponse.status) {
          companyEmployers.current = companyEmployersResponse.participants;
          setFilteredEmployers(companyEmployersResponse.participants);
        }
        const projectResponse = await CompanyApi.getCompanyProjects(
          token,
          userCompany[0]._id
        );
        if (projectResponse.status)
          setCompanyProjects(projectResponse.projects);
      };

      makeAsync();
    }
  }, [userCompany]);

  useEffect(() => {
    if (companyEmployers.current) {
      let filteredEmployees = companyEmployers.current;
      if (roleFilterValue.length) {
        filteredEmployees = filteredEmployees.filter((employee) =>
          roleFilterValue.includes(employee.role.translatedValue)
        );
      }
      if (projectFilterValue.length && companyProjects) {
        filteredEmployees = filteredEmployees.filter((employee) => {
          return employee.user.projects.some((project) =>
            projectFilterValue.includes(project)
          );
        });
      }
      setFilteredEmployers(filteredEmployees);
    }
  }, [roleFilterValue, projectFilterValue, companyProjects]);

  const filterEmployers = (value: string) => {
    setSearch(value);
    if (companyEmployers.current) {
      const filteredContacts = companyEmployers.current.filter((employer) => {
        const searchTerm = value.toLowerCase();
        const fullname =
          `${employer.user.name} ${employer.user.surname}`.toLowerCase();
        return fullname.includes(searchTerm);
      });
      setFilteredEmployers(filteredContacts);
    }
  };

  return (
    <div className={s.container}>
      <div className={s.locationTitleBlock}>
        <span>{strings.locationTitleCompany}</span>
        <ChevronIcon className={s.chevronIcon} />
        <span>{strings.locationTitleEmployers}</span>
      </div>
      <div className={s.mainContentContainer}>
        <div className={s.employersTableContainer}>
          <div className={s.filterBlock}>
            <div className={s.searchInputBlock}>
              <Input
                inputValue={search}
                onChangeInput={(value) => filterEmployers(value)}
                isVisible
                required
                placeholder={strings.filterSearchPlaceHolder}
              />
              <img src={MagnifierIcon} alt="" className={s.magnifierIcon} />
            </div>
            <div className={s.selectFilterBlock}>
              <span className={s.filterTitle}>{strings.employeeFilterBy}</span>
              <div className={s.selectInputBlock}>
                <div className={s.multiSelectBlock}>
                  <MultipleSelect
                    data={
                      companyProjects
                        ? companyProjects.map((item) => ({
                            value: item.title,
                            lable: item.title,
                          }))
                        : []
                    }
                    multiplie={true}
                    value={projectFilterValue}
                    setValue={(value) => setProjectFilterValue(value)}
                    lable={strings.employeProgramInputLable}
                    checkBox={true}
                    padding="6px 10px"
                  />
                </div>
                <div className={s.multiSelectBlock}>
                  <MultipleSelect
                    data={roleData}
                    multiplie={true}
                    value={roleFilterValue}
                    setValue={(value) => setRoleFilterValue(value)}
                    lable={strings.employeRoleInputLable}
                    checkBox={true}
                    padding="6px 10px"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={s.lableTableBlock}>
            <span className={s.nameTableLable}>
              {strings.tableHeadTitleName}
            </span>
            <span className={s.roleTableLable}>
              {strings.tableHeadTitleRole}
            </span>
            <span className={s.programTableLable}>
              {strings.tableHeadTitleProgram}
            </span>
            <span className={s.emailTableLable}>
              {strings.tableHeadTitleEmail}
            </span>
          </div>
          <div className={s.tableBlock}>
            {filteredEmployers &&
              filteredEmployers.map((item, index) => (
                <div
                  key={index}
                  className={s.employeeCardContainer}
                  style={{
                    backgroundColor:
                      (index + 1) % 2 === 0
                        ? "rgba(63, 176, 130, 0.1)"
                        : "white",
                  }}
                >
                  <EmployerCard
                    userId={item.user._id}
                    email={item.user.email}
                    fullName={item.user.name + " " + item.user.surname}
                    program={item.user.projects}
                    role={item.role}
                    setFilteredEmployers={setFilteredEmployers}
                  />
                </div>
              ))}
          </div>
        </div>
        <div className={s.rightInfoBlock}></div>
      </div>
    </div>
  );
};

export default Employers;
