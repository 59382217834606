import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import AnimateHeight from "react-animate-height";
import { Link } from "react-router-dom";
import { ReactComponent as SpecialistIcon } from "../../assets/Company/magnifier.svg";
import { ReactComponent as SwitchIcon } from "../../assets/Header/switch.svg";
import TokenIcon from "../../assets/Header/token-icon.svg";
import { ReactComponent as ProfileIcon } from "../../assets/Header/user.svg";
import { ReactComponent as ChevronIcon } from "../../assets/HomePage/chevron-up.svg";
import { ReactComponent as CompanyIcon } from "../../assets/SideBar/company-icon.svg";
import { ReactComponent as HomeIcon } from "../../assets/SideBar/home.svg";
import { ReactComponent as LogoutIcon } from "../../assets/SideBar/logout.svg";
import { ReactComponent as MyProgramsIcon } from "../../assets/SideBar/my-programs.svg";
import { ReactComponent as NotesIcon } from "../../assets/SideBar/notes.svg";
import { ReactComponent as TodoIcon } from "../../assets/SideBar/todo.svg";
import { ReactComponent as TreeQuizIcon } from "../../assets/SideBar/tree-quiz.svg";
import { ReactComponent as SubscriptionsIcon } from "../../assets/Subscriptions/subscriptions-logo.svg";
import {
  TypeMenuItem,
  sidebarMenu,
  specialistSideBarMenu,
} from "../../constans/sidebar-menu";
import { ContextProvider } from "../../contextProvider";
import strings from "../../localization";
import { Roles } from "../../types/TypeUsers";
import { logout } from "../../utils/general";
import s from "./MobileNavMenu.module.css";

type MobileNavMenu = {
  setMobileMenuIsOpen: Dispatch<SetStateAction<boolean>>;
  setSubscriptionsModal: Dispatch<SetStateAction<boolean>>;
  setBalanceModalOpen: Dispatch<SetStateAction<boolean>>;
};

const MobileNavMenu = ({
  setMobileMenuIsOpen,
  setSubscriptionsModal,
  setBalanceModalOpen,
}: MobileNavMenu) => {
  const { userData } = useContext(ContextProvider);
  const selectLanguage = localStorage.getItem("lang");
  const userHaveProgramsStored = localStorage.getItem("userHavePrograms");
  const userHavePrograms =
    userHaveProgramsStored !== null
      ? JSON.parse(userHaveProgramsStored)
      : false;
  const isSpecialistStored = localStorage.getItem("isSpecialist");
  const isUserCompanyStored = localStorage.getItem("haveCompany");
  const isUserCompany =
    isUserCompanyStored !== null ? JSON.parse(isUserCompanyStored) : false;
  const isSpecialist =
    isSpecialistStored !== null ? JSON.parse(isSpecialistStored) : false;
  const [subMenuIsOpen, setSubMenuIsOpen] = useState(false);
  const [menuItems, setMenuItems] = useState<TypeMenuItem[]>();
  const subMenuRef = useRef<any>(null);
  useEffect(() => {
    setMenuItems(isSpecialist ? specialistSideBarMenu : sidebarMenu);
  }, [isSpecialist]);

  useEffect(() => {
    if (selectLanguage) {
      strings.setLanguage(selectLanguage);
    }

    if (isSpecialist) {
      setMenuItems(
        !userHavePrograms
          ? [
              {
                pathname: "/home",
                dataTestId: "home-link",
                icon: HomeIcon,
                title: strings.homeMenuTitle,
                submenu: false,
              },
              {
                pathname: "/todo",
                dataTestId: "sessions-link",
                icon: TodoIcon,
                title: strings.todoListMenuTitle,
                submenu: false,
              },
              {
                pathname: "/notes",
                dataTestId: "sessions-link",
                icon: NotesIcon,
                title: strings.notesMenuTitle,
                submenu: false,
              },
            ]
          : [
              {
                pathname: "/home",
                dataTestId: "home-link",
                icon: HomeIcon,
                title: strings.homeMenuTitle,
                submenu: false,
              },
              {
                pathname: "/todo",
                dataTestId: "sessions-link",
                icon: TodoIcon,
                title: strings.todoListMenuTitle,
                submenu: false,
              },
              {
                pathname: "/notes",
                dataTestId: "sessions-link",
                icon: NotesIcon,
                title: strings.notesMenuTitle,
                submenu: false,
              },
              {
                pathname: "/my-programs-specialist",
                dataTestId: "sessions-link",
                icon: MyProgramsIcon,
                title: strings.myProgramsMenuTitle,
                submenu: false,
              },
            ]
      );
    }

    if (!isSpecialist) {
      setMenuItems(
        !userHavePrograms
          ? [
              {
                pathname: "/home",
                dataTestId: "home-link",
                icon: HomeIcon,
                title: strings.homeMenuTitle,
                submenu: false,
              },
              {
                pathname: "/specialist-find",
                dataTestId: "home-link",
                icon: SpecialistIcon,
                title: strings.specialistsMenuTitle,
                submenu: false,
              },
              {
                pathname: "/todo",
                dataTestId: "sessions-link",
                icon: TodoIcon,
                title: strings.todoListMenuTitle,
                submenu: false,
              },
              {
                pathname: "/notes",
                dataTestId: "sessions-link",
                icon: NotesIcon,
                title: strings.notesMenuTitle,
                submenu: false,
              },
              {
                pathname: "/tree-quiz",
                dataTestId: "sessions-link",
                icon: TreeQuizIcon,
                title: strings.treeQuizMenuTitle,
                submenu: false,
                strokeColor: true,
              },
              {
                pathname: "/",
                dataTestId: "company",
                icon: CompanyIcon,
                title: strings.companyMenuTitle,
                submenu: true,
                submenuItems: [
                  {
                    title: strings.dashboardMenuTitle,
                    path: "/company-dashboard",
                  },
                  {
                    title: strings.programsMenuTitle,
                    path: "/company-programs",
                  },
                  {
                    title: strings.employersMenuTitle,
                    path: "/company-employers",
                  },
                ],
              },
            ]
          : [
              {
                pathname: "/home",
                dataTestId: "home-link",
                icon: HomeIcon,
                title: strings.homeMenuTitle,
                submenu: false,
              },
              {
                pathname: "/specialist-find",
                dataTestId: "home-link",
                icon: SpecialistIcon,
                title: strings.specialistsMenuTitle,
                submenu: false,
              },
              {
                pathname: "/todo",
                dataTestId: "sessions-link",
                icon: TodoIcon,
                title: strings.todoListMenuTitle,
                submenu: false,
              },
              {
                pathname: "/notes",
                dataTestId: "sessions-link",
                icon: NotesIcon,
                title: strings.notesMenuTitle,
                submenu: false,
              },
              {
                pathname: "/tree-quiz",
                dataTestId: "sessions-link",
                icon: TreeQuizIcon,
                title: strings.treeQuizMenuTitle,
                submenu: false,
                strokeColor: true,
              },
              {
                pathname: "/my-programs",
                dataTestId: "sessions-link",
                icon: MyProgramsIcon,
                title: strings.myProgramsMenuTitle,
                submenu: false,
              },
              {
                pathname: "/",
                dataTestId: "company",
                icon: CompanyIcon,
                title: strings.companyMenuTitle,
                submenu: true,
                submenuItems: [
                  {
                    title: strings.dashboardMenuTitle,
                    path: "/company-dashboard",
                  },
                  {
                    title: strings.programsMenuTitle,
                    path: "/company-programs",
                  },
                  {
                    title: strings.employersMenuTitle,
                    path: "/company-employers",
                  },
                ],
              },
            ]
      );
    }
  }, [isSpecialist, userHaveProgramsStored, selectLanguage]);

  const switchProfileMode = (isSpec: boolean) => {
    localStorage.setItem("isSpecialist", (!isSpec).toString());
    window.location.reload();
  };

  const getBalanceOfUser = () => {
    if (!userData || userData.balance === undefined) return "";
    if (userData.balance === 0) return 0;
    return (userData.balance / 100).toFixed(0);
  };

  return (
    <div className={s.conatiner}>
      <div
        className={s.balanceBlock}
        role="button"
        onClick={() => setBalanceModalOpen(true)}
      >
        <span>{getBalanceOfUser()}</span>
        <img src={TokenIcon} alt="" />
      </div>

      <div style={{ marginLeft: "20px" }}>
        <Link
          className={s.profileButton}
          to={`/profile/${userData?.username}`}
          onClick={() => setMobileMenuIsOpen(false)}
        >
          <ProfileIcon className={s.profileIcon} />
          <p>{strings.myProfile}</p>
        </Link>

        {menuItems &&
          menuItems.map((item, index) =>
            item.submenu && item.submenuItems && isUserCompany ? (
              <div key={index} className={s.subMenuItem}>
                <div onClick={() => setSubMenuIsOpen(!subMenuIsOpen)}>
                  <item.icon className={s.menuIcon} />
                  <span className={s.menuTitle}>{item.title}</span>
                  <ChevronIcon className={s.chevronIcon} />
                </div>

                <AnimateHeight
                  height={subMenuIsOpen ? "auto" : 0}
                  ref={subMenuRef}
                  className={s.subMenuItemsBlock}
                >
                  {item.submenuItems.map((item, index) => (
                    <Link
                      to={item.path}
                      key={index}
                      className={s.subMenuItemElement}
                      onClick={() => setMobileMenuIsOpen(false)}
                    >
                      {item.title}
                    </Link>
                  ))}
                </AnimateHeight>
              </div>
            ) : item.title === "Company" ? (
              <span key={index}></span>
            ) : (
              <Link
                className={s.menuItem}
                key={index}
                to={item.pathname}
                onClick={() => setMobileMenuIsOpen(false)}
              >
                <item.icon
                  className={item.strokeColor ? s.menuIconStroke : s.menuIcon}
                />
                <span className={s.menuTitle}>{item.title}</span>
              </Link>
            )
          )}
        <div className={s.bottomBtnsBlcok}>
          {userData && userData.roles!.includes(Roles.SPECIALIST) && (
            <div
              role="button"
              onClick={() => switchProfileMode(isSpecialist)}
              className={s.profileMenuBtn}
            >
              <SwitchIcon className={`${s.profileMenuIcon}`} />
              {isSpecialist
                ? strings.switchToClient
                : strings.switchToSpecProfile}
            </div>
          )}
          <div
            role="button"
            onClick={() => setSubscriptionsModal(true)}
            className={s.profileMenuBtn}
            style={{ borderRadius: "0 0 20px 20px" }}
          >
            <SubscriptionsIcon className={s.profileMenuIcon} />
            {strings.subscriptions}
          </div>
          <button className={s.logoutButton} onClick={logout}>
            <LogoutIcon className={s.logoutButtonIcon} />
            <p>{strings.logOut}</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default MobileNavMenu;
