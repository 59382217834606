import { useContext, useEffect, useState } from "react";
import QuizeApi from "../../api/quiezeApi";
import { ReactComponent as LogOutIcon } from "../../assets/SideBar/logout.svg";
import { ReactComponent as ArrowRightIcon } from "../../assets/SpecialistSetupInfo/arrow-right.svg";
import HeaderChangeLangs from "../../components/HeaderChangeLangs/HeaderChangeLangs";
import { ContextProvider } from "../../contextProvider";
import { logout } from "../../utils/general";
import PersonInfo from "./PersonInfo/PersonInfo";
import s from "./UserQuize.module.css";

export type TypeQuestionWithId = {
  _id?: string;
  title: string;
  description?: string;
  answers: string[];
  multiselect?: boolean;
};

const UserQuize = () => {
  const { userData } = useContext(ContextProvider);
  const [dateOfBith, setDateOfBith] = useState({
    day: 1,
    month: 1,
    year: 2000,
  });
  const [textInputValues, setTextInputValues] = useState({
    name: "",
    surname: "",
  });
  const [genderValue, setGenderValue] = useState<string>("male");
  const [avatar, setAvatar] = useState<File | null>(null);
  const [firstQuizeStep, setFirstQuizeStep] = useState(0);
  const [secondQuizeStep, setSecondQuizeStep] = useState(0);
  const [thirdQuizeStep, setThirdQuizeStep] = useState(0);
  const [activeStep, setActiveStep] = useState(1);

  const handlePrevStep = () => {
    if (
      (firstQuizeStep === 0 && activeStep === 2) ||
      (secondQuizeStep === 0 && activeStep === 4) ||
      (thirdQuizeStep === 0 && activeStep === 6) ||
      activeStep === 3 ||
      activeStep === 5
    ) {
      setActiveStep(activeStep - 1);
    } else if (activeStep === 2 && firstQuizeStep > 0) {
      setFirstQuizeStep(firstQuizeStep - 1);
    } else if (activeStep === 4 && secondQuizeStep > 0) {
      setSecondQuizeStep(secondQuizeStep - 1);
    } else if (activeStep === 6 && thirdQuizeStep > 0) {
      setThirdQuizeStep(thirdQuizeStep - 1);
    }
  };

  useEffect(() => {
    const makeAsync = async () => {
      const token = localStorage.getItem("token");
      if (!token) return;

      const [
        responseMainQuiz,
        responseCoachQuiz,
        responseMentorQuiz,
        responsePsychologistQuiz,
      ] = await Promise.all([
        QuizeApi.getQuizByTitle("Main quiz", token),
        QuizeApi.getQuizByTitle("Coach quiz", token),
        QuizeApi.getQuizByTitle("Mentor quiz", token),
        QuizeApi.getQuizByTitle("Psyhologist quiz", token),
      ]);

      if (
        !responseMainQuiz ||
        !responseCoachQuiz ||
        !responseMentorQuiz ||
        !responsePsychologistQuiz
      )
        return;
    };

    makeAsync();
  }, [userData]);

  return (
    <div className={s.container}>
      <div className={s.headerContainer}>
        {activeStep === 1 ||
        (activeStep === 2 && firstQuizeStep === 0) ||
        activeStep === 3 ||
        activeStep === 5 ? (
          <div></div>
        ) : (
          <div
            role="button"
            className={s.arrowImageBlock}
            onClick={handlePrevStep}
          >
            <ArrowRightIcon className={s.arrowIcon} />
          </div>
        )}

        <div className={s.profileIconBlcok}>
          <HeaderChangeLangs />
          <LogOutIcon className={s.loguotIcon} onClick={logout} />
        </div>
      </div>
      {activeStep === 1 && (
        <PersonInfo
          avatar={avatar}
          setAvatar={setAvatar}
          dateOfBith={dateOfBith}
          genderValue={genderValue}
          setDateOfBith={setDateOfBith}
          setGenderValue={setGenderValue}
          setTextInputValues={setTextInputValues}
          textInputValues={textInputValues}
        />
      )}

      <div></div>
    </div>
  );
};

export default UserQuize;
