import { Dispatch, SetStateAction, useContext, useState } from "react";

import SpecialistApi from "../../../api/specialistApi";
import UsersApi from "../../../api/usersApi";
import NextSlideIcon from "../../../assets/SpecialistSetupInfo/next-slide.svg";
import { PlaceType } from "../../../components/CitySelect/CitySelect";
import StandartButton from "../../../components/StandartButton/StandartButton";
import { ContextProvider } from "../../../contextProvider";
import strings from "../../../localization";
import { SpecialistData } from "../../../types/TypeSpecialist";
import {
  addSpecialistCertificates,
  areAllStatesValid,
  updateSpecialistIntroVideo,
  updateUserAvatar,
} from "../../../utils/setupProfileInfo";
import Notify from "../../../utils/toaster";
import s from "./CreatingProfile.module.css";
import SubStep1 from "./SubSteps/SubStep1";
import SubSteps2 from "./SubSteps/SubSteps2";
import SubSteps3 from "./SubSteps/SubSteps3";

type CreatingProfileProps = {
  setActiveStep: Dispatch<SetStateAction<number>>;
  subStep: { current: number; total: number };
  setSubStep: Dispatch<SetStateAction<{ current: number; total: number }>>;
  setLoader: Dispatch<SetStateAction<boolean>>;
  setSpecialistInfo: Dispatch<SetStateAction<SpecialistData | null>>;
  specialistInfo: SpecialistData | null;
};

const CreatingProfile = ({
  setActiveStep,
  subStep,
  setSubStep,
  setLoader,
  setSpecialistInfo,
  specialistInfo,
}: CreatingProfileProps) => {
  const { userData, setUserData } = useContext(ContextProvider);
  const [dateOfBith, setDateOfBith] = useState({
    day: 1,
    month: 1,
    year: 2000,
  });
  const [textInputValues, setTextInputValues] = useState({
    name: "",
    surname: "",
    latinName: "",
    latinSurname: "",
    practics: "",
    experience: "",
  });
  const [genderValue, setGenderValue] = useState<"male" | "female" | string>(
    "male"
  );
  const [selectedCountry, setSelectedCountry] = useState<{
    code: string;
    title: string;
  }>({ code: "", title: "" });
  const [selectedCity, setSelectedCity] = useState<PlaceType | null>(null);
  const [selectedLangs, setSelectedLangs] = useState<string | string[]>([]);
  const [avatar, setAvatar] = useState<File | null>(null);
  const [video, setVideo] = useState<File | null>(null);
  //@ts-expect-error
  const [diplomas, setDiplomas] = useState<File[]>([]);

  const nextStep = async () => {
    const token = localStorage.getItem("token");
    const userDate = new Date(
      dateOfBith.year,
      dateOfBith.month - 1,
      dateOfBith.day
    );

    if (
      token &&
      userData &&
      userData._id &&
      (userData?.specialistIds ||
        (userData?.specialistIds && userData?.specialistIds[0]._id))
    ) {
      setLoader(true);
      const specialistId = userData?.specialistIds[0]._id
        ? userData?.specialistIds[0]._id
        : userData.specialistIds[0].toString();
      if (subStep.current === 1) {
        if (
          !areAllStatesValid(
            textInputValues,
            selectedCountry.title,
            selectedCity,
            selectedLangs
          )
        ) {
          setLoader(false);
          return Notify(strings.fillAllFields);
        }

        const updateUserDataResponse = await UsersApi.updateUserData(token, {
          _id: userData?._id,
          name: textInputValues.name.length
            ? textInputValues.name
            : textInputValues.latinName,
          surname: textInputValues.surname.length
            ? textInputValues.surname
            : textInputValues.latinSurname,
          latinName: textInputValues.latinName,
          latinSurname: textInputValues.latinSurname,
          dateOfBirth: userDate,
          gender: genderValue,
          preferredLanguages: selectedLangs,
          location: selectedCity?.terms[0].value + ", " + selectedCountry.title,
        });
        if (updateUserDataResponse.status && updateUserDataResponse.userData) {
          setUserData(updateUserDataResponse.userData);
        }
        const response = await SpecialistApi.update(
          {
            _id: specialistId,
            practiceHours: Number(textInputValues.practics),
            yearsOfExperience: Number(textInputValues.experience),
          },
          token
        );
        if (response.status) setSpecialistInfo(response.specialistData);

        setSubStep((prev) => ({ ...prev, current: prev.current + 1 }));
      } else if (subStep.current === 2) {
        if (!video && !avatar) {
          setSubStep((prev) => ({ ...prev, current: prev.current + 1 }));
          return setLoader(false);
        }
        if (avatar) {
          await updateUserAvatar(avatar, userData._id, token);
        }
        if (!video) {
          setLoader(false);
          return setSubStep((prev) => ({ ...prev, current: prev.current + 1 }));
        }
        await updateSpecialistIntroVideo(video, specialistId, token);
        setSubStep((prev) => ({ ...prev, current: prev.current + 1 }));
      } else if (subStep.current === 3) {
        if (diplomas.length === 0) {
          setActiveStep((prev) => prev + 1);
          return setLoader(false);
        }
        await addSpecialistCertificates(diplomas, specialistId, token);

        setActiveStep((prev) => prev + 1);
      }
      setLoader(false);
    }
  };

  return (
    <div>
      {subStep.current === 1 && (
        <SubStep1
          dateOfBith={dateOfBith}
          setDateOfBith={setDateOfBith}
          setTextInputValues={setTextInputValues}
          textInputValues={textInputValues}
          genderValue={genderValue}
          setGenderValue={setGenderValue}
          selectedCountry={selectedCountry}
          selectedLangs={selectedLangs}
          selectedCity={selectedCity}
          setSelectedCity={setSelectedCity}
          setSelectedCountry={setSelectedCountry}
          setSelectedLangs={setSelectedLangs}
        />
      )}
      {subStep.current === 2 && (
        <SubSteps2
          avatar={avatar}
          setAvatar={setAvatar}
          setVideo={setVideo}
          video={video}
          specialistInfo={specialistInfo}
        />
      )}
      {subStep.current === 3 && (
        <SubSteps3 /* files={diplomas} setFiles={setDiplomas} */ />
      )}
      <div className={s.continueButtonBlock}>
        {subStep.current === subStep.total ? (
          <StandartButton
            buttonTitle="Continue"
            action={nextStep}
            width="250px"
          />
        ) : (
          <div role="button" className={s.nextButtonBlock}>
            <img
              className={s.nextImage}
              src={NextSlideIcon}
              alt=""
              onClick={nextStep}
            />
          </div>
        )}
      </div>
      <div className={s.mobileContinueButtonBlock}>
        <StandartButton
          buttonTitle={strings.continueBtnSetupProfile}
          action={nextStep}
          width="70%"
        />
      </div>
    </div>
  );
};

export default CreatingProfile;
