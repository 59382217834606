import { Dispatch, SetStateAction, useRef, useState } from "react";
import s from "./PackageSessionCard.module.css";

import AnimateHeight from "react-animate-height";
import { ReactComponent as ChevronDown } from "../../../../assets/SpecTools/chevron-down.svg";
import { ReactComponent as Delete } from "../../../../assets/SpecTools/delete.svg";
import useAutosizeTextArea from "../../../../hooks/useAutosizeTextarea";
import strings from "../../../../localization";
import { NewPackageType } from "../PackagesTool";

type PackageSessionCardProps = {
  session: {
    title: string;
    description?: string;
    datesAndTime: {
      duration: number;
    }[];
    order: number;
  };
  setSessionState: Dispatch<SetStateAction<NewPackageType>>;
};

const PackageSessionCard = ({
  session,
  setSessionState,
}: PackageSessionCardProps) => {
  const [cardIsOpen, setCardIsOpen] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useAutosizeTextArea(textAreaRef.current, session.description!);

  const updateSessionField = (order: number, field: string, value: any) => {
    setSessionState((prevPackage) => {
      const updatedSessions = prevPackage.sessions.map((session) => {
        if (session.order === order) {
          if (field.startsWith("datesAndTime.")) {
            const [_, nestedField] = field.split(".");
            return {
              ...session,
              datesAndTime: [
                {
                  ...session.datesAndTime[0],
                  [nestedField]: value,
                },
              ],
            };
          } else {
            return {
              ...session,
              [field]: value,
            };
          }
        }
        return session;
      });

      return { ...prevPackage, sessions: updatedSessions };
    });
  };
  const updateDuration = (order: number, operator: "plus" | "minus") => {
    if (session.datesAndTime[0].duration === (operator === "plus" ? 180 : 60))
      return;
    setSessionState((prevState) => {
      return {
        ...prevState,
        sessions: prevState.sessions.map((session) => {
          if (session.order === order) {
            return {
              ...session,
              datesAndTime: session.datesAndTime.map((dt, index) => {
                if (index === 0) {
                  return {
                    ...dt,
                    duration:
                      operator === "plus" ? dt.duration + 60 : dt.duration - 60,
                  };
                }
                return dt;
              }),
            };
          }
          return session;
        }),
      };
    });
  };
  const deleteSessionByOrder = (order: number) => {
    setSessionState((prevPackage) => {
      const updatedSessions = prevPackage.sessions.filter(
        (session) => session.order !== order
      );
      return { ...prevPackage, sessions: updatedSessions };
    });
  };

  return (
    <div className={s.container}>
      <div className={s.headCardBlock}>
        <div className={s.leftHeadBlock}>
          <ChevronDown
            className={s.chevronIcon}
            onClick={() => setCardIsOpen((prev) => !prev)}
          />
          <input
            type="text"
            value={session.title}
            onChange={(e) =>
              updateSessionField(session.order, "title", e.target.value)
            }
            className={s.titleInput}
            placeholder={strings.packSessionTitlePlaceholder}
          />
        </div>
        <Delete
          onClick={() => deleteSessionByOrder(session.order)}
          className={s.deleteIcon}
        />
      </div>
      <AnimateHeight height={cardIsOpen ? "auto" : 0}>
        <div className={s.durationBlock}>
          <span>{strings.packSessionDuration}</span>
          <div className={s.durationInput}>
            <span
              className={s.durationBtn}
              onClick={() => updateDuration(session.order, "minus")}
            >
              -
            </span>
            <span>{session.datesAndTime[0].duration}</span>
            <span
              className={s.durationBtn}
              onClick={() => updateDuration(session.order, "plus")}
            >
              +
            </span>
          </div>
          {/* <input
            type="number"
            value={session.datesAndTime[0].duration}
            className={s.durationInput}
            onChange={(e) =>
              updateSessionField(
                session.order,
                "datesAndTime.duration",
                e.target.value
              )
            }
          /> */}
        </div>
        <div className={s.descriptionBlock}>
          <span>{strings.packSessionDescription}</span>
          <textarea
            rows={2}
            name=""
            id=""
            ref={textAreaRef}
            value={session.description}
            onChange={(e) =>
              updateSessionField(session.order, "description", e.target.value)
            }
            className={s.descriptionTextArea}
          ></textarea>
        </div>
      </AnimateHeight>
    </div>
  );
};

export default PackageSessionCard;
