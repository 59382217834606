import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import AnimateHeight from "react-animate-height";
import { ReactSpoiler } from "react-simple-spoiler";
import TodosAPI from "../../../api/todoApi";
import UsersApi from "../../../api/usersApi";
import { ReactComponent as ChevronIcon } from "../../../assets/HomePage/chevron-up.svg";
import CheckIcon from "../../../assets/Notes/check.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/Notes/delete.svg";
import { ReactComponent as EditIcon } from "../../../assets/Notes/edit.svg";
import TripleDotsIcon from "../../../assets/Notes/triple-dots.svg";
import { ReactComponent as ChangeIcon } from "../../../assets/Todo/arrow-change.svg";
import CircleAvatar from "../../../components/CircleAvatar/CircleAvatar";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal";
import NavigateToProfile from "../../../components/NavigateToProfile/NavigateToProfile";
import useClickOutside from "../../../hooks/useClickOutside";
import strings from "../../../localization";
import { Todo, TodoList, TodoStatus } from "../../../types/TypeToDo";
import Notify from "../../../utils/toaster";
import s from "./TaskCard.module.css";

type TaskCardProps = {
  taskId: string;
  title: string;
  description: string;
  status: string;
  onChangeTodo: (value: string, index: number, keyObj: keyof Todo) => void;
  index: number;
  updateTodo: (index: number) => void;
  setSelectedTodoList: Dispatch<SetStateAction<TodoList | null>>;
  selectedTodoList: TodoList | null;
  setTodosData: Dispatch<SetStateAction<TodoList[] | null>>;
  createdDate: Date;
};

type TaskAuthorInfo = {
  name: string;
  surname: string;
};

const TaskCard = ({
  title,
  description,
  status,
  onChangeTodo,
  index,
  updateTodo,
  taskId,
  setSelectedTodoList,
  selectedTodoList,
  setTodosData,
  createdDate,
}: TaskCardProps) => {
  const token = localStorage.getItem("token");
  const taskActionMenuRef = useRef<HTMLDivElement | null>(null);
  const [isEdit, setIsEdit] = useState(false);
  const [statusMenuIsCollapsed, setStatusMenuIsCollapsed] = useState(true);
  const [taskMenuIsOpen, setTaskMenuIsOpen] = useState(false);
  const [taskAuthorInfo, setTaskAuthorInfo] = useState<TaskAuthorInfo | null>(
    null
  );
  const [deleteTaskModal, setDeleteTaskModal] = useState(false);
  const [cardTaskCollapsed, setCardTaskCollapsed] = useState(false);
  useClickOutside(taskActionMenuRef, () => setTaskMenuIsOpen(false));
  const formateDate = (date: Date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString().slice(-2);
    const formattedDate = `${day}.${month}.${year}`;
    return formattedDate;
  };
  const statusArray = [
    { lable: strings.newStatusTask, value: "new" },
    { lable: strings.inProgressStatusTask, value: "in progress" },
    { lable: strings.urgentStatusTask, value: "urgent" },
    { lable: strings.completeStatusTask, value: "completed" },
  ];

  const deleteTask = async () => {
    if (token && selectedTodoList) {
      const response = await TodosAPI.deleteTodo(
        token,
        taskId,
        selectedTodoList._id
      );
      if (response.status) {
        setSelectedTodoList((prevTodoList) => {
          if (prevTodoList) {
            const updatedTodos = prevTodoList.todos.filter(
              (todo) => todo._id !== taskId
            );

            return {
              ...prevTodoList,
              todos: updatedTodos,
            };
          }
          return prevTodoList;
        });
        setTodosData((prevTodosData) => {
          if (prevTodosData) {
            const updatedTodosData = prevTodosData.map((todoList) => {
              if (todoList._id === selectedTodoList._id) {
                return {
                  ...todoList,
                  todos: todoList.todos.filter((todo) => todo._id !== taskId),
                };
              }
              return todoList;
            });
            return updatedTodosData;
          }
          return prevTodosData;
        });
      }
    }
  };

  const changeStatusHandler = async (status: string) => {
    if (token && selectedTodoList) {
      const response = await TodosAPI.updateTodo(
        token,
        {
          _id: taskId,
          status: status,
        },
        selectedTodoList?._id
      );
      if (!response.status && response.message) {
        return Notify(response.message);
      }

      if (response.status)
        setSelectedTodoList((prevTodoList) => {
          const newstatus =
            status === "new"
              ? TodoStatus.NEW
              : status === "in progress"
              ? TodoStatus.IN_PROGRESS
              : status === "urgent"
              ? TodoStatus.URGENT
              : TodoStatus.COMPLETED;
          if (prevTodoList && prevTodoList.todos[index]) {
            const updatedTodos = [...prevTodoList.todos];
            updatedTodos[index] = {
              ...updatedTodos[index],
              status: { ...updatedTodos[index].status, status: newstatus },
            };

            return {
              ...prevTodoList,
              todos: updatedTodos,
            };
          }
          return prevTodoList;
        });
      setTaskMenuIsOpen(false);
    }
  };

  useEffect(() => {
    const makeAsync = async () => {
      if (!token || !selectedTodoList) return;
      const response = await UsersApi.getUserAvatarAndUsername(
        token,
        selectedTodoList.createdBy._id
      );
      if (response.name && response.surname) {
        setTaskAuthorInfo({
          name: response.name,
          surname: response.surname,
        });
      }
    };
    makeAsync();
  }, [selectedTodoList]);

  return (
    <div
      className={s.container}
      onClick={() => setCardTaskCollapsed(!cardTaskCollapsed)}
    >
      <div className={s.noteDataBlock}>
        <div className={s.headNoteData}>
          <div style={{ display: "flex", alignItems: "center", width: "80%" }}>
            {isEdit ? (
              <input
                className={s.updateTitleInput}
                type="text"
                value={title}
                onChange={(event) =>
                  onChangeTodo(event.target.value, index, "title")
                }
              />
            ) : (
              <span
                className={
                  status === "in progress"
                    ? s.progressStatus
                    : status === "new"
                    ? s.newStatus
                    : status === "completed"
                    ? s.completeStatus
                    : s.urgentStatus
                }
              >
                {status === "in progress"
                  ? strings.inProgressStatusTask
                  : status === "new"
                  ? strings.newStatusTask
                  : status === "completed"
                  ? strings.completeStatusTask
                  : strings.urgentStatusTask}
              </span>
            )}
          </div>
          <div className={s.editButtonBlock}>
            <span className={s.dateTitle}>
              {formateDate(new Date(createdDate))}
            </span>
            <span
              onClick={
                isEdit
                  ? () => {
                      updateTodo(index);
                      setIsEdit(false);
                    }
                  : () => setTaskMenuIsOpen(!taskMenuIsOpen)
              }
              className={s.tripleDotIcon}
            >
              {isEdit ? (
                <img src={CheckIcon} alt="" className={s.checkIcon} />
              ) : (
                <img src={TripleDotsIcon} alt="" />
              )}
            </span>
            <div
              ref={taskActionMenuRef}
              className={s.actionMenuBlock}
              style={{ display: taskMenuIsOpen ? "flex" : "none" }}
            >
              <span
                className={s.menuElement}
                onClick={() => {
                  setIsEdit(!isEdit);
                  setTaskMenuIsOpen(false);
                }}
              >
                <EditIcon className={s.editIcon} /> {strings.editTaskBtn}
              </span>
              <span
                className={s.menuElement}
                onClick={() => setDeleteTaskModal(true)}
              >
                <DeleteIcon className={s.deleteIcon} /> {strings.deletetaskBtn}
              </span>
              <span
                className={s.menuElementDropDown}
                onClick={() => setStatusMenuIsCollapsed(!statusMenuIsCollapsed)}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <ChangeIcon className={s.changeIcon} />
                  {strings.changeStatusBtn}
                  <ChevronIcon className={s.chevronIcon} />
                </div>
                <AnimateHeight
                  height={statusMenuIsCollapsed ? 0 : "auto"}
                  className={s.changeStatusBlock}
                >
                  {statusArray.map((item, index) => (
                    <span
                      key={index}
                      style={{ display: "inline" }}
                      className={s.statusElement}
                      onClick={() => changeStatusHandler(item.value)}
                    >
                      {item.lable}
                    </span>
                  ))}
                </AnimateHeight>{" "}
              </span>
            </div>
          </div>
        </div>
        <h3 className={s.taskTitle}>{title}</h3>

        <div className={s.noteTextData}>
          {!isEdit ? (
            <ReactSpoiler
              collapsedSize={80}
              showMoreComponent={
                <p className={s.showTextBtn}>{strings.showMore}</p>
              }
              showLessComponent={
                <p className={s.showTextBtn}>{strings.showLess}</p>
              }
            >
              <p className={s.aboutTitle}>{description}</p>
            </ReactSpoiler>
          ) : (
            <textarea
              readOnly={!isEdit}
              name=""
              id=""
              value={description}
              className={s.textAreaNewNote}
              onChange={(event) =>
                onChangeTodo(event.target.value, index, "description")
              }
            ></textarea>
          )}
        </div>

        <div className={s.createdByBlock}>
          <span>{strings.createdByTodo}</span>
          <NavigateToProfile
            userId={selectedTodoList ? selectedTodoList.createdBy._id : ""}
          >
            <span className={s.authorInfoBlock}>
              <CircleAvatar
                userId={selectedTodoList ? selectedTodoList.createdBy._id : ""}
                width="40px"
                height="40px"
              />
              {taskAuthorInfo?.name + " " + taskAuthorInfo?.surname}
            </span>
          </NavigateToProfile>
        </div>
      </div>
      <ConfirmationModal
        modalVisible={deleteTaskModal}
        setModalVisible={setDeleteTaskModal}
        confirmFunc={deleteTask}
        confirmTitle={strings.confirmDeleteTaskTitle}
      ></ConfirmationModal>
    </div>
  );
};

export default TaskCard;
